import React, { PureComponent } from "react";
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { i18n } from "../../../../../Configuration/I18n";
import { EditButton } from "../../../../../Common/Adapters/Primaries/Components/EditButton";
import { SpeedQuizListToolbar } from "./Toolbar";
import { SpeedQuizHeader } from "../../../../Domain/Entities/SpeedQuizHeader";
import './Table.css';
import { StatusTag } from "../../../../../Common/Adapters/Primaries/Components/StatusTag";
import { SpeedQuizStatusType } from "../../../../Domain/SpeedQuiz.type";
import { Box } from "@mui/material";
import { EmptyTableText } from "../../../../../Common/Adapters/Primaries/Components/EmptyTableText";

interface Props {
    speedQuizList: SpeedQuizHeader[];
}

interface State {
    searchValue: string
    status: string;
}

export class SpeedQuizListTable extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            searchValue: '',
            status     : 'all'
        }
    }

    render() {
        const columns = [
            { field: 'name', headerName: i18n.speedQuiz.title, flex: 2 },
            {
                field     : 'status', headerName: i18n.speedQuiz.status, flex: 1,
                renderCell: (params: GridCellParams) => this.renderTag(params.value)
            },
            {
                field     : 'id', headerName: i18n.speedQuiz.actions, flex: 1, sortable: false,
                renderCell: (params: GridCellParams) => {
                    return <EditButton color={params.row._status === 'finished' ? 'primary' : 'default'}
                                       path={'/update-speed-quiz/' + params.value}/>
                }
            }
        ]

        return (
            <DataGrid className={'speed-quiz-table'}
                      rows={this.filter(this.props.speedQuizList)}
                      columns={columns}
                      autoHeight={true}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnMenu
                      sx={{   '& .MuiDataGrid-cell:focus': {
                              outline: 0,
                          }
                      }}
                      components={{
                          NoRowsOverlay: () => <EmptyTableText text={i18n.speedQuiz.no_speed_quiz}/>,
                          Toolbar      : () => <SpeedQuizListToolbar searchValue={this.state.searchValue}
                                                                     status={this.state.status}
                                                                     onChange={(value: string) => this.setState({ searchValue: value })}
                                                                     filter={(value: string) => this.setState({ status: value })}/>

                      }}
            />
        )
    }

    renderTag(status: SpeedQuizStatusType) {
        if (status === 'new')
            return <StatusTag title={i18n.speedQuiz.new} color={'green'}/>
        else if (status === 'in_progress')
            return <StatusTag title={i18n.speedQuiz.in_progress} color={'orange'}/>
        else if (status === 'finished')
            return <StatusTag title={i18n.speedQuiz.finished} color={'red'}/>
        else
            return <Box/>
    }

    filter(list: SpeedQuizHeader[]): SpeedQuizHeader[] {
        return list
            .filter(speedQuiz => speedQuiz.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1)
            .filter(speedQuiz => this.state.status !== 'all' ? this.state.status === speedQuiz.status : speedQuiz)
    }
}
