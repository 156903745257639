import { getLoginStatsEpic } from "../Usecases/GetLoginStats/epic";
import { combineEpics, Epic } from 'redux-observable';
import { getSessionStatsEpic } from "../Usecases/GetSessionStats/epic";
import { getAvatarStatsEpic } from "../Usecases/GetAvatarStats/epic";
import { getMcqStatsEpic } from "../Usecases/GetMcqStats/epic";
import { getSpeedQuizQuestionStatsEpic } from "../Usecases/GetSpeedQuizQuestionStats/epic";
import { getAdventureStatsEpic } from "../Usecases/GetAdventureStats/epic";
import { getSpeedQuizResponseStatsEpic } from "../Usecases/GetSpeedQuizResponseStats/epic";
import { getMessageStatsEpic } from "../Usecases/GetMessageStats/epic";
import { getAdventureSubscriptionStatsEpic } from "../Usecases/GetAdventureSubscriptionStats/epic";
import { getEmployeeStatsEpic } from "../Usecases/GetEmployeeStats/epic";
import { getBadgesStatsEpic } from "../Usecases/GetBadgesStats/epic";
import { getPageStatsEpic } from "../Usecases/GetPageStats/epic";
import { getSpeedQuizPlayerStatsEpic } from "../Usecases/GetSpeedQuizPlayerStats/epic";
import { getSpeedQuizPlayerCountEpic } from "../Usecases/GetSpeedQuizPlayerCount/epic";

export const statisticsRootEpic: Epic = combineEpics(
    getLoginStatsEpic,
    getSessionStatsEpic,
    getAvatarStatsEpic,
    getMcqStatsEpic,
    getSpeedQuizQuestionStatsEpic,
    getAdventureStatsEpic,
    getSpeedQuizResponseStatsEpic,
    getMessageStatsEpic,
    getAdventureSubscriptionStatsEpic,
    getEmployeeStatsEpic,
    getBadgesStatsEpic,
    getPageStatsEpic,
    getSpeedQuizPlayerStatsEpic,
    getSpeedQuizPlayerCountEpic
)
