import {
    LOGOUT_USER,
    LOGOUT_USER_FAILED,
    LOGOUT_USER_SUCCEEDED,
    LogoutUserAction,
    LogoutUserActionTypes
} from './actionTypes';

export const logoutUser = (): LogoutUserAction => ({
    type   : LOGOUT_USER
})

export const logoutUserFailed = (error: string): LogoutUserActionTypes => ({
    type   : LOGOUT_USER_FAILED,
    payload: error
})

export const logoutUserSucceeded = (): LogoutUserActionTypes => ({
    type: LOGOUT_USER_SUCCEEDED
})
