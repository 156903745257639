import {
    SpeedQuizAnswerType,
    SpeedQuizQuestionType,
    SpeedQuizRoundType,
    SpeedQuizType
} from "../../../Domain/SpeedQuiz.type";

export class SpeedQuizFormValidation {

    static validate(speedQuiz: SpeedQuizType): boolean {
        return this.validateTitle(speedQuiz.label) && this.validateTitle(speedQuiz.endDate) &&
            this.validateRound(speedQuiz.rounds[0]) && this.validateRound(speedQuiz.rounds[1]) &&
            this.validateRound(speedQuiz.rounds[2])
    }

    static validateTitle(title: string): boolean {
        return title.length > 2
    }

    static validateRound(round: SpeedQuizRoundType): boolean {
        return this.validateTitle(round.category) &&
            this.validateTitle(round.startDate) && this.validateQuestion(round.questions[0]) &&
            this.validateQuestion(round.questions[1]) && this.validateQuestion(round.questions[2])
    }

    static validateQuestion(question: SpeedQuizQuestionType): boolean {
        return this.validateTitle(question.text) && question.responses.length === 4 &&
            this.validateResponse(question.responses)
    }

    static validateResponse(answers: SpeedQuizAnswerType[]): boolean {
        return (answers[0].correct || answers[1].correct ||
            answers[2].correct || answers[3].correct) && (answers[0].text !== '' &&
            answers[1].text !== '' && answers[2].text !== '' && answers[3].text !== '')

    }
}
