import React from 'react';
import { Typography } from '@mui/material';

export const StatusTag = (props: {title: string, color: string}) => {
    return (
        <Typography component="span" style={{
            fontSize    : 13,
            color       : props.color,
            borderRadius: 15,
            padding     : '2px 8px',
            border      : '1px solid ' + props.color
        }}>
            {props.title}
        </Typography>
    )
}
