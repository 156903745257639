import React, { PureComponent, ReactNode } from 'react'
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { i18n } from "../../../../../../../Configuration/I18n";
import { MCQResponseFormType } from "../../../type/AdventureFormType";

interface Props {
    label: string
    response: MCQResponseFormType
    error: boolean
    onChange: (key: keyof MCQResponseFormType, value: string | boolean) => void
}

interface State {
    response_status: string
}

export class MCQResponseField extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            response_status: i18n.adventure.bad_response
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.response !== prevProps.response)
            this.setState({
                response_status: this.props.response.isCorrect ? i18n.adventure.good_response : i18n.adventure.bad_response
            })
    }

    render(): ReactNode {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                    <TextField fullWidth required
                               sx={{ mb: 2 }}
                               label={this.props.label}
                               error={this.props.error}
                               value={this.props.response.text}
                               onChange={(event) => this.props.onChange('text', event.target.value)}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControlLabel control={<Switch checked={this.props.response.isCorrect} color={'success'}
                                                       onChange={event => {
                                                           this.props.onChange('isCorrect', event.target.checked)
                                                           this.setState({
                                                               response_status: event.target.checked ? i18n.adventure.good_response : i18n.adventure.bad_response
                                                           })
                                                       }}/>}
                                      label={this.state.response_status}/>
                </Grid>
            </Grid>
        )
    }
}