import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { StatisticsGridTitle } from "./Title";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    title: string;
    info: string;
    size: number;
    colors: string[];
    stats: CartesianData[];
    style?: SxProps<Theme>;
}

interface State {
    activeIndex: number;
}

export class StatisticsPieChart extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            activeIndex: 0
        }
    }

    render(): ReactNode {
        return (
            <Grid item xs={12} lg={this.props.size}>
                <Box className={'statistics-grid'} sx={this.props.style}>
                    <StatisticsGridTitle title={this.props.title}
                                         info={this.props.info}/>

                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart width={300} height={300} style={{ fontFamily: 'sans-serif', fontSize: 12 }}>
                            <Pie activeIndex={this.state.activeIndex}
                                 activeShape={this.renderActiveShape}
                                 data={this.props.stats ? this.props.stats : []}
                                 cx="50%"
                                 cy="50%"
                                 innerRadius={70}
                                 outerRadius={90}
                                 fill="#8884d8"
                                 dataKey="_value"
                                 onMouseEnter={(children: ReactNode, index: number) => this.onPieEnter(children, index)}
                            >

                                {this.getCellColors()}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        )
    }

    onPieEnter = (e: ReactNode, index: number) => {
        this.setState({
            activeIndex: index
        })
    }

    getCellColors(): JSX.Element[] {
        return this.props.stats.map((stat: CartesianData, index: number) => {
            return (
                <Cell key={'cell-' + index} fill={this.props.colors[index % this.props.colors.length]}/>
            )
        })
    }

    renderActiveShape = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        startAngle: number;
        endAngle: number;
        fill: string;
        payload: { _label: string };
        percent: number;
        value: number;
    }) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload._label}
                </text>
                <Sector cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                />
                <Sector cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                    {(percent * 100).toFixed(2)}%
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    ({value})
                </text>
            </g>
        )
    }

}
