import { SerieData } from "../../Domain/Entity/SerieData";

export const GET_MCQ_STATS = 'GET_MCQ_STATS'
export const GET_MCQ_STATS_FAILED = 'GET_MCQ_STATS_FAILED'
export const GET_MCQ_STATS_SUCCEEDED = 'GET_MCQ_STATS_SUCCEEDED'

export  interface GetMcqStatsAction {
    type: typeof GET_MCQ_STATS;
    payload: string
}

interface GetMcqStatsFailedAction {
    type: typeof GET_MCQ_STATS_FAILED;
    payload: string;
}

interface GetMcqStatsSucceededAction {
    type: typeof GET_MCQ_STATS_SUCCEEDED;
    payload: SerieData[];
}

export type GetMcqStatsActionTypes = GetMcqStatsAction | GetMcqStatsFailedAction | GetMcqStatsSucceededAction
