import {
    GET_ALL_SPEED_QUIZ,
    GET_ALL_SPEED_QUIZ_FAILED,
    GET_ALL_SPEED_QUIZ_SUCCEEDED,
    GetAllSpeedQuizActionTypes
} from "./actionTypes";
import { GetAllSpeedQuizState } from "../../Config/State";

const initialState: GetAllSpeedQuizState = {
    loading: false,
    error  : undefined,
    data   : null
}
export const getAllSpeedQuizReducer = (state = initialState, action: GetAllSpeedQuizActionTypes): GetAllSpeedQuizState => {
    switch (action.type) {
        case GET_ALL_SPEED_QUIZ:
            return { loading: true, error: undefined, data: null }
        case GET_ALL_SPEED_QUIZ_FAILED:
            return { loading: false, error: action.payload, data: null }
        case GET_ALL_SPEED_QUIZ_SUCCEEDED:
            return { loading: false, error: undefined, data: action.payload }
        default:
            return state;
    }
}