import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { UpdateMessageContainer } from "./UpdateMessage.container";
import { Message } from "../../../Domain/Entity/Message";
import { updateMessage } from "../../../Usecases/Update/actions";
import { UpdateMessageActionTypes } from "../../../Usecases/Update/actionTypes";
import { updateMessageSuccessSelector } from "../../../Usecases/Update/selector";
import { loadMessageList } from "../../../Usecases/List/actions";
import { messageListSelector } from "../../../Usecases/List/selector";
import { LoadMessageListActionTypes } from "../../../Usecases/List/actionTypes";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    success: boolean | null;
    messages: Message[] | null;
}

interface DispatchToPropsType {
    updateMessage: (message: Message) => void;
    loadMessages: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    success : updateMessageSuccessSelector(state),
    messages: messageListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    updateMessage : (message: Message): UpdateMessageActionTypes => dispatch(updateMessage(message)),
    loadMessages  : (): LoadMessageListActionTypes => dispatch(loadMessageList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const UpdateMessagePage = connect(mapStateToProps, mapDispatchToProps)(UpdateMessageContainer)
