import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { agencyListSelector } from "../../../Usecases/GetAgencyList/selector";
import { connect } from "react-redux";
import { GetAgencyListActionTypes } from "../../../Usecases/GetAgencyList/actionTypes";
import { Agency } from "../../../Domain/Entities/Agency";
import { AppState } from "../../../../Redux-configuration/AppState";
import { Dispatch } from "redux";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { getAgencyList } from "../../../Usecases/GetAgencyList/actions";
import { UpdateAgencyAction } from "../../../Usecases/Update/actionTypes";
import { updateAgency } from "../../../Usecases/Update/actions";
import { UpdateAgencyContainer } from "./UpdateAgencyContainer";
import { updateAgencyErrorSelector, updateAgencyLoadingSelector } from "../../../Usecases/Update/selector";
import { Region } from "../../../Domain/Entities/Region";
import { regionsListSelector } from "../../../Usecases/GetRegionList/selector";

interface StateToPropsType {
    loading: boolean
    agencies: Agency[] | null;
    error: string | undefined;
    regions: Region[] | null
}

interface DispatchToPropsType {
    loadAgencies: () => void;
    setTopBarTitle: (title: string) => void
    updateAgency: (agency: Agency) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading : updateAgencyLoadingSelector(state),
    agencies: agencyListSelector(state),
    error   : updateAgencyErrorSelector(state),
    regions : regionsListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAgencies  : (): GetAgencyListActionTypes => dispatch(getAgencyList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title)),
    updateAgency  : (agency: Agency): UpdateAgencyAction => dispatch(updateAgency(agency))
})

export const UpdateAgencyPage = connect(mapStateToProps, mapDispatchToProps)(UpdateAgencyContainer)
