import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_SPEED_QUIZ_RESPONSE_STATS = 'GET_SPEED_QUIZ_RESPONSE_STATS'
export const GET_SPEED_QUIZ_RESPONSE_STATS_FAILED = 'GET_SPEED_QUIZ_RESPONSE_STATS_FAILED'
export const GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED = 'GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED'

export  interface GetSpeedQuizResponseStatsAction {
    type: typeof GET_SPEED_QUIZ_RESPONSE_STATS;
    payload: string
}

interface GetSpeedQuizResponseStatsFailedAction {
    type: typeof GET_SPEED_QUIZ_RESPONSE_STATS_FAILED;
    payload: string;
}

interface GetSpeedQuizResponseStatsSucceededAction {
    type: typeof GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED;
    payload: CartesianData[];
}

export type GetSpeedQuizResponseStatsActionTypes = GetSpeedQuizResponseStatsAction | GetSpeedQuizResponseStatsFailedAction | GetSpeedQuizResponseStatsSucceededAction
