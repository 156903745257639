import { combineReducers, Reducer } from "redux";
import { EmployeeState } from "./State";
import { getEmployeeListReducer } from "../Usecases/GetEmployeeList/reducer";
import { addEmployeeReducer } from "../Usecases/AddEmployee/reducer";
import { updateEmployeeReducer } from "../Usecases/UpdateEmployee/reducer";

export const employeeRootReducer: Reducer = combineReducers<EmployeeState>({
    listing       : getEmployeeListReducer,
    addEmployee   : addEmployeeReducer,
    updateEmployee: updateEmployeeReducer
})