import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import { GET_SPEED_QUIZ_RESPONSE_STATS, GetSpeedQuizResponseStatsAction } from "./actionTypes";
import {
    getSpeedQuizResponseStatsFailed,
    getSpeedQuizResponseStatsSucceeded,
} from "./actions";
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getSpeedQuizResponseStatsEpic: Epic = (action$: ActionsObservable<GetSpeedQuizResponseStatsAction>,
                                                    store: StateObservable<GetAppStatsState>,
                                                    { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_SPEED_QUIZ_RESPONSE_STATS),
        switchMap((action) => statisticsService.getSpeedQuizResponseStats(action.payload)
            .pipe(
                map((stats: CartesianData[]) => getSpeedQuizResponseStatsSucceeded(stats)),
                catchError(error => of(getSpeedQuizResponseStatsFailed(error)))
            )
        )
    )
