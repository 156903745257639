import React, { PureComponent, ReactNode } from 'react'
import { Agency } from "../../../Domain/Entities/Agency";
import { i18n } from "../../../../Configuration/I18n";
import { Box, CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { AgencyForm } from "../Components/AgencyForm";
import { match, RouteComponentProps } from "react-router";
import { Region } from "../../../Domain/Entities/Region";

interface Props extends RouteComponentProps {
    loading: boolean
    agencies: Agency[] | null;
    loadAgencies: () => void;
    setTopBarTitle: (title: string) => void
    updateAgency: (agency: Agency) => void
    match: match<{ code: string }>
    error: string | undefined
    regions: Region[] | null
}

export class UpdateAgencyContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.setTopBarTitle(i18n.agency.agency_title)

        if (!this.props.agencies && !this.props.loading)
            this.props.loadAgencies()
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error === undefined && this.props.loading !== prevProps.loading && this.props.loading === false)
            this.props.history.push("/agency-list");
    }

    render(): ReactNode {
        const agency: Agency | undefined = this.props.agencies?.find(item => item.agencyCode === this.props.match.params.code)
        return agency ? (
            <Box>
                <PageTitle title={i18n.agency.update_agency(agency.agencyName)}/>
                <AgencyForm agency={agency}
                            error={this.props.error}
                            regions={this.props.regions}
                            submitAgency={(agency: Agency) => this.props.updateAgency(agency)}/>

                {this.props.loading && this.renderLoading()}
            </Box>
        ) : null
    }

    renderLoading(): JSX.Element {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress/>
            </Box>
        )
    }
}
