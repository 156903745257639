import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs/index";
import { UPDATE_ADVENTURE, UpdateAdventureAction } from "./actionTypes";
import { HandleAdventureState } from "../../Config/State";
import { AdventureService } from "../../Domain/Gateway/Adventure.service";
import { updateAdventureFailed, updateAdventureSucceeded } from "./actions";

export const updateAdventureEpic: Epic = (action$: ActionsObservable<UpdateAdventureAction>,
                                          store: StateObservable<HandleAdventureState>,
                                          { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(UPDATE_ADVENTURE),
        switchMap((action) => adventureService.updateAdventure(action.payload)
            .pipe(
                map(() => updateAdventureSucceeded()
                ),
                catchError(error => of(updateAdventureFailed(error)))
            )
        ))
