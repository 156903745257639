export class MultivariateData {
    constructor(
        protected _label: string,
        protected _valueA: number,
        protected _valueB: number
    ) {
    }

    get label(): string {
        return this._label;
    }

    get valueA(): number {
        return this._valueA;
    }

    get valueB(): number {
        return this._valueB;
    }
}
