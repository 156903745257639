import {
    LOAD_ADVENTURE_LIST,
    LOAD_ADVENTURE_LIST_FAILED,
    LOAD_ADVENTURE_LIST_SUCCEEDED,
    LoadAdventureListActionTypes
} from './actionTypes';
import { Adventure } from "../../Domain/Entity/Adventure";

export const loadAdventureList = (): LoadAdventureListActionTypes => ({
    type: LOAD_ADVENTURE_LIST
})

export const loadAdventureListFailed = (error: string): LoadAdventureListActionTypes => ({
    type   : LOAD_ADVENTURE_LIST_FAILED,
    payload: error
})

export const loadAdventureListSucceeded = (adventures: Adventure[]): LoadAdventureListActionTypes => ({
    type   : LOAD_ADVENTURE_LIST_SUCCEEDED,
    payload: adventures
})
