import {
    ADD_MESSAGE,
    ADD_MESSAGE_FAILED,
    ADD_MESSAGE_SUCCEEDED,
    AddMessageActionTypes
} from './actionTypes';
import { Message } from "../../Domain/Entity/Message";

export const addMessage = (message: Message): AddMessageActionTypes => ({
    type   : ADD_MESSAGE,
    payload: message
})

export const addMessageFailed = (error: string): AddMessageActionTypes => ({
    type   : ADD_MESSAGE_FAILED,
    payload: error
})

export const addMessageSucceeded = (): AddMessageActionTypes => ({
    type: ADD_MESSAGE_SUCCEEDED
})
