import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";

export const CREATE_MCQ_QUESTION = 'CREATE_MCQ_QUESTION'
export const CREATE_MCQ_QUESTION_FAILED = 'CREATE_MCQ_QUESTION_FAILED'
export const CREATE_MCQ_QUESTION_SUCCEEDED = 'CREATE_MCQ_QUESTION_SUCCEEDED'

export interface CreateMCQQuestionAction {
    type: typeof CREATE_MCQ_QUESTION
    payload: { question: MCQQuestion, mcqId: string }
}

export interface CreateMCQQuestionFailedAction {
    type: typeof CREATE_MCQ_QUESTION_FAILED
    payload: string
}

export interface CreateMCQQuestionSucceededAction {
    type: typeof CREATE_MCQ_QUESTION_SUCCEEDED
}

export type CreateMCQQuestionActionTypes =
    CreateMCQQuestionAction
    | CreateMCQQuestionFailedAction
    | CreateMCQQuestionSucceededAction