interface BadgeType {
    id: string;
    title: string;
    description: string;
    picture: string;
}

export const AppUserBadges: BadgeType[] = [
    {
        id         : 'IGNITION',
        title      : '3, 2, 1, 0, Ignition',
        description: 'Personnalisez la photo de votre profil en choisissant l’avatar qui vous correspond le mieux',
        picture    : './assets/badges/3_2_1_IGNITION.png'
    },
    {
        id         : 'IN_ORBIT',
        title      : 'En orbite',
        description: 'Instructions : Obtenez un score supérieur à 100 Red Point',
        picture    : './assets/badges/EN_ORBITE.png'
    },
    {
        id         : 'HYPERSPACE',
        title      : 'Hyperspace',
        description: 'Instructions : Atteignez 100% de votre objectif sur l’une de vos missions challenges',
        picture    : './assets/badges/HYPERSPACE.png'
    },
    {
        id         : 'LIGHT_SPEED',
        title      : 'Vitesse lumière',
        description: 'Instructions : Atteignez 150% de votre objectif sur l’une de vos missions challenges',
        picture    : './assets/badges/VITESSE_LUMIERE.png'
    },
    {
        id         : 'ADMIRAL_SPUTNIK',
        title      : 'Amiral Spoutnik',
        description: 'Instructions : Arrivez premier au classement des ventes',
        picture    : './assets/badges/SPUTNIK.png'
    },
    {
        id         : 'CAPTAIN_FALCON',
        title      : 'Capitaine Falcon',
        description: 'Instructions : Arrivez dans le top 3 du classement des ventes',
        picture    : './assets/badges/CAPTAIN_FALCON.png'
    },
    {
        id         : 'FIRST_HUMAN_STEP',
        title      : 'Un petit pas pour l’homme…',
        description: 'Instructions : Arrivez dans le top 10 du classement des joueurs',
        picture    : './assets/badges/PETIT_PAS.png'
    },
    {
        id         : 'LONG_LIVE',
        title      : 'Longue vie et prospérité !',
        description: 'Instructions : Atteignez la première place du classement des joueurs',
        picture    : './assets/badges/LONGUE_VIE.png'
    },
    {
        id         : 'LIMITLESS',
        title      : 'Aucune limite à mon pouvoir !',
        description: 'Instructions : Obtenez un score parfait à un QCM pour la première fois ',
        picture    : './assets/badges/AUCUNE_LIMITE.png'
    },
    {
        id         : 'ARMSTRONG',
        title      : 'Armstrong',
        description: 'Instructions : Atteignez la première place du classement régional',
        picture    : './assets/badges/ARMSTRONG.png'
    }
]
