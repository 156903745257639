import {
    GET_ALL_SPEED_QUIZ,
    GET_ALL_SPEED_QUIZ_FAILED, GET_ALL_SPEED_QUIZ_SUCCEEDED,
    GetAllSpeedQuizAction,
    GetAllSpeedQuizFailedAction, GetAllSpeedQuizSucceededAction
} from "./actionTypes";
import { SpeedQuizHeader } from "../../Domain/Entities/SpeedQuizHeader";

export const getAllSpeedQuiz = (): GetAllSpeedQuizAction =>({
    type: GET_ALL_SPEED_QUIZ
})

export const getAllSpeedQuizFailed = (error: string): GetAllSpeedQuizFailedAction => ({
    type: GET_ALL_SPEED_QUIZ_FAILED,
    payload: error
})

export const getAllSpeedQuizSucceeded = (data: SpeedQuizHeader[]): GetAllSpeedQuizSucceededAction => ({
    type: GET_ALL_SPEED_QUIZ_SUCCEEDED,
    payload: data
})