import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import { CartesianData } from "../../Domain/Entity/CartesianData";
import { GET_AVATAR_STATS, GetAvatarStatsAction } from "./actionTypes";
import { getAvatarStatsFailed, getAvatarStatsSucceeded } from "./actions";

export const getAvatarStatsEpic: Epic = (action$: ActionsObservable<GetAvatarStatsAction>,
                                         store: StateObservable<GetAppStatsState>,
                                         { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_AVATAR_STATS),
        switchMap(() => statisticsService.getAvatarStats()
            .pipe(
                map((stats: CartesianData[]) => getAvatarStatsSucceeded(stats)),
                catchError(error => of(getAvatarStatsFailed(error)))
            )
        )
    )
