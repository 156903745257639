import { MCQResponse } from "./MCQResponse";

export class MCQQuestion {
    constructor(
        protected _id: number,
        protected _question: string,
        protected _description: string,
        protected _picture: File | undefined,
        protected _pictureName: string,
        protected _responses: MCQResponse[]
    ) {}

    get id(): number {
        return this._id
    }

    get question(): string {
        return this._question;
    }

    get description(): string {
        return this._description;
    }

    get picture(): File | undefined {
        return this._picture;
    }

    get pictureName(): string {
        return this._pictureName
    }

    get responses(): MCQResponse[] {
        return this._responses;
    }
}