import { Message } from "../../../../Message/Domain/Entity/Message";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { AppState } from "../../../../Redux-configuration/AppState";
import { messageStatsSelector } from "../../../Usecases/GetMessageStats/selector";
import { loadMessageListLoadingSelector, messageListSelector } from "../../../../Message/Usecases/List/selector";
import { Dispatch } from "redux";
import { LoadMessageListActionTypes } from "../../../../Message/Usecases/List/actionTypes";
import { loadMessageList } from "../../../../Message/Usecases/List/actions";
import { GetMessageStatsAction } from "../../../Usecases/GetMessageStats/actionTypes";
import { getMessageStats } from "../../../Usecases/GetMessageStats/actions";
import { connect } from "react-redux";
import { MessageStatisticsContainer } from "./MessageStatistics.container";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { employeeStatsSelector } from "../../../Usecases/GetEmployeeStats/selector";
import { getEmployeeStats } from "../../../Usecases/GetEmployeeStats/actions";
import { GetEmployeeStatsAction } from "../../../Usecases/GetEmployeeStats/actionTypes";

interface StateToPropsType {
    loading: boolean;
    messages: Message[] | null;
    messageStats: CartesianData[] | null;
    employeeStats: EmployeeData | null;
}

interface DispatchToPropsType {
    loadMessages: () => void
    getMessageStats: (messageId: string)=> void
    setTopBarTitle: (title: string) => void
    getEmployeeStats: () => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : loadMessageListLoadingSelector(state),
    messages     : messageListSelector(state),
    messageStats : messageStatsSelector(state),
    employeeStats: employeeStatsSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadMessages    : (): LoadMessageListActionTypes => dispatch(loadMessageList()),
    getMessageStats : (messageId: string): GetMessageStatsAction => dispatch(getMessageStats(messageId)),
    setTopBarTitle  : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title)),
    getEmployeeStats: (): GetEmployeeStatsAction => dispatch(getEmployeeStats())
})

export const MessageStatisticsPage = connect(mapStateToProps, mapDispatchToProps)(MessageStatisticsContainer)
