import { MessageService } from "../Domain/Gateway/Message.service";
import { ApiMessageService } from "../Adapters/Secondaries/Real/ApiMessage.service";

export class MessageDependenciesFactory {

    static getMessageService(): MessageService {
        return new ApiMessageService()
    }

}
