import {
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_FAILED,
    UPDATE_EMPLOYEE_SUCCEEDED,
    UpdateEmployeeActionTypes
} from "./actionTypes";
import { HandleEmployeeState } from "../../Config/State";

const initialState: HandleEmployeeState = {
    loading: false,
    error  : undefined
}
export const updateEmployeeReducer = (state = initialState, action: UpdateEmployeeActionTypes): HandleEmployeeState => {
    switch (action.type) {
        case UPDATE_EMPLOYEE:
            return { loading: true, error: undefined }
        case UPDATE_EMPLOYEE_FAILED:
            return { loading: false, error: action.payload }
        case UPDATE_EMPLOYEE_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}