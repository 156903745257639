import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";

export const UPDATE_MCQ_QUESTION = 'UPDATE_MCQ_QUESTION'
export const UPDATE_MCQ_QUESTION_FAILED = 'UPDATE_MCQ_QUESTION_FAILED'
export const UPDATE_MCQ_QUESTION_SUCCEEDED = 'UPDATE_MCQ_QUESTION_SUCCEEDED'

export interface UpdateMCQQuestionAction {
    type: typeof UPDATE_MCQ_QUESTION,
    payload: { question: MCQQuestion, mcqId: string }
}

export interface UpdateMCQQuestionFailedAction {
    type: typeof UPDATE_MCQ_QUESTION_FAILED
    payload: string
}

export interface UpdateMCQQuestionSucceededAction {
    type: typeof UPDATE_MCQ_QUESTION_SUCCEEDED
}

export type UpdateMCQQuestionActionTypes =
    UpdateMCQQuestionAction
    | UpdateMCQQuestionFailedAction
    | UpdateMCQQuestionSucceededAction