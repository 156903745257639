import { catchError, map, switchMap } from "rxjs/operators";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { UPDATE_EMPLOYEE, UpdateEmployeeAction } from "./actionTypes";
import { HandleEmployeeState } from "../../Config/State";
import { EmployeeService } from "../../Domain/Gateway/Employee.service";
import { updateEmployeeFailed, updateEmployeeSucceeded, } from "./actions";

export const updateEmployeeEpic: Epic = (action$: ActionsObservable<UpdateEmployeeAction>,
                                      store: StateObservable<HandleEmployeeState>,
                                      { employeeService }: { employeeService: EmployeeService }) =>
    action$.pipe(
        ofType(UPDATE_EMPLOYEE),
        switchMap((action) => employeeService.updateEmployee(action.payload)
            .pipe(
                map(() => updateEmployeeSucceeded()),
                catchError(error => of(updateEmployeeFailed(error)))
            )
        )
    )
