import React, { PureComponent } from "react";
import { Box, Typography } from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';

interface Props {
    label: string;
    value: string;
    error?: boolean;
    required?: boolean;
    onChange: (text: string) => void;
}

export class TextEditor extends PureComponent<Props> {

    render() {
        const className = this.props.error ? 'text-editor-form-error' : 'text-editor-form-value'
        const label = this.props.required === true ? this.props.label + ' *' : this.props.label

        return (
            <Box>
                <Typography component={'span'} style={{
                    fontSize  : 14,
                    fontWeight: '500',
                    color     : '#111827'
                }}>
                    {label}
                </Typography>

                <ReactQuill value={this.props.value} className={className}
                            placeholder={label}
                            onChange={(text) => this.props.onChange(text)}/>
            </Box>
        )
    }
}
