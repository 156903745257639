import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { authenticationRootEpic } from "../Authentication/Config/RootEpic";
import { authenticationEpicDependencies } from "../Authentication/Config/Epic.dependencies";
import { profileEpicsDependencies } from "../Profile/Config/Dependencies.redux";
import { profileRootEpics } from "../Profile/Config/Epics";
import { statisticsRootEpic } from "../Statistics/Config/RootEpic";
import { statisticsEpicDependencies } from "../Statistics/Config/Epic.dependencies";
import { speedQuizRootEpics } from "../SpeedQuiz/Config/RootEpics";
import { speedQuizEpicDependencies } from "../SpeedQuiz/Config/Epic.dependencies";
import { employeeRootEpics } from "../Employee/Config/RootEpics";
import { employeeEpicDependencies } from "../Employee/Config/Epic.dependencies";
import { agencyRootEpics } from "../Agence/Config/RootEpics";
import { agencyEpicDependencies } from "../Agence/Config/Epic.dependencies";
import { adventureRootEpic } from "../Adventure/Config/RootEpic";
import { adventureEpicDependencies } from "../Adventure/Config/Epic.dependencies";
import { messageEpicDependencies } from "../Message/Config/Epic.dependencies";
import { messageRootEpic } from "../Message/Config/RootEpic";

export const rootEpics = combineEpics(
    authenticationRootEpic,
    profileRootEpics,
    statisticsRootEpic,
    speedQuizRootEpics,
    employeeRootEpics,
    agencyRootEpics,
    adventureRootEpic,
    messageRootEpic
)

export const epicsMiddleware = createEpicMiddleware({
    dependencies: {
        ...authenticationEpicDependencies,
        ...profileEpicsDependencies,
        ...statisticsEpicDependencies,
        ...speedQuizEpicDependencies,
        ...employeeEpicDependencies,
        ...agencyEpicDependencies,
        ...adventureEpicDependencies,
        ...messageEpicDependencies
    }
})
