import React, { PureComponent, ReactNode } from "react";
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Adventure } from "../../../Domain/Entity/Adventure";
import { i18n } from "../../../../Configuration/I18n";
import { EnabledTag } from "../../../../Common/Adapters/Primaries/Components/EnabledTag";
import { DisabledTag } from "../../../../Common/Adapters/Primaries/Components/DisabledTag";
import { EditButton } from "../../../../Common/Adapters/Primaries/Components/EditButton";
import { EmptyTableText } from "../../../../Common/Adapters/Primaries/Components/EmptyTableText";
import { ApplicationContext } from "../../../../Configuration/Application.context";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    adventures: Adventure[]
}

interface DataTableAdventureType {
    id: string
    name: string
    start_date: string
    end_date: string
    active: boolean
    action: string
}

export class AdventureListTable extends PureComponent<Props> {

    render(): ReactNode {
        const columns = [
            { field: 'name', headerName: i18n.adventure.label, flex: 1 },
            { field: 'id', headerName: i18n.adventure.id, flex: 1 },
            { field: 'start_date', headerName: i18n.adventure.start_date, flex: 0.5 },
            { field: 'end_date', headerName: i18n.adventure.end_date, flex: 0.5 },
            {
                field     : 'active', headerName: i18n.adventure.status, flex: 0.5,
                renderCell: (params: GridCellParams) => params.value ? <EnabledTag/> : <DisabledTag/>
            },
            {
                field     : 'action',
                headerName: 'Actions',
                flex      : 0.5,
                sortable  : false,
                renderCell: (params: GridCellParams) => <EditButton path={'/adventure-update/' + params.value}/>
            }
        ]

        return (
            <DataGrid style={{backgroundColor: 'white'}}
                      rows={this.getDataTable(this.props.adventures)}
                      columns={columns}
                      autoHeight={true}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                      disableSelectionOnClick
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnFilter
                      disableColumnMenu
                      getRowClassName={(params) => `employee-active--${params.row.active}`}
                      sx={{
                          '& .MuiDataGrid-cell:focus': {
                              outline: 0,
                          },
                      }}
                      components={{
                          NoRowsOverlay: () => <EmptyTableText text={i18n.adventure.no_adventure}/>
                      }}/>
        )
    }

    getDataTable(adventures: Adventure[]): DataTableAdventureType[] {
        return adventures.map(adventure => ({
            id        : adventure.id,
            start_date: moment(adventure.startDate).format('DD MMM YYYY'),
            end_date  : moment(adventure.endDate).format('DD MMM YYYY'),
            name      : adventure.name,
            active    : adventure.enabled,
            action    : adventure.id
        }))
    }

}
