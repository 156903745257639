import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Area, ComposedChart, Line, Tooltip
} from 'recharts';
import { ApplicationContext } from "../../../../../Configuration/Application.context";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { StatisticsGridTitle } from "../../Component/Title";
import { i18n } from "../../../../../Configuration/I18n";
import { Theme } from "../../../../../Common/Config/Theme";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    stats: CartesianData[] | null;
    users: number | undefined;
}

export class SpeedQuizAreaChart extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <Box className={'statistics-grid'}>
                        <StatisticsGridTitle title={i18n.statistics.players_evolution}
                                             info={i18n.statistics.players_evolution}/>

                        <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart width={500}
                                           height={300}
                                           style={{ fontFamily: 'sans-serif', fontSize: 12 }}
                                           data={this.getFormattedData()}
                                           margin={{ top: 20, right: 40, bottom: 20, left: 0 }}>

                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="label"/>
                                <YAxis/>
                                <Tooltip/>

                                <Line type="monotone"
                                      dataKey="goal"
                                      dot={<Box/>}
                                      strokeWidth={2}
                                      stroke={Theme.darkBlue}
                                      name={i18n.statistics.users_number}/>

                                {this.renderEmptyDataText()}

                                <Area type="monotone"
                                      dataKey="value"
                                      strokeWidth={2}
                                      stroke={Theme.lightOrange}
                                      fill={Theme.lightOrange}
                                      name={i18n.statistics.quiz_players_number}/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    getFormattedData() {
        if (this.props.stats)
            return this.props.stats.map(stat => ({
                label: moment(stat.label, 'YYYY-MM-DD').format('dddd DD MMM'),
                value: stat.value,
                goal : this.props.users
            }))
        else
            return []
    }

    renderEmptyDataText() {
        if (this.props.stats && this.props.stats.length === 0)
            return (
                <text fill={'#891204'} fontSize={20} textAnchor="middle" x={'50%'} y={'45%'}>
                    {i18n.statistics.no_data}
                </text>
            )
    }

}
