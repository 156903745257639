import {
    GET_EMPLOYEE_STATS,
    GET_EMPLOYEE_STATS_FAILED, GET_EMPLOYEE_STATS_SUCCEEDED,
    GetEmployeeStatsAction,
    GetEmployeeStatsFailedAction, GetEmployeeStatsSucceededAction
} from "./actionTypes";
import { EmployeeData } from "../../Domain/Entity/EmployeeData";

export const getEmployeeStats = (): GetEmployeeStatsAction => ({
    type: GET_EMPLOYEE_STATS
})

export const getEmployeeStatsFailed = (error: string): GetEmployeeStatsFailedAction => ({
    type   : GET_EMPLOYEE_STATS_FAILED,
    payload: error
})

export const getEmployeeStatsSucceeded = (data: EmployeeData): GetEmployeeStatsSucceededAction => ({
    type   : GET_EMPLOYEE_STATS_SUCCEEDED,
    payload: data
})