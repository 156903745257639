import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { UpdateAgencyState } from "../../Config/State";
import { updateAgencyFailed, updateAgencySucceeded } from "./actions";
import { UPDATE_AGENCY, UpdateAgencyAction } from "./actionTypes";
import { AgencyService } from "../../Domain/Gateway/agencyService";
import { getAgencyList } from "../GetAgencyList/actions";

export const updateAgencyEpic: Epic = (action$: ActionsObservable<UpdateAgencyAction>,
                                    store: StateObservable<UpdateAgencyState>,
                                    { agencyService }: { agencyService: AgencyService }) =>
    action$.pipe(
        ofType(UPDATE_AGENCY),
        switchMap((action) => agencyService.updateAgency(action.payload)
            .pipe(
                concatMap(() => [
                    updateAgencySucceeded(),
                    getAgencyList()
                ]),
                catchError(error => of(updateAgencyFailed(error)))
            )
        )
    );
