import React, { PureComponent, ReactNode } from 'react'
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { Grid } from "@mui/material";
import { StatisticsBarChart } from "../../Component/BarChart";
import { i18n } from "../../../../../Configuration/I18n";

interface Props {
    pageStats: CartesianData[] | null
}
export class PageStats extends PureComponent<Props>{
    render(): ReactNode{
        return(
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <StatisticsBarChart title={i18n.statistics.stats_page_title} size={12}
                                        legend={i18n.statistics.visit_number}
                                        stats={this.props.pageStats ? this.mapPageStats(this.props.pageStats) : []} />
                </Grid>
            </Grid>
        )
    }

    mapPageStats(pageStats: CartesianData[]): CartesianData[]{
        const stats: CartesianData[] = []
        pageStats.map(item =>{
            switch (item.label){
                case 'DISPLAY_DASHBOARD':
                    stats.push(new CartesianData(i18n.statistics.dashboard_page, item.value))
                    break
                case 'DISPLAY_PROFILE':
                    stats.push(new CartesianData(i18n.statistics.profile_page, item.value))
                    break
                case 'DISPLAY_SCORE':
                    stats.push(new CartesianData(i18n.statistics.score_page, item.value))
                    break
                case 'DISPLAY_NEWS':
                    stats.push(new CartesianData(i18n.statistics.message_page, item.value))
                    break
                case 'DISPLAY_ADVENTURE':
                    stats.push(new CartesianData(i18n.statistics.adventure_page, item.value))
                    break
                case 'DISPLAY_SPEED_QUIZ':
                    stats.push(new CartesianData(i18n.statistics.speedQuiz_page, item.value))
                    break
                case 'DISPLAY_TEAM_MATE':
                    stats.push(new CartesianData(i18n.statistics.team_page, item.value))
                    break
                default:
                    stats.push(new CartesianData(item.label, item.value))
            }
        })
        return stats
    }
}
