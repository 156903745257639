import {
    GET_AVATAR_STATS,
    GET_AVATAR_STATS_FAILED,
    GET_AVATAR_STATS_SUCCEEDED,
    GetAvatarStatsActionTypes
} from './actionTypes';
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getAvatarStats = (): GetAvatarStatsActionTypes => ({
    type: GET_AVATAR_STATS
})

export const getAvatarStatsFailed = (error: string): GetAvatarStatsActionTypes => ({
    type   : GET_AVATAR_STATS_FAILED,
    payload: error
})

export const getAvatarStatsSucceeded = (stats: CartesianData[]): GetAvatarStatsActionTypes => ({
    type   : GET_AVATAR_STATS_SUCCEEDED,
    payload: stats
})
