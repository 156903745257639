import { ADD_EMPLOYEE, ADD_EMPLOYEE_FAILED, ADD_EMPLOYEE_SUCCEEDED, AddEmployeeActionTypes } from "./actionTypes";
import { HandleEmployeeState } from "../../Config/State";

const initialState: HandleEmployeeState = {
    loading: false,
    error  : undefined
}
export const addEmployeeReducer = (state = initialState, action: AddEmployeeActionTypes): HandleEmployeeState => {
    switch (action.type) {
        case ADD_EMPLOYEE:
            return { loading: true, error: undefined }
        case ADD_EMPLOYEE_FAILED:
            return { loading: false, error: action.payload }
        case ADD_EMPLOYEE_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}