import { LoadMessageListState } from "../../Config/State";
import {
    LOAD_MESSAGE_LIST,
    LOAD_MESSAGE_LIST_FAILED,
    LOAD_MESSAGE_LIST_SUCCEEDED,
    LoadMessageListActionTypes
} from './actionTypes';

const initialState: LoadMessageListState = {
    loading : false,
    messages: null,
    error   : undefined
}

export const loadMessageListReducer = (state = initialState, action: LoadMessageListActionTypes): LoadMessageListState => {
    switch (action.type) {
        case LOAD_MESSAGE_LIST:
            return {
                loading : true,
                messages: null,
                error   : undefined
            }
        case LOAD_MESSAGE_LIST_SUCCEEDED:
            return {
                loading : false,
                messages: action.payload,
                error   : undefined
            }
        case LOAD_MESSAGE_LIST_FAILED:
            return {
                loading : false,
                messages: null,
                error   : action.payload
            }
        default:
            return state
    }
};
