import {
    GET_REGION_LIST,
    GET_REGION_LIST_FAILED, GET_REGION_LIST_SUCCEEDED,
    GetRegionListAction,
    GetRegionListFailedAction,
    GetRegionListSucceededAction
} from "./actionTypes";
import { Region } from "../../Domain/Entities/Region";

export const getRegionList = (): GetRegionListAction => ({
    type: GET_REGION_LIST
})

export const getRegionListFailed = (error: string): GetRegionListFailedAction => ({
    type   : GET_REGION_LIST_FAILED,
    payload: error
})

export const getRegionListSucceeded = (regions: Region[]): GetRegionListSucceededAction => ({
    type   : GET_REGION_LIST_SUCCEEDED,
    payload: regions
})