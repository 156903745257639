import { MessageDTO } from '../DTO/Message.DTO';
import { Message } from "../../../../Domain/Entity/Message";
import { MessageBuilder } from "../../../../Domain/Builder/Message.builder";

export class MessageMapper {

    static mapDataToMessageList(messages: MessageDTO[]): Message[] {
        return messages.map(message =>
            new MessageBuilder()
                .withId(message.id.toString())
                .withTitle(message.title)
                .withText(message.message)
                .withStartDate(message.start)
                .withEndDate(message.end)
                .withIsEnabled(message.active)
                .build()
        )
    }

}
