import { Employee } from "../Entities/Employee";
import { Agency } from "../../../Agence/Domain/Entities/Agency";

export class EmployeeBuilder {
    protected _email: string;
    protected _firstName: string;
    protected _lastName: string;
    protected _qualification: 'ITINERANT' | 'AGENCE' | 'SUPPORT';
    protected _active: boolean;
    protected _job: string;
    protected _agency: Agency
    protected _avatar: string;

    withEmail(email: string): EmployeeBuilder {
        this._email = email
        return this
    }

    withFirstName(firstname: string): EmployeeBuilder {
        this._firstName = firstname
        return this
    }

    withLastName(lastname: string): EmployeeBuilder {
        this._lastName = lastname
        return this
    }

    withQualification(qualification: 'ITINERANT' | 'AGENCE' | 'SUPPORT'): EmployeeBuilder {
        this._qualification = qualification
        return this
    }

    withActive(active: boolean): EmployeeBuilder {
        this._active = active
        return this
    }

    withJob(job: string): EmployeeBuilder {
        this._job = job
        return this
    }

    withAgency(agency: Agency): EmployeeBuilder {
        this._agency = agency
        return this
    }

    withAvatar(avatar: string): EmployeeBuilder {
        this._avatar = avatar
        return this
    }

    build(): Employee {
        return new Employee(this._email,
            this._firstName,
            this._lastName,
            this._qualification,
            this._active,
            this._job,
            this._agency,
            this._avatar)
    }

}