import { Adventure } from "../../Domain/Entity/Adventure";

export const UPDATE_ADVENTURE = 'UPDATE_ADVENTURE'
export const UPDATE_ADVENTURE_FAILED ='UPDATE_ADVENTURE_FAILED'
export const UPDATE_ADVENTURE_SUCCEEDED = 'UPDATE_ADVENTURE_SUCCEEDED'


export interface UpdateAdventureAction {
    type: typeof UPDATE_ADVENTURE
    payload: Adventure
}

export interface UpdateAdventureFailedAction {
    type: typeof UPDATE_ADVENTURE_FAILED
    payload: string
}

export interface UpdateAdventureSucceededAction {
    type: typeof UPDATE_ADVENTURE_SUCCEEDED
}

export type UpdateAdventureActionTypes = UpdateAdventureAction | UpdateAdventureFailedAction | UpdateAdventureSucceededAction