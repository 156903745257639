import { SerieData } from "../../Domain/Entity/SerieData";

export const GET_SPEED_QUIZ_QUESTION_STATS = 'GET_SPEED_QUIZ_QUESTION_STATS'
export const GET_SPEED_QUIZ_QUESTION_STATS_FAILED = 'GET_SPEED_QUIZ_QUESTION_STATS_FAILED'
export const GET_SPEED_QUIZ_QUESTION_STATS_SUCCEEDED = 'GET_SPEED_QUIZ_QUESTION_STATS_SUCCEEDED'

export  interface GetSpeedQuizQuestionStatsAction {
    type: typeof GET_SPEED_QUIZ_QUESTION_STATS;
    payload: string
}

interface GetSpeedQuizQuestionStatsFailedAction {
    type: typeof GET_SPEED_QUIZ_QUESTION_STATS_FAILED;
    payload: string;
}

interface GetSpeedQuizQuestionStatsSucceededAction {
    type: typeof GET_SPEED_QUIZ_QUESTION_STATS_SUCCEEDED;
    payload: SerieData[];
}

export type GetSpeedQuizQuestionStatsActionTypes = GetSpeedQuizQuestionStatsAction | GetSpeedQuizQuestionStatsFailedAction | GetSpeedQuizQuestionStatsSucceededAction
