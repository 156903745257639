export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'
export const LOGIN_USER_SUCCEEDED = 'LOGIN_USER_SUCCEEDED'

export  interface LoginUserAction {
    type: typeof LOGIN_USER;
    payload: {
        email: string;
        password: string;
    };
}

interface LoginUserFailedAction {
    type: typeof LOGIN_USER_FAILED;
    payload: string;
}

interface LoginUserSucceededAction {
    type: typeof LOGIN_USER_SUCCEEDED;
}

export type LoginUserActionTypes = LoginUserAction | LoginUserFailedAction | LoginUserSucceededAction
