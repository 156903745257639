import React, { Component, ReactNode } from "react";
import { Grid, TextField, Typography } from '@mui/material';
import { i18n } from "../../../../../Configuration/I18n";
import { SpeedQuizAnswerContainer } from "./Answer";
import { SpeedQuizAnswerType, SpeedQuizQuestionType } from "../../../../Domain/SpeedQuiz.type";
import { SpeedQuizFormValidation } from "../FormValidation";

interface Props {
    title: string;
    error: boolean;
    question: SpeedQuizQuestionType;
    onChange: (question: SpeedQuizQuestionType) => void;
}

interface State {
    questionError: boolean;
    answersError: boolean;
    answer_1: SpeedQuizAnswerType
    answer_2: SpeedQuizAnswerType
    answer_3: SpeedQuizAnswerType
    answer_4: SpeedQuizAnswerType
}


export class SpeedQuizQuestionContainer extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            questionError: false,
            answersError : false,
            answer_1     : {
                text   : '',
                correct: false
            },
            answer_2     : {
                text   : '',
                correct: false
            },
            answer_3     : {
                text   : '',
                correct: false
            },
            answer_4     : {
                text   : '',
                correct: false
            }
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.question.responses.length > 0 && state.answer_1.text === '') {
            return {
                ...state,
                answer_1: {
                    id     : props.question.responses[0].id,
                    text   : props.question.responses[0].text,
                    correct: props.question.responses[0].correct
                },
                answer_2: {
                    id     : props.question.responses[1].id,
                    text   : props.question.responses[1].text,
                    correct: props.question.responses[1].correct
                },
                answer_3: {
                    id     : props.question.responses[2].id,
                    text   : props.question.responses[2].text,
                    correct: props.question.responses[2].correct
                },
                answer_4: {
                    id     : props.question.responses[3].id,
                    text   : props.question.responses[3].text,
                    correct: props.question.responses[3].correct
                }
            }
        }
        return state
    }

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error === true && this.props.error !== prevProps.error) {
            const answers = [this.state.answer_1, this.state.answer_2, this.state.answer_3, this.state.answer_4]
            this.setState({
                questionError: !SpeedQuizFormValidation.validateTitle(this.props.question.text),
                answersError : !SpeedQuizFormValidation.validateResponse(answers)
            })
        }
    }

    render(): ReactNode {
        return (
            <Grid container spacing={2} my={1}>
                <Grid item xs={12} sm={3}>
                    <Typography className={'form-block-title'}>
                        {this.props.title}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={9}>
                    <TextField fullWidth
                               required
                               sx={{ mb: 2 }}
                               label={i18n.speedQuiz.question}
                               value={this.props.question.text}
                               error={this.state.questionError}
                               onChange={(event) => this.props.onChange({
                                   ...this.props.question,
                                   text: event.target.value
                               })}/>

                    <Grid container spacing={2}>
                        <SpeedQuizAnswerContainer label={i18n.speedQuiz.answer_1}
                                                  answer={this.state.answer_1}
                                                  error={this.props.error}
                                                  answersError={this.state.answersError}
                                                  onChange={(key, value) => {
                                                      const answer_1 = {
                                                          ...this.state.answer_1,
                                                          [key]: value
                                                      }
                                                      this.setState({ answer_1 })
                                                      this.props.onChange({
                                                          ...this.props.question,
                                                          responses: [
                                                              answer_1,
                                                              this.state.answer_2,
                                                              this.state.answer_3,
                                                              this.state.answer_4
                                                          ]
                                                      })
                                                  }}/>

                        <SpeedQuizAnswerContainer label={i18n.speedQuiz.answer_2}
                                                  answer={this.state.answer_2}
                                                  error={this.props.error}
                                                  answersError={this.state.answersError}
                                                  onChange={(key, value) => {
                                                      const answer_2 = {
                                                          ...this.state.answer_2,
                                                          [key]: value
                                                      }
                                                      this.setState({ answer_2 })
                                                      this.props.onChange({
                                                          ...this.props.question,
                                                          responses: [
                                                              this.state.answer_1,
                                                              answer_2,
                                                              this.state.answer_3,
                                                              this.state.answer_4
                                                          ]
                                                      })
                                                  }}/>

                        <SpeedQuizAnswerContainer label={i18n.speedQuiz.answer_3}
                                                  answer={this.state.answer_3}
                                                  error={this.props.error}
                                                  answersError={this.state.answersError}
                                                  onChange={(key, value) => {
                                                      const answer_3 = {
                                                          ...this.state.answer_3,
                                                          [key]: value
                                                      }
                                                      this.setState({ answer_3 })
                                                      this.props.onChange({
                                                          ...this.props.question,
                                                          responses: [
                                                              this.state.answer_1,
                                                              this.state.answer_2,
                                                              answer_3,
                                                              this.state.answer_4
                                                          ]
                                                      })
                                                  }}/>

                        <SpeedQuizAnswerContainer label={i18n.speedQuiz.answer_4}
                                                  answer={this.state.answer_4}
                                                  error={this.props.error}
                                                  answersError={this.state.answersError}
                                                  onChange={(key, value) => {
                                                      const answer_4 = {
                                                          ...this.state.answer_4,
                                                          [key]: value
                                                      }
                                                      this.setState({ answer_4 })
                                                      this.props.onChange({
                                                          ...this.props.question,
                                                          responses: [
                                                              this.state.answer_1,
                                                              this.state.answer_2,
                                                              this.state.answer_3,
                                                              answer_4
                                                          ]
                                                      })
                                                  }}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
