import { catchError, map, switchMap } from "rxjs/operators";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { GET_REGION_LIST, GetRegionListAction } from "./actionTypes";
import { GetRegionListState } from "../../Config/State";
import { getRegionListFailed, getRegionListSucceeded } from "./actions";
import { AgencyService } from "../../Domain/Gateway/agencyService";
import { Region } from "../../Domain/Entities/Region";

export const getRegionListEpic: Epic = (action$: ActionsObservable<GetRegionListAction>,
                                        store: StateObservable<GetRegionListState>,
                                        { agencyService }: { agencyService: AgencyService }) =>
    action$.pipe(
        ofType(GET_REGION_LIST),
        switchMap(() => agencyService.getRegionList()
            .pipe(
                map((regions:Region[]) => getRegionListSucceeded(regions)),
                catchError(error => of(getRegionListFailed(error)))
            )
        )
    )
