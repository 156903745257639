import { SerieData } from "../../../Domain/Entity/SerieData";
import { AppState } from "../../../../Redux-configuration/AppState";
import {
    allSpeedQuizDataSelector,
    getAllSpeedQuizLoadingSelector
} from "../../../../SpeedQuiz/Usecases/GetAllSpeedQuiz/selector";
import { speedQuizQuestionStatsSelector } from "../../../Usecases/GetSpeedQuizQuestionStats/selector";
import { Dispatch } from "redux";
import { GetAllSpeedQuizAction } from "../../../../SpeedQuiz/Usecases/GetAllSpeedQuiz/actionTypes";
import { getAllSpeedQuiz } from "../../../../SpeedQuiz/Usecases/GetAllSpeedQuiz/actions";
import { GetSpeedQuizQuestionStatsAction } from "../../../Usecases/GetSpeedQuizQuestionStats/actionTypes";
import { getSpeedQuizQuestionStats } from "../../../Usecases/GetSpeedQuizQuestionStats/actions";
import { connect } from "react-redux";
import { SpeedQuizStatisticsContainer } from "./SpeedQuizStatistics.container";
import { SpeedQuizHeader } from "../../../../SpeedQuiz/Domain/Entities/SpeedQuizHeader";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { speedQuizResponseStatsSelector } from "../../../Usecases/GetSpeedQuizResponseStats/selectors";
import { GetSpeedQuizResponseStatsAction } from "../../../Usecases/GetSpeedQuizResponseStats/actionTypes";
import { getSpeedQuizResponseStats } from "../../../Usecases/GetSpeedQuizResponseStats/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { employeeStatsSelector } from "../../../Usecases/GetEmployeeStats/selector";
import { speedQuizPlayerStatsSelector } from "../../../Usecases/GetSpeedQuizPlayerStats/selector";
import { GetSpeedQuizPlayerStatsAction } from "../../../Usecases/GetSpeedQuizPlayerStats/actionTypes";
import { getSpeedQuizPlayerStats } from "../../../Usecases/GetSpeedQuizPlayerStats/actions";
import { GetEmployeeStatsAction } from "../../../Usecases/GetEmployeeStats/actionTypes";
import { getEmployeeStats } from "../../../Usecases/GetEmployeeStats/actions";
import { speedQuizPlayerCountSelector } from "../../../Usecases/GetSpeedQuizPlayerCount/selectors";
import { GetSpeedQuizPlayerCountAction } from "../../../Usecases/GetSpeedQuizPlayerCount/actionTypes";
import { getSpeedQuizPlayerCount } from "../../../Usecases/GetSpeedQuizPlayerCount/actions";

interface StateToPropsType {
    loading: boolean;
    allSpeedQuiz: SpeedQuizHeader[] | null;
    speedQuizStats: SerieData[] | null;
    speedQuizResponseStats: CartesianData[] | null;
    employeeStats: EmployeeData | null
    playerStats: CartesianData[] | null
    speedQuizPlayerCount: number | undefined
}

interface DispatchToPropsType {
    getAllSpeedQuiz: () => void;
    getSpeedQuizQuestionStats: (speedQuizId: string) => void;
    getSpeedQuizResponseStats: (speedQuizId: string) => void;
    setTopBarTitle: (title: string) => void
    getEmployeeStats: () => void
    getSpeedQuizPlayerStats: (speedQuizId: string) => void
    getSpeedQuizPlayerCount: (speedQuizId: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading               : getAllSpeedQuizLoadingSelector(state),
    allSpeedQuiz          : allSpeedQuizDataSelector(state),
    speedQuizStats        : speedQuizQuestionStatsSelector(state),
    speedQuizResponseStats: speedQuizResponseStatsSelector(state),
    employeeStats         : employeeStatsSelector(state),
    playerStats           : speedQuizPlayerStatsSelector(state),
    speedQuizPlayerCount  : speedQuizPlayerCountSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    getAllSpeedQuiz          : (): GetAllSpeedQuizAction => dispatch(getAllSpeedQuiz()),
    getSpeedQuizQuestionStats: (speedQuizId: string): GetSpeedQuizQuestionStatsAction => dispatch(getSpeedQuizQuestionStats(speedQuizId)),
    getSpeedQuizResponseStats: (speedQuizId: string): GetSpeedQuizResponseStatsAction => dispatch(getSpeedQuizResponseStats(speedQuizId)),
    setTopBarTitle           : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title)),
    getSpeedQuizPlayerStats  : (speedQuizId: string): GetSpeedQuizPlayerStatsAction => dispatch(getSpeedQuizPlayerStats(speedQuizId)),
    getSpeedQuizPlayerCount  : (speedQuizId: string): GetSpeedQuizPlayerCountAction=> dispatch(getSpeedQuizPlayerCount(speedQuizId)),
    getEmployeeStats         : (): GetEmployeeStatsAction => dispatch(getEmployeeStats())
})

export const SpeedQuizStatisticsPage = connect(mapStateToProps, mapDispatchToProps)(SpeedQuizStatisticsContainer)
