import React, { PureComponent, ReactNode } from "react";
import { Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
    title: string;
    info: string;
    children?: ReactNode | undefined
}

export class StatisticsGridTitle extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Box className={'block-title'}>
                <p className={'title'}>{this.props.title}</p>
                {this.props.children ?
                    this.props.children :
                    <Tooltip title={this.props.info}>
                        <InfoOutlinedIcon/>
                    </Tooltip>
                }
            </Box>
        )
    }
}
