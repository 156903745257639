import React, { PureComponent, ReactNode } from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import { AppUserBadges } from "./Badges";

interface Props {
    name: string
}

export class StatisticsBadgeDetails extends PureComponent<Props> {

    render(): ReactNode {
        const badge = AppUserBadges.find(item => item.id === this.props.name)
        if (badge)
            return (
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                        <Avatar className={'badge-image'}
                                alt={badge.title}
                                src={badge.picture}
                                sx={{ width: 32, height: 32 }}/>

                        <Typography sx={{ ml: 2, fontWeight: 600, fontSize: 14 }}>
                            {badge.title}
                        </Typography>
                    </Box>

                    <Typography component={'span'} sx={{ fontSize: 12 }}>
                        {badge.description}
                    </Typography>
                </Box>
            )
        else
            return <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{this.props.name}</Typography>
    }
}
