import {
    ADD_SPEED_QUIZ,
    ADD_SPEED_QUIZ_FAILED,
    ADD_SPEED_QUIZ_SUCCEEDED,
    AddSpeedQuizActionTypes
} from "./actionTypes";
import { HandleSpeedQuizState } from "../../Config/State";

const initialState: HandleSpeedQuizState = {
    loading: false,
    error  : undefined
}
export const addSpeedQuizReducer = (state = initialState, action: AddSpeedQuizActionTypes): HandleSpeedQuizState => {
    switch (action.type) {
        case ADD_SPEED_QUIZ:
            return { loading: true, error: undefined }
        case ADD_SPEED_QUIZ_FAILED:
            return { loading: false, error: action.payload }
        case ADD_SPEED_QUIZ_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}