import { Agency } from "../../../Agence/Domain/Entities/Agency";

export class Employee {
    constructor(
        protected _email: string,
        protected _firstName: string,
        protected _lastName: string,
        protected _qualification: 'ITINERANT' | 'AGENCE' | 'SUPPORT',
        protected _active: boolean,
        protected _job: string,
        protected _agency: Agency,
        protected _avatar: string
    ) {
    }

    get email(): string {
        return this._email;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get qualification(): 'ITINERANT' | 'AGENCE' | 'SUPPORT' {
        return this._qualification;
    }

    get active(): boolean {
        return this._active;
    }

    get job(): string {
        return this._job;
    }

    get agency(): Agency {
        return this._agency
    }

    get avatar(): string {
        return this._avatar;
    }

}