import { connect } from 'react-redux'
import { AvatarType } from "../../../../Profile/Domain/Entities/Profile-Types";
import { AppState } from "../../../../Redux-configuration/AppState";
import { profileSelector } from "../../../../Profile/Usecases/SetStoreProfile/SetReduxProfile.selectors";
import { AppTopBarContainer } from "./AppTopBar.container";
import { Dispatch } from "redux";
import { logoutUser } from "../../../../Authentication/Usecases/Logout/actions";
import { LogoutUserAction } from "../../../../Authentication/Usecases/Logout/actionTypes";
import { logoutUserSuccessSelector } from "../../../../Authentication/Usecases/Logout/selector";
import { topBarTitleSelector } from "../../../Usecases/SetConfigurationApp/selectors";

interface StateToPropsType {
    avatar: AvatarType | undefined;
    topBarTitle: string;
    successLogout: null | boolean;
}

interface DispatchToPropsType {
    logout: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    logout: (): LogoutUserAction => dispatch(logoutUser())
})


const mapStateToProps = (appState: AppState): StateToPropsType => ({
    avatar       : profileSelector(appState)?.avatar,
    topBarTitle  : topBarTitleSelector(appState),
    successLogout: logoutUserSuccessSelector(appState)
})

export const AppTopBar = connect(mapStateToProps, mapDispatchToProps)(AppTopBarContainer)
