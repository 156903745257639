import { AgencyDto, RegionDto } from "../DTO/agencyDto";
import { Agency } from "../../../../Domain/Entities/Agency";
import { AgencyBuilder } from "../../../../Domain/Builder/AgencyBuilder";
import { Region } from "../../../../Domain/Entities/Region";

export class AgencyMapper {

    static mapAgencyDtoToAgency(agencyDto: AgencyDto[]): Agency[] {
        return agencyDto.map(item => {
            const region = new Region(item.regionCode, item.regionName, item.regionLabel)
            return new AgencyBuilder()
                .withAgencyCode(item.agencyCode)
                .withAgencyName(item.agencyName)
                .withRegion(region)
                .withNetwork(item.network)
                .build()
        })
    }

    static mapRegionDtoToRegion(regionDto: RegionDto[]): Region[] {
        return regionDto.map(item => new Region(item.code, item.regionName, item.regionLabel))
    }

}
