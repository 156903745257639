import { AvatarType, ProfileType } from "./Profile-Types";
import { Profile } from "./Profile";

export class ProfileBuilder {
    protected _firstName: string
    protected _lastName: string
    protected _agency: string
    protected _agencyCode: string
    protected _region: string
    protected _avatar: AvatarType
    protected _score: number
    protected _type: ProfileType
    protected _email: string


    withFirstName(firstName: string): ProfileBuilder {
        this._firstName = firstName
        return this;
    }

    withLastName(lastName: string): ProfileBuilder {
        this._lastName = lastName
        return this;
    }

    withAgency(agency: string): ProfileBuilder {
        this._agency = agency
        return this
    }

    withAgencyCode(agencyCode: string): ProfileBuilder {
        this._agencyCode = agencyCode
        return this
    }

    withRegion(region: string): ProfileBuilder {
        this._region = region
        return this
    }

    withAvatar(avatar: AvatarType): ProfileBuilder {
        this._avatar = avatar
        return this
    }

    withScore(score: number): ProfileBuilder {
        this._score = score
        return this
    }

    withType(type: ProfileType): ProfileBuilder {
        this._type = type
        return this
    }

    withEmail(email: string): ProfileBuilder {
        this._email = email
        return this
    }

    build(): Profile {
        return new Profile(this._firstName, this._lastName, this._agency, this._agencyCode, this._region, this._avatar, this._score, this._type, this._email)
    }
}