import React, { PureComponent, ReactNode } from "react";
import { Alert, Box, Grid, IconButton, Typography } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import './pictureInput.css'

interface Props {
    value: string | undefined;
    onChange: (file: File) => void;
    label?: string
    description?: string
    error?: boolean
    width?: number
    height?: number
    id: string
}

interface State {
    errorMessage: string
}

export class PictureInput extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            errorMessage: ''
        }
    }

    render(): ReactNode {
        const className = this.props.error ? 'container-picture-input picture-input-error' : 'container-picture-input'
        const label = this.props.label ? this.props.label : i18n.common.add_picture
        const subTitle = this.props.description ? this.props.description : i18n.common.add_picture_description

        return (
            <Box>
                <input accept="image/*"
                       id={this.props.id}
                       style={{ display: 'none' }}
                       type="file"
                       onChange={(event) => {
                           if (event.target.files !== null) {
                               this.checkFileSize(event.target.files[0]).then(result => {
                                   if (result && event.target.files !== null) {
                                       this.props.onChange(event.target.files[0])
                                       this.setState({ errorMessage: '' })
                                   }
                                   else if (this.props.width && this.props.height)
                                       this.setState({ errorMessage: i18n.common.error_size_picture})
                                   else
                                       this.setState({ errorMessage: i18n.common.error_file_size })
                               })
                           }
                       }}
                />

                <label htmlFor={this.props.id}>
                    <Box className={className}>
                        <IconButton color="primary" component="span">
                            <LibraryAddIcon fontSize={'large'}/>
                        </IconButton>

                        {this.props.value ? this.renderValue(this.props.value) :
                                <Typography className={'picture-input-title'}>
                                    {label}
                                </Typography>
                        }

                        <Typography className={'picture-input-subtitle'} component="span">
                            {subTitle}
                        </Typography>

                        {this.state.errorMessage !== '' && <Alert sx={{ml: 2}} severity="error">{this.state.errorMessage}</Alert>}
                    </Box>
                </label>
            </Box>
        )
    }

    renderValue(value: string): JSX.Element {
        if (this.isURL(value))
            return (<Box className="adventure-image" component="img" src={this.props.value}/>)
        else
            return (<Grid item><Typography className={'picture-input-value'}>
                {this.props.value}
            </Typography></Grid>)

    }

    checkFileSize(image: File): Promise<boolean> {
        const fileSize = image.size / 1024 / 1024;
        let ratio = 0
        if (this.props.width && this.props.height)
            ratio = Math.round(this.props.width / this.props.height)
        const promiseCheck = new Promise<boolean>((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(image);
            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                if (ratio === 0)
                    resolve(fileSize < 2)
                else
                    resolve(Math.round(img.width / img.height) === ratio && fileSize < 2)
            }
            img.src = objectUrl
        })
        return promiseCheck
    }

    isURL(value: string): boolean {
        const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        return value.length > 0 && regex.test(value)
    }
}
