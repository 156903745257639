import { combineEpics, Epic } from 'redux-observable';
import { addMessageEpic } from "../Usecases/AddMessage/epic";
import { loadMessageListEpic } from "../Usecases/List/epic";
import { updateMessageEpic } from "../Usecases/Update/epic";

export const messageRootEpic: Epic = combineEpics(
    addMessageEpic,
    loadMessageListEpic,
    updateMessageEpic
)
