import {
    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_FAILED,
    GET_EMPLOYEE_LIST_SUCCEEDED,
    GetEmployeeListActionTypes
} from "./actionTypes";
import { GetEmployeeListState } from "../../Config/State";

const initialState: GetEmployeeListState = {
    loading  : false,
    employees: null,
    error    : undefined
}
export const getEmployeeListReducer = (state = initialState, action: GetEmployeeListActionTypes): GetEmployeeListState => {
    switch (action.type) {
        case GET_EMPLOYEE_LIST:
            return { loading: true, employees: null, error: undefined }
        case GET_EMPLOYEE_LIST_FAILED:
            return { loading: false, employees: null, error: action.payload }
        case GET_EMPLOYEE_LIST_SUCCEEDED:
            return { loading: false, employees: action.payload, error: undefined }
        default:
            return state
    }
}