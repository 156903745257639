import { Agency } from "../../Domain/Entities/Agency";

export const ADD_AGENCY = 'ADD_AGENCY'
export const ADD_AGENCY_FAILED = 'ADD_AGENCY_FAILED'
export const ADD_AGENCY_SUCCEEDED = 'ADD_AGENCY_SUCCEEDED'

export interface AddAgencyAction {
    type: typeof ADD_AGENCY;
    payload: Agency;
}

interface AddAgencyFailedAction {
    type: typeof ADD_AGENCY_FAILED;
    payload: string;
}

interface AddAgencySucceededAction {
    type: typeof ADD_AGENCY_SUCCEEDED;
}

export type AddAgencyActionTypes = AddAgencyAction | AddAgencyFailedAction | AddAgencySucceededAction
