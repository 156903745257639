import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { AddMessageContainer } from "./AddMessage.container";
import { addMessageSuccessSelector } from "../../../Usecases/AddMessage/selector";
import { Message } from "../../../Domain/Entity/Message";
import { AddMessageActionTypes } from "../../../Usecases/AddMessage/actionTypes";
import { addMessage } from "../../../Usecases/AddMessage/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    success: boolean | null;
}

interface DispatchToPropsType {
    addMessage: (message: Message) => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    success: addMessageSuccessSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addMessage: (message: Message): AddMessageActionTypes => dispatch(addMessage(message)),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AddMessagePage = connect(mapStateToProps, mapDispatchToProps)(AddMessageContainer)
