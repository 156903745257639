import React, { PureComponent } from "react";
import { Alert, Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { FormBlock } from "../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { TextEditor } from "../../../../Common/Adapters/Primaries/Components/Form/TextEditor";
import { Message } from "../../../Domain/Entity/Message";
import { MessageBuilder } from "../../../Domain/Builder/Message.builder";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { DateTimePicker } from "../../../../Common/Adapters/Primaries/Components/DateTimePicker";
import { MessageFormValidation } from "../FormValidation";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    message: Message | null;
    submitMessage: (message: Message) => void;
}

interface State {
    id: string;
    title: string;
    text: string;
    startDate: string;
    endDate: string;
    isEnabled: boolean;
    titleError: boolean;
    textError: boolean;
    dateError: boolean;
    errorMsg: string | undefined;
}

export class MessageForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            id        : '',
            title     : '',
            text      : '',
            startDate : '',
            endDate   : '',
            isEnabled : false,
            titleError: false,
            textError : false,
            dateError : false,
            errorMsg  : undefined
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.message && state.id === '') {
            return {
                ...state,
                id       : props.message.id,
                title    : props.message.title,
                text     : props.message.text,
                startDate: moment(props.message.startDate).format('YYYY-MM-DDTHH:mm'),
                endDate  : moment(props.message.endDate).format('YYYY-MM-DDTHH:mm'),
                isEnabled: props.message.isEnabled
            }
        }
        return null
    }

    render() {
        return (
            <Box component="form"
                 noValidate
                 autoComplete="off">

                <FormBlock title={i18n.message.message}>
                    <TextField fullWidth
                               sx={{ mb: 2 }}
                               label={i18n.message.title}
                               value={this.state.title}
                               error={this.state.titleError}
                               onChange={(event) => this.setState({
                                   title     : event.target.value,
                                   titleError: false,
                                   errorMsg  : undefined
                               })}/>

                    <TextEditor label={i18n.message.details}
                                value={this.state.text}
                                error={this.state.textError}
                                onChange={(text) => this.setState({
                                    text,
                                    textError: false,
                                    errorMsg : undefined
                                })}/>

                    <FormControlLabel label={i18n.message.activate}
                                      sx={{ mt: 1 }}
                                      control={
                                          <Checkbox checked={this.state.isEnabled}
                                                    onChange={() => this.setState({ isEnabled: !this.state.isEnabled })}/>
                                      }/>
                </FormBlock>

                <FormBlock title={i18n.message.date}>
                    <DateTimePicker startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    error={this.state.dateError}
                                    onChange={(startDate: string, endDate: string) => this.setState({
                                        startDate,
                                        endDate,
                                        dateError: false,
                                        errorMsg : undefined
                                    })}/>
                </FormBlock>

                {this.state.errorMsg && <Alert severity="error" sx={{ mb: 2 }}>{this.state.errorMsg}</Alert>}

                <Button variant="contained" onClick={(): void => this.submitMessage()}>
                    {i18n.message.save}
                </Button>

            </Box>
        )
    }

    submitMessage() {
        this.setState({
            errorMsg  : MessageFormValidation.validate(this.state.title, this.state.text, this.state.startDate, this.state.endDate),
            titleError: !MessageFormValidation.validateTitle(this.state.title),
            textError : !MessageFormValidation.validateDescription(this.state.text),
            dateError : !MessageFormValidation.validateDate(this.state.startDate, this.state.endDate)
        }, () => {
            if (!this.state.errorMsg)
                this.props.submitMessage(
                    new MessageBuilder()
                        .withId(this.state.id)
                        .withTitle(this.state.title)
                        .withText(this.state.text)
                        .withStartDate(moment(this.state.startDate, 'YYYY-MM-DDTHH:mm').format())
                        .withEndDate(moment(this.state.endDate, 'YYYY-MM-DDTHH:mm').format())
                        .withIsEnabled(this.state.isEnabled)
                        .build()
                )
        })

    }
}
