import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, concatMap, switchMap } from 'rxjs/operators'
import { RetrieveProfileState } from "../../Config/State";
import { RETRIEVE_PROFILE, RetrieveProfileAction } from "./RetrieveProfile.types";
import { ProfileService } from "../../Domain/Gateway/Profile.service";
import { retrieveProfileFailed, retrieveProfileSucceeded } from "./RetrieveProfile.actions";
import { setStoreProfile } from "../SetStoreProfile/SetReduxProfile.actions";
import { Profile } from "../../Domain/Entities/Profile";

export const retrieveProfileEpic: Epic = (action$: ActionsObservable<RetrieveProfileAction>,
                                          store: StateObservable<RetrieveProfileState>,
                                          { profileService }: { profileService: ProfileService; }) =>
    action$.pipe(
        ofType(RETRIEVE_PROFILE),
        switchMap(() => profileService.retrieveRemoteProfile()
            .pipe(
                concatMap((profile: Profile) => [
                    setStoreProfile(profile),
                    retrieveProfileSucceeded()
                ]),
                catchError((error: string) => of(retrieveProfileFailed(error)))
            )
        )
    )
