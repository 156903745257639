import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { ADD_MESSAGE, AddMessageAction } from "./actionTypes";
import { AddMessageState } from "../../Config/State";
import { MessageService } from "../../Domain/Gateway/Message.service";
import { addMessageFailed, addMessageSucceeded } from "./actions";
import { loadMessageList } from "../List/actions";

export const addMessageEpic: Epic = (action$: ActionsObservable<AddMessageAction>,
                                     store: StateObservable<AddMessageState>,
                                     { messageService }: { messageService: MessageService }) =>
    action$.pipe(
        ofType(ADD_MESSAGE),
        switchMap((action) => messageService.addMessage(action.payload)
            .pipe(
                concatMap(() => [
                    addMessageSucceeded(),
                    loadMessageList()
                ]),
                catchError(error => of(addMessageFailed(error)))
            )
        )
    )
