import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { Region } from "../../../Domain/Entities/Region";

interface Props {
    value: string | undefined;
    error: boolean;
    onChange: (region: string) => void;
    data: Region[] | null
}

export const AgencyFormRegionSelect = (props: Props) => {
    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="region-select-label">{i18n.agency.region_name}</InputLabel>

            <Select id="region-select"
                    labelId="region-select-label"
                    value={props.value}
                    error={props.error}
                    label={i18n.agency.region_name}
                    onChange={(event: SelectChangeEvent) => props.onChange(event.target.value)}>

                {props.data?.map(region => <MenuItem key={region.regionCode}
                                                     value={region.regionCode}>{region.regionName}</MenuItem>)}
            </Select>
        </FormControl>
    )
};
