export const LocaleFr = {
    months            : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort       : 'janv._fevr._mars_avr._mai_juin_juil._aout_sept._oct._nov._dec.'.split('_'),
    monthsParseExact  : true,
    weekdays          : 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    weekdaysShort     : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin       : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat    : {
        LT  : 'HH:mm',
        LTS : 'HH:mm:ss',
        L   : 'DD/MM/YYYY',
        LL  : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar          : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime      : {
        future: 'dans %s',
        past  : 'il y a %s',
        s     : 'quelques secondes',
        m     : 'une minute',
        mm    : '%d minutes',
        h     : 'une heure',
        hh    : '%d heures',
        d     : 'un jour',
        dd    : '%d jours',
        M     : 'un mois',
        MM    : '%d mois',
        y     : 'un an',
        yy    : '%d ans'
    }
}
