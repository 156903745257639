import {
    USER_IS_AUTHENTICATED,
    USER_IS_NOT_AUTHENTICATED,
    AUTHENTICATE_USER, AuthenticateUserActionTypes
} from './actionTypes';

export const authenticateUser = (): AuthenticateUserActionTypes => ({
    type: AUTHENTICATE_USER
})

export const userIsAuthenticated = (token: string): AuthenticateUserActionTypes => ({
    type   : USER_IS_AUTHENTICATED,
    payload: token
})

export const userIsNotAuthenticated = (): AuthenticateUserActionTypes => ({
    type: USER_IS_NOT_AUTHENTICATED
})
