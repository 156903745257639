import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import { SerieData } from "../../Domain/Entity/SerieData";
import { GET_MCQ_STATS, GetMcqStatsAction } from "./actionTypes";
import { getMcqStatsFailed, getMcqStatsSucceeded } from "./actions";

export const getMcqStatsEpic: Epic = (action$: ActionsObservable<GetMcqStatsAction>,
    store: StateObservable<GetAppStatsState>,
    { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_MCQ_STATS),
        switchMap((action) => 
            statisticsService.getMcqStats(action.payload)
                .pipe(
                    map((stats: SerieData[]) => getMcqStatsSucceeded(stats)),
                    catchError(error => of(getMcqStatsFailed(error)))
                )
        )
    )
