import { SET_TOP_BAR_TITLE, SetConfigurationActionTypes } from "./actionTypes";
import { ApplicationState } from "../../Config/State";

const initialState: ApplicationState = {
    topBarTitle: ''
}
export const setConfigurationAppReducer = (state = initialState, action: SetConfigurationActionTypes): ApplicationState => {
    switch (action.type) {
        case SET_TOP_BAR_TITLE:
            return { topBarTitle: action.payload }
        default:
            return state
    }
}