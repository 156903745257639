import { ApplicationContext } from "../../../Configuration/Application.context";
import { i18n } from "../../../Configuration/I18n";

const moment = ApplicationContext.getInstance().momentJs()

export class MessageFormValidation {

    static validate(title: string, description: string, startDate: string, endDate: string): string | undefined {
        if (title.length === 0 || description.length === 0 || startDate.length === 0 || endDate.length === 0)
            return  i18n.message.fill_in_all_inputs

        else if (!this.validateTitle(title))
            return i18n.message.title_error

        else if (!this.validateDescription(description))
            return i18n.message.text_error

        else if (!this.validateDate(startDate, endDate))
            return i18n.message.date_error

        else
            return undefined
    }

    static validateTitle(title: string): boolean {
        return title.length > 2
    }

    static validateDescription(description: string): boolean {
        return description.length > 15
    }

    static validateDate(startDate: string, endDate: string): boolean {
        if (startDate.length > 0 && endDate.length > 0) {
            const _startDate = moment(startDate, 'YYYY-MM-DDTHH:mm')
            const _endDate = moment(endDate, 'YYYY-MM-DDTHH:mm')
            const duration = moment.duration(_endDate.diff(_startDate));
            const hours = duration.asHours()
            return hours > 3
        }
        else
            return false
    }

}
