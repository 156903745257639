import {
    REQUEST_PASSWORD,
    REQUEST_PASSWORD_FAILED,
    REQUEST_PASSWORD_SUCCEEDED,
    RequestPasswordActionTypes
} from './actionTypes';

export const requestPassword = (email: string): RequestPasswordActionTypes => ({
    type   : REQUEST_PASSWORD,
    payload: email
})

export const requestPasswordFailed = (error: string): RequestPasswordActionTypes => ({
    type   : REQUEST_PASSWORD_FAILED,
    payload: error
})

export const requestPasswordSucceeded = (): RequestPasswordActionTypes => ({
    type: REQUEST_PASSWORD_SUCCEEDED
})
