import {
    UPDATE_MESSAGE,
    UPDATE_MESSAGE_FAILED,
    UPDATE_MESSAGE_SUCCEEDED,
    UpdateMessageActionTypes
} from './actionTypes';
import { Message } from "../../Domain/Entity/Message";

export const updateMessage = (message: Message): UpdateMessageActionTypes => ({
    type   : UPDATE_MESSAGE,
    payload: message
})

export const updateMessageFailed = (error: string): UpdateMessageActionTypes => ({
    type   : UPDATE_MESSAGE_FAILED,
    payload: error
})

export const updateMessageSucceeded = (): UpdateMessageActionTypes => ({
    type: UPDATE_MESSAGE_SUCCEEDED
})
