import React, { PureComponent, ReactNode } from "react";
import { Box, CircularProgress } from '@mui/material';
import { i18n } from "../../../../Configuration/I18n";
import { Adventure } from "../../../Domain/Entity/Adventure";
import { PageTitleWithButton } from "../../../../Common/Adapters/Primaries/Components/PageTitleWithButton";
import { AdventureListTable } from "./AdventureListTable";

interface Props {
    adventures: Adventure[] | null;
    loading: boolean;
    getAdventureList: () => void;
    setTopBarTitle: (title: string) => void
}

export class AdventureListContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.getAdventureList()
        this.props.setTopBarTitle(i18n.adventure.adventure_title)
    }

    render(): ReactNode {
        return (
            <Box>
                <PageTitleWithButton title={i18n.adventure.adventure_list}
                                     button={i18n.adventure.add_adventure}
                                     path={'/adventure-add'}/>

                {this.props.loading &&
                <Box className={'page-loading'}>
                    <CircularProgress/>
                </Box>
                }

                {this.props.adventures && <AdventureListTable adventures={this.props.adventures}/>}

            </Box>
        )
    }
}
