import React, { PureComponent } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { FormBlock } from "../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { Agency } from "../../../Domain/Entities/Agency";
import { AgencyBuilder } from "../../../Domain/Builder/AgencyBuilder";
import { AgencyFormNetworkSelect } from "./Network.select";
import { AgencyFormRegionSelect } from "./Region.select";
import { Region } from "../../../Domain/Entities/Region";

interface Props {
    agency: Agency | null;
    submitAgency: (agency: Agency) => void;
    error: string | undefined
    regions: Region[] | null
}

interface State {
    agencyCode: string;
    agencyName: string;
    regionCode: string | undefined;
    network: string;
    agencyCodeError: boolean;
    agencyNameError: boolean;
    regionCodeError: boolean;
    disabled: boolean
}

export class AgencyForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            agencyCode     : '',
            agencyName     : '',
            regionCode     : undefined,
            network        : 'CGED',
            agencyCodeError: false,
            agencyNameError: false,
            regionCodeError: false,
            disabled       : false
        }
    }

    static getDerivedStateFromProps(props: Props, state: State): State {
        if (props.agency && state.agencyCode === '')
            return {
                agencyCode     : props.agency.agencyCode,
                agencyName     : props.agency.agencyName,
                regionCode     : props.agency.region.regionCode,
                network        : props.agency.network,
                disabled       : true,
                agencyCodeError: false,
                agencyNameError: false,
                regionCodeError: false
            }
        return state
    }

    render() {
        return (
            <Box component="form"
                 noValidate
                 autoComplete="off">

                <FormBlock title={i18n.agency.agency}>
                    <TextField fullWidth
                               sx={{ mb: 2 }}
                               label={i18n.agency.agency_code}
                               value={this.state.agencyCode}
                               disabled={this.state.disabled}
                               error={this.state.agencyCodeError}
                               onChange={(event) => this.setState({
                                   agencyCode     : event.target.value,
                                   agencyCodeError: false
                               })}/>

                    <TextField fullWidth
                               sx={{ mb: 2 }}
                               label={i18n.agency.agency_name}
                               value={this.state.agencyName}
                               error={this.state.agencyNameError}
                               onChange={(event) => this.setState({
                                   agencyName     : event.target.value,
                                   agencyNameError: false
                               })}/>

                    <AgencyFormRegionSelect value={this.state.regionCode}
                                            error={this.state.regionCodeError}
                                            data={this.props.regions}
                                            onChange={(regionCode: string) => this.setState({
                                                regionCode,
                                                regionCodeError: false
                                            })
                                            }/>

                    <AgencyFormNetworkSelect value={this.state.network}
                                             disabled={this.state.disabled}
                                             onChange={(network: string) => this.setState({ network })}/>
                </FormBlock>

                {this.renderErrorMessage()}

                <Button variant="contained" onClick={(): void => this.submitAgency()}>
                    {i18n.agency.save}
                </Button>

            </Box>
        )
    }

    submitAgency() {
        this.setState({
            agencyCodeError: this.state.agencyCode === '',
            agencyNameError: this.state.agencyName === '',
            regionCodeError: this.state.regionCode === undefined
        }, () => {
            if (!this.state.agencyCodeError && !this.state.agencyNameError && !this.state.agencyNameError) {
                const region: Region | undefined = this.getRegionByCode(this.state.regionCode)
                const agency: AgencyBuilder = new AgencyBuilder()
                    .withAgencyCode(this.state.agencyCode)
                    .withAgencyName(this.state.agencyName)
                    .withNetwork(this.state.network)
                if (region)
                    agency.withRegion(region)
                this.props.submitAgency(agency.build())
            }
        })
    }

    renderErrorMessage(): JSX.Element | undefined {
        if (this.props.error && this.props.error === '409')
            return <Alert severity="error" sx={{ mb: 2 }}>{i18n.agency.agency_already_exist}</Alert>
        if (this.state.agencyCodeError || this.state.agencyNameError || this.state.regionCodeError)
            return <Alert severity="error" sx={{ mb: 2 }}>{i18n.agency.fill_in_all_inputs}</Alert>
    }

    getRegionByCode(regionCode: string | undefined): Region | undefined {
        return regionCode ? this.props.regions?.find(item => item.regionCode.toString() === regionCode.toString()) : undefined
    }
}
