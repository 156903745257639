import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs/index";
import {
    DELETE_MCQ_QUESTION,
    DeleteMCQQuestionAction,
} from "./actionTypes";

import {
    deleteMCQQuestionFailed,
    deleteMCQQuestionSucceeded,
} from "./actions";
import { HandleAdventureState } from "../../../Config/State";
import { AdventureService } from "../../../Domain/Gateway/Adventure.service";

export const deleteMCQQuestionEpic: Epic = (action$: ActionsObservable<DeleteMCQQuestionAction>,
                                            store: StateObservable<HandleAdventureState>,
                                            { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(DELETE_MCQ_QUESTION),
        switchMap((action) => adventureService.deleteMCQQuestion(action.payload.questionId, action.payload.mcqId)
            .pipe(
                map(() => deleteMCQQuestionSucceeded()
                ),
                catchError(error => of(deleteMCQQuestionFailed(error)))
            )
        ))
