import { ADD_ADVENTURE, ADD_ADVENTURE_FAILED, ADD_ADVENTURE_SUCCEEDED, AddAdventureActionTypes } from "./actionTypes";
import { HandleAdventureState } from "../../Config/State";

const initialState: HandleAdventureState = {
    loading: false,
    error  : undefined
}
export const addAdventureReducer = (state = initialState, action: AddAdventureActionTypes): HandleAdventureState => {
    switch (action.type) {
        case ADD_ADVENTURE:
            return { loading: true, error: undefined }
        case ADD_ADVENTURE_FAILED:
            return { loading: false, error: action.payload }
        case ADD_ADVENTURE_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}