import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";

export const ADD_SPEED_QUIZ = 'ADD_SPEED_QUIZ'
export const ADD_SPEED_QUIZ_FAILED = 'ADD_SPEED_QUIZ_FAILED'
export const ADD_SPEED_QUIZ_SUCCEEDED = 'ADD_SPEED_QUIZ_SUCCEEDED'

export interface AddSpeedQuizAction {
    type: typeof ADD_SPEED_QUIZ
    payload: SpeedQuiz
}

export interface AddSpeedQuizFailedAction {
    type: typeof ADD_SPEED_QUIZ_FAILED
    payload: string
}

export interface AddSpeedQuizSucceedddAction {
    type: typeof ADD_SPEED_QUIZ_SUCCEEDED
}

export type AddSpeedQuizActionTypes = AddSpeedQuizAction | AddSpeedQuizFailedAction | AddSpeedQuizSucceedddAction