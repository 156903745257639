import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router";

interface Props {
    title: string;
    button: string;
    path: string;
}

export const PageTitleWithButton = (props: Props) => {

    const history = useHistory();

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item>
                <Typography component="h2" className={'page-title'} style={{
                    fontSize    : 20,
                    fontWeight  : '600',
                    color       : '#111827',
                    marginTop   : 24,
                    marginBottom: 24,
                }}>
                    {props.title}
                </Typography>
            </Grid>

            <Grid item>
                <Button variant="contained"
                        onClick={() => history.push(props.path)}
                        style={{
                            fontSize     : 13,
                            paddingTop   : 8,
                            paddingBottom: 8,
                            paddingLeft  : 20,
                            paddingRight : 20
                        }}>
                    {props.button}
                </Button>
            </Grid>
        </Grid>
    )
};
