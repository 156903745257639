import {
    GET_SPEED_QUIZ_PLAYER_STATS,
    GET_SPEED_QUIZ_PLAYER_STATS_FAILED, GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED,
    GetSpeedQuizPlayerStatsActionTypes
} from "./actionTypes";
import { GetAppStatsState } from "../../Config/State";

const initialState: GetAppStatsState = {
    loading: false,
    error: undefined,
    stats: null
}
export const getSpeedQuizPlayerStatsReducer = (state = initialState, action: GetSpeedQuizPlayerStatsActionTypes): GetAppStatsState => {
    switch (action.type){
        case GET_SPEED_QUIZ_PLAYER_STATS:
            return {loading: true, stats: null, error: undefined}
        case GET_SPEED_QUIZ_PLAYER_STATS_FAILED:
            return {loading: false, error: action.payload, stats: null}
        case GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED:
            return {loading: false, error: undefined, stats: action.payload}
        default:
            return state
    }
}