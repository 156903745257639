import { Employee } from "../../Domain/Entities/Employee";
import {
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_FAILED, UPDATE_EMPLOYEE_SUCCEEDED,
    UpdateEmployeeAction,
    UpdateEmployeeFailedAction, UpdateEmployeeSucceededAction
} from "./actionTypes";

export const updateEmployee = (employee: Employee): UpdateEmployeeAction =>({
    type: UPDATE_EMPLOYEE,
    payload: employee
})

export const updateEmployeeFailed = (error: string): UpdateEmployeeFailedAction =>({
    type: UPDATE_EMPLOYEE_FAILED,
    payload: error
})

export const updateEmployeeSucceeded = (): UpdateEmployeeSucceededAction =>({
    type: UPDATE_EMPLOYEE_SUCCEEDED
})