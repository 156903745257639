import { combineEpics, Epic } from 'redux-observable';
import { requestPasswordEpic } from '../Usecases/RequestPassword/epic';
import { loginUserEpic } from '../Usecases/Login/epic';
import { authenticateUserEpic } from '../Usecases/AuthenticateUser/epic';
import { logoutUserEpic } from "../Usecases/Logout/epic";

export const authenticationRootEpic: Epic = combineEpics(
    requestPasswordEpic,
    loginUserEpic,
    logoutUserEpic,
    authenticateUserEpic
)
