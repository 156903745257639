import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import {
    UPDATE_MCQ_QUESTION,
    UPDATE_MCQ_QUESTION_FAILED, UPDATE_MCQ_QUESTION_SUCCEEDED,
    UpdateMCQQuestionAction,
    UpdateMCQQuestionFailedAction, UpdateMCQQuestionSucceededAction
} from "./actionTypes";

export const updateMCQQuestion = (question: MCQQuestion, mcqId: string): UpdateMCQQuestionAction => ({
    type   : UPDATE_MCQ_QUESTION,
    payload: { question, mcqId }
})

export const updateMCQQuestionFailed = (error: string): UpdateMCQQuestionFailedAction => ({
    type   : UPDATE_MCQ_QUESTION_FAILED,
    payload: error
})

export const updateMCQQuestionSucceeded = (): UpdateMCQQuestionSucceededAction => ({
    type: UPDATE_MCQ_QUESTION_SUCCEEDED
})