import { ApplicationContext } from "../../../Configuration/Application.context";
const moment = ApplicationContext.getInstance().momentJs()

export class Message {
    constructor(
        protected _id: string,
        protected _title: string,
        protected _text: string,
        protected _startDate: string,
        protected _endDate: string,
        protected _isEnabled: boolean
    ) {}

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get text(): string {
        return this._text;
    }

    get startDate(): string {
        return this._startDate;
    }

    get endDate(): string {
        return this._endDate;
    }

    get isEnabled(): boolean {
        return this._isEnabled;
    }

    formatDate(): Message {
        return new Message(
            this._id,
            this._title,
            this._text,
            moment(this._startDate).format('DD MMM YYYY à HH:mm'),
            moment(this._endDate).format('DD MMM YYYY à HH:mm'),
            this._isEnabled
        )
    }
}
