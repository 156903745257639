import {
    GET_MESSAGE_STATS,
    GET_MESSAGE_STATS_FAILED,
    GET_MESSAGE_STATS_SUCCEEDED,
    GetMessageStatsActionTypes
} from "./actionTypes";
import { GetAppStatsState } from "../../Config/State";

const initialState: GetAppStatsState = {
    loading: false,
    error  : undefined,
    stats  : null
}
export const getMessageStatsReducer = (state = initialState, action: GetMessageStatsActionTypes): GetAppStatsState => {
    switch (action.type) {
        case GET_MESSAGE_STATS:
            return { loading: true, error: undefined, stats: null }
        case GET_MESSAGE_STATS_FAILED:
            return { loading: false, error: action.payload, stats: null }
        case GET_MESSAGE_STATS_SUCCEEDED:
            return { loading: false, error: undefined, stats: action.payload }
        default:
            return state
    }
}