import React, { PureComponent, ReactNode } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { i18n } from "../../../../Configuration/I18n";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    startDate: string;
    endDate: string;
    error: boolean;
    onChange: (startDate: string, endDate: string) => void;
    required?: boolean
    sx?: SxProps<Theme>
}

export class DateTimePicker extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Stack direction="row" sx={this.props.sx} spacing={2}>
                <TextField id="startDate"
                           required={this.props.required}
                           label={i18n.common.start_date}
                           type="datetime-local"
                           value={this.props.startDate}
                           sx={{ width: 250 }}
                           error={this.props.error}
                           InputLabelProps={{ shrink: true }}
                           onChange={(event) => this.props.onChange(event.target.value, this.props.endDate)}/>

                <TextField id="endDate"
                           label={i18n.common.end_date}
                           required={this.props.required}
                           type="datetime-local"
                           value={this.props.endDate}
                           sx={{ width: 250 }}
                           error={this.props.error}
                           InputLabelProps={{ shrink: true }}
                           onChange={(event) => this.props.onChange(this.props.startDate, event.target.value)}/>
            </Stack>
        )
    }
}
