import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import {
    requestPasswordErrorSelector,
    requestPasswordLoadingSelector,
    requestPasswordSuccessSelector
} from "../../../Usecases/RequestPassword/selector";
import { RequestPasswordActionTypes } from "../../../Usecases/RequestPassword/actionTypes";
import { requestPassword } from "../../../Usecases/RequestPassword/actions";
import { Ask4PasswordContainer } from "./Ask4Password.container";

interface StateToPropsType {
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
}

interface DispatchToPropsType {
    requestPassword: (email: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: requestPasswordLoadingSelector(state),
    success: requestPasswordSuccessSelector(state),
    error  : requestPasswordErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    requestPassword: (email: string): RequestPasswordActionTypes => dispatch(requestPassword(email))
})

export const Ask4Password = connect(mapStateToProps, mapDispatchToProps)(Ask4PasswordContainer)
