import { CartesianData } from "../Entity/CartesianData";

export class CartesianDataBuilder {
    protected _label: string
    protected _value: number

    withLabel(value: string): CartesianDataBuilder {
        this._label = value
        return this
    }

    withValue(value: number): CartesianDataBuilder {
        this._value = value
        return this
    }

    build(): CartesianData {
        return new CartesianData(
            this._label,
            this._value
        )
    }
}
