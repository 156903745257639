export const Theme = {
    red             : '#D20808',
    darkGreen       : '#35A318',
    softGreen       : '#87E14D',
    lightOrange     : '#FFB547',
    darkCyan        : '#0b815a',
    moderateCyan    : '#3fc79a',
    desaturatedGreen: '#7bc67e',
    verySoftBlue    : '#a1a8dd',
    softBlue        : '#64b6f7',
    grayishBlue     : '#455a64',
    brightBlue      : '#5048E5',
    darkBlue        : '#2F3EB1',
    moderateViolet  : '#8533c3',
    brightPink      : '#dd2c86',
}
