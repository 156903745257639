export const AuthenticationI18n = {
    empty_code_error    : 'Veuillez saisir votre mot de passe',
    code_error          : 'Veuillez vérifier votre identifiant',
    request_password_400: 'Aucun astronaut trouvé avec cet e-mail',
    request_password_406: 'Vous avez dépassée le limite de demande de mot de passe',
    invalid_email       : 'Veuillez saisir un email valide',
    sign_in_error_400   : 'Veuillez vérifier le format de l\'email',
    email_address       : 'Adresse email',
    password            : 'Mot de passe',
    ask_for_password    : 'Demander un mot de passe',
    sign_in             : 's\'authentifier'
}
