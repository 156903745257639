import React, { PureComponent } from "react";
import {
    Box, FormControl,
    Grid,
    InputAdornment, InputLabel,
    MenuItem, Select, SelectChangeEvent,
    TextField
} from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    searchValue: string
    status: string;
    onChange: (value: string) => void;
    filter: (value: string) => void;
}

export class MessageListToolbar extends PureComponent<Props> {

    render() {
        return (
            <Box component="form"
                 noValidate
                 autoComplete="off">

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={8}>
                        <TextField fullWidth
                                   placeholder={i18n.message.search}
                                   autoFocus={this.props.searchValue !== ''}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start"><SearchIcon/></InputAdornment>)
                                   }}
                                   value={this.props.searchValue}
                                   onChange={event => this.props.onChange(event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="status-select-label">{i18n.message.status}</InputLabel>

                            <Select id="status-select"
                                    labelId="status-select-label"
                                    value={this.props.status}
                                    label={i18n.message.status}
                                    onChange={(event: SelectChangeEvent) => this.props.filter(event.target.value)}>

                                <MenuItem value={'all'}>{i18n.message.all}</MenuItem>
                                <MenuItem value={'enabled'}>{i18n.message.enabled}</MenuItem>
                                <MenuItem value={'disabled'}>{i18n.message.disabled}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
