import {
    ADD_AGENCY,
    ADD_AGENCY_FAILED,
    ADD_AGENCY_SUCCEEDED,
    AddAgencyActionTypes
} from './actionTypes';
import { Agency } from "../../Domain/Entities/Agency";

export const addAgency = (Agency: Agency): AddAgencyActionTypes => ({
    type   : ADD_AGENCY,
    payload: Agency
})

export const addAgencyFailed = (error: string): AddAgencyActionTypes => ({
    type   : ADD_AGENCY_FAILED,
    payload: error
})

export const addAgencySucceeded = (): AddAgencyActionTypes => ({
    type: ADD_AGENCY_SUCCEEDED
})
