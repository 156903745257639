import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError,  map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { GET_SPEED_QUIZ, GetSpeedQuizAction } from "./actionTypes";
import { GetSpeedQuizState } from "../../Config/State";
import { getSpeedQuizFailed, getSpeedQuizSucceeded } from "./actions";
import { SpeedQuizService } from "../../Domain/Gateway/SpeedQuiz.service";
import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";

export const getSpeedQuizEpic: Epic = (action$: ActionsObservable<GetSpeedQuizAction>,
                                          store: StateObservable<GetSpeedQuizState>,
                                          { speedQuizService }: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(GET_SPEED_QUIZ),
        switchMap((action) => speedQuizService.getSpeedQuiz(action.payload)
            .pipe(
                map((data: SpeedQuiz) => getSpeedQuizSucceeded(data)
                ),
                catchError(error => of(getSpeedQuizFailed(error)))
            )
        )
    )