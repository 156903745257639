import {
    GET_MCQ_STATS,
    GET_MCQ_STATS_FAILED,
    GET_MCQ_STATS_SUCCEEDED,
    GetMcqStatsActionTypes,
    GetMcqStatsAction
} from './actionTypes';
import { SerieData } from "../../Domain/Entity/SerieData";

export const getMcqStats = (adventureId: string): GetMcqStatsAction => ({
    type   : GET_MCQ_STATS,
    payload: adventureId
})

export const getMcqStatsFailed = (error: string): GetMcqStatsActionTypes => ({
    type   : GET_MCQ_STATS_FAILED,
    payload: error
})

export const getMcqStatsSucceeded = (stats: SerieData[]): GetMcqStatsActionTypes => ({
    type   : GET_MCQ_STATS_SUCCEEDED,
    payload: stats
})
