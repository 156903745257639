import { Adventure } from "../../../Domain/Entity/Adventure";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../../Redux-configuration/AppState";

import { AddAdventureContainer } from "./AddAdventureContainer";
import { MCQ } from "../../../Domain/Entity/MCQ";
import { addAdventureErrorSelector, addAdventureLoadingSelector } from "../../../Usecases/Add/selectors";
import { AddAdventureAction } from "../../../Usecases/Add/actionTypes";
import { addAdventure } from "../../../Usecases/Add/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined
}

interface DispatchToPropsType {
    addAdventure: (adventure: Adventure, mcq: MCQ) => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: addAdventureLoadingSelector(state),
    error  : addAdventureErrorSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addAdventure: (adventure: Adventure, mcq: MCQ): AddAdventureAction => dispatch(addAdventure(adventure, mcq)),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AddAdventurePage = connect(mapStateToProps, mapDispatchToProps)(AddAdventureContainer)