import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { Ask4Password } from "./Ask4Password";
import { SignIn } from "./SignIn";
import './Login.css';
import { Grid, Container } from "@mui/material";

interface State {
    step: 1 | 2;
    email: string;
}

export class LoginContainer extends PureComponent<RouteComponentProps, State> {

    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            step : 1,
            email: ''
        }
    }

    render(): ReactNode {
        return (
            <Container component="main" maxWidth={false} className={'container'}>
                <Grid container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      className={'sub-container'}>

                    <Grid item xs={12} sm={6} lg={4}>
                        <img src={require('./../../../Assets/sonepar.png')} className={'img-fluid app-logo'}/>

                        {this.renderFormStep()}
                    </Grid>
                </Grid>
            </Container>
        )
    }

    renderFormStep(): JSX.Element {
        switch (this.state.step) {
            case 1:
                return <Ask4Password taskSucceeded={(email: string) => this.setState({ step: 2, email })}/>
            case 2:
                return <SignIn email={this.state.email}
                               taskSucceeded={() => window.location.href = '/statistics'}
                               onPressBack={() => this.setState({ step: 1 })}/>
            default:
                return <Ask4Password taskSucceeded={(email: string) => this.setState({ step: 2, email })}/>
        }
    }
}
