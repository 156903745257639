import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";

interface Props {
    value: string;
    disabled: boolean;
    onChange: (network: string) => void;
}

export const AgencyFormNetworkSelect = (props: Props) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="network-select-label">{i18n.agency.network}</InputLabel>

            <Select id="network-select"
                    labelId="network-select-label"
                    disabled={props.disabled}
                    value={props.value}
                    label={i18n.agency.network}
                    onChange={(event: SelectChangeEvent) => props.onChange(event.target.value)}>

                <MenuItem value={'CGED'}>{i18n.agency.cged}</MenuItem>
                <MenuItem value={'CONNECT'}>{i18n.agency.connect}</MenuItem>
            </Select>
        </FormControl>
    )
};
