import { Adventure } from "../../../Domain/Entity/Adventure";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { adventureListSelector, loadAdventureListLoadingSelector } from "../../../Usecases/List/selector";
import { LoadAdventureListActionTypes } from "../../../Usecases/List/actionTypes";
import { loadAdventureList } from "../../../Usecases/List/actions";
import { AdventureListContainer } from "./AdventureList.container";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    adventures: Adventure[] | null;
    loading: boolean;
}

interface DispatchToPropsType {
    getAdventureList: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    adventures: adventureListSelector(state),
    loading   : loadAdventureListLoadingSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    getAdventureList: (): LoadAdventureListActionTypes => dispatch(loadAdventureList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AdventureListPage = connect(mapStateToProps, mapDispatchToProps)(AdventureListContainer)