import { Employee } from "../../../Domain/Entities/Employee";
import { AppState } from "../../../../Redux-configuration/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Agency } from "../../../../Agence/Domain/Entities/Agency";
import { agencyListSelector } from "../../../../Agence/Usecases/GetAgencyList/selector";
import { GetAgencyListAction } from "../../../../Agence/Usecases/GetAgencyList/actionTypes";
import { getAgencyList } from "../../../../Agence/Usecases/GetAgencyList/actions";
import { updateEmployee } from "../../../Usecases/UpdateEmployee/actions";
import { UpdateEmployeeContainer } from "./UpdateEmployee.container";
import { UpdateEmployeeAction } from "../../../Usecases/UpdateEmployee/actionTypes";
import { employeeListSelector } from "../../../Usecases/GetEmployeeList/selectors";
import { getEmployeeList } from "../../../Usecases/GetEmployeeList/actions";
import { GetEmployeeListAction } from "../../../Usecases/GetEmployeeList/actionTypes";
import { updateEmployeeErrorSelector, updateEmployeeLoadingSelector } from "../../../Usecases/UpdateEmployee/selectors";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean
    error: string | undefined
    agencies: Agency[] | null;
    employees: Employee[] | null
}

interface DispatchToPropsType {
    updateEmployee: (employee: Employee) => void;
    getAgencyList: () => void;
    getEmployeeList: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading  : updateEmployeeLoadingSelector(state),
    error    : updateEmployeeErrorSelector(state),
    agencies : agencyListSelector(state),
    employees: employeeListSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    updateEmployee : (employee: Employee): UpdateEmployeeAction => dispatch(updateEmployee(employee)),
    getAgencyList  : (): GetAgencyListAction => dispatch(getAgencyList()),
    getEmployeeList: (): GetEmployeeListAction => dispatch(getEmployeeList()),
    setTopBarTitle : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))

})

export const UpdateEmployeePage = connect(mapStateToProps, mapDispatchToProps)(UpdateEmployeeContainer)