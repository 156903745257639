import React from "react";
import { Typography } from "@mui/material";

interface Props {
    text: string;
}

export const EmptyTableText = (props: Props) => {
    return (
        <Typography sx={{
            position: 'absolute',
            left    : '45%',
            top     : '40%',
            fontSize: 14,
            opacity : 0.6
        }}>{props.text}</Typography>
    )
}
