import {
    GET_BADGES_STATS,
    GET_BADGES_STATS_FAILED, GET_BADGES_STATS_SUCCEEDED,
    GetBadgesStatsAction,
    GetBadgesStatsFailedAction, GetBadgesStatsSucceededAction
} from "./actionTypes";
import { SerieData } from "../../Domain/Entity/SerieData";

export const getBadgesStats = (): GetBadgesStatsAction => ({
    type: GET_BADGES_STATS
})

export const getBadgesStatsFailed = (error: string): GetBadgesStatsFailedAction => ({
    type   : GET_BADGES_STATS_FAILED,
    payload: error
})

export const getBadgesStatsSucceeded = (data: SerieData[]): GetBadgesStatsSucceededAction => ({
    type   : GET_BADGES_STATS_SUCCEEDED,
    payload: data
})