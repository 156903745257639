import {
    GET_AGENCY_LIST,
    GET_AGENCY_LIST_FAILED,
    GET_AGENCY_LIST_SUCCEEDED,
    GetAgencyListActionTypes
} from "./actionTypes";
import { GetAgencyListState } from "../../Config/State";
const initialState: GetAgencyListState = {
    loading : false,
    error   : undefined,
    agencies: null
}
export const getAgencyListReducer = (state = initialState, action: GetAgencyListActionTypes): GetAgencyListState => {
    switch (action.type) {
        case GET_AGENCY_LIST:
            return { loading: true, agencies: null, error: undefined }
        case GET_AGENCY_LIST_FAILED:
            return { loading: false, error: action.payload, agencies: null }
        case GET_AGENCY_LIST_SUCCEEDED:
            return {
                loading : false,
                agencies: action.payload,
                error   : undefined
            }
        default:
            return state
    }
}