import {
    GET_BADGES_STATS,
    GET_BADGES_STATS_FAILED,
    GET_BADGES_STATS_SUCCEEDED,
    GetBadgesStatsActionTypes
} from "./actionTypes";
import { GetSerieStatsState } from "../../Config/State";

const initialState: GetSerieStatsState = {
    loading: false,
    error  : undefined,
    stats  : null
}
export const getBadgesStatsReducer = (state = initialState, action: GetBadgesStatsActionTypes): GetSerieStatsState => {
    switch (action.type) {
        case GET_BADGES_STATS:
            return { loading: true, error: undefined, stats: null }
        case GET_BADGES_STATS_FAILED:
            return { loading: false, error: action.payload, stats: null }
        case GET_BADGES_STATS_SUCCEEDED:
            return { loading: false, error: undefined, stats: action.payload }
        default:
            return state
    }
}