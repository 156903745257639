import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";
import {
    UPDATE_SPEED_QUIZ,
    UPDATE_SPEED_QUIZ_FAILED, UPDATE_SPEED_QUIZ_SUCCEEDED,
    UpdateSpeedQuizAction,
    UpdateSpeedQuizFailedAction, UpdateSpeedQuizSucceedddAction
} from "./actionTypes";

export const updateSpeedQuiz =(speedQuiz : SpeedQuiz): UpdateSpeedQuizAction =>({
    type: UPDATE_SPEED_QUIZ,
    payload: speedQuiz
})

export const updateSpeedQuizFailed = (error: string): UpdateSpeedQuizFailedAction =>({
    type: UPDATE_SPEED_QUIZ_FAILED,
    payload: error
})

export const updateSpeedQuizSucceeded = (): UpdateSpeedQuizSucceedddAction =>({
    type: UPDATE_SPEED_QUIZ_SUCCEEDED
})