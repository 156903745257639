import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { AddAgencyState } from "../../Config/State";
import { addAgencyFailed, addAgencySucceeded } from "./actions";
import { ADD_AGENCY, AddAgencyAction } from "./actionTypes";
import { AgencyService } from "../../Domain/Gateway/agencyService";
import { getAgencyList } from "../GetAgencyList/actions";

export const addAgencyEpic: Epic = (action$: ActionsObservable<AddAgencyAction>,
                                    store: StateObservable<AddAgencyState>,
                                    { agencyService }: { agencyService: AgencyService }) =>
    action$.pipe(
        ofType(ADD_AGENCY),
        switchMap((action) => agencyService.addAgency(action.payload)
            .pipe(
                concatMap(() => [
                    addAgencySucceeded(),
                    getAgencyList()
                ]),
                catchError(error => of(addAgencyFailed(error)))
            )
        )
    );
