export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED'
export const LOGOUT_USER_SUCCEEDED = 'LOGOUT_USER_SUCCEEDED'

export  interface LogoutUserAction {
    type: typeof LOGOUT_USER;
}

interface LogoutUserFailedAction {
    type: typeof LOGOUT_USER_FAILED;
    payload: string;
}

interface LogoutUserSucceededAction {
    type: typeof LOGOUT_USER_SUCCEEDED;
}

export type LogoutUserActionTypes = LogoutUserAction | LogoutUserFailedAction | LogoutUserSucceededAction
