import { combineEpics, Epic } from "redux-observable";
import { getAgencyListEpic } from "../Usecases/GetAgencyList/epic";
import { addAgencyEpic } from "../Usecases/Add/epic";
import { updateAgencyEpic } from "../Usecases/Update/epic";
import { getRegionListEpic } from "../Usecases/GetRegionList/epic";

export const agencyRootEpics : Epic = combineEpics(
    getAgencyListEpic,
    addAgencyEpic,
    updateAgencyEpic,
    getRegionListEpic
)
