import { SerieData } from "../../Domain/Entity/SerieData";

export const GET_BADGES_STATS = 'GET_BADGES_STATS'
export const GET_BADGES_STATS_FAILED = 'GET_BADGES_STATS_FAILED'
export const GET_BADGES_STATS_SUCCEEDED = 'GET_BADGES_STATS_SUCCEEDED'

export interface GetBadgesStatsAction {
    type: typeof GET_BADGES_STATS
}

export interface GetBadgesStatsFailedAction {
    type: typeof GET_BADGES_STATS_FAILED
    payload: string
}

export interface GetBadgesStatsSucceededAction {
    type: typeof GET_BADGES_STATS_SUCCEEDED
    payload: SerieData[]
}

export type GetBadgesStatsActionTypes =
    GetBadgesStatsAction
    | GetBadgesStatsFailedAction
    | GetBadgesStatsSucceededAction