import {
    GET_SPEED_QUIZ,
    GET_SPEED_QUIZ_FAILED, GET_SPEED_QUIZ_SUCCEEDED,
    GetSpeedQuizAction,
    GetSpeedQuizFailedAction,
    GetSpeedQuizSucceededAction
} from "./actionTypes";
import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";

export const getSpeedQuiz= (speedQuizId: string): GetSpeedQuizAction =>({
    type: GET_SPEED_QUIZ,
    payload: speedQuizId
})
export const getSpeedQuizFailed = (error: string): GetSpeedQuizFailedAction =>({
    type: GET_SPEED_QUIZ_FAILED,
    payload: error
})

export const getSpeedQuizSucceeded = (speedQuiz: SpeedQuiz): GetSpeedQuizSucceededAction =>({
    type: GET_SPEED_QUIZ_SUCCEEDED,
    payload: speedQuiz
})