import React, { Component, ReactNode } from "react";
import { Box, Divider, Grid, TextField } from '@mui/material';
import { i18n } from "../../../../../Configuration/I18n";
import { SpeedQuizQuestionContainer } from "./Question";
import { SpeedQuizQuestionType, SpeedQuizRoundType } from "../../../../Domain/SpeedQuiz.type";
import { SpeedQuizFormValidation } from "../FormValidation";

interface Props {
    round: SpeedQuizRoundType;
    error: boolean;
    onChange: (key: keyof SpeedQuizRoundType, value: string | number | SpeedQuizQuestionType[]) => void;
}

interface State {
    categoryError: boolean;
    dateError: boolean;
    question_1: SpeedQuizQuestionType
    question_2: SpeedQuizQuestionType
    question_3: SpeedQuizQuestionType
}

export class SpeedQuizRoundContainer extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            categoryError: false,
            dateError    : false,
            question_1   : {
                text     : '',
                delay    : 15,
                responses: []
            },
            question_2   : {
                text     : '',
                delay    : 15,
                responses: []
            },
            question_3   : {
                text     : '',
                delay    : 15,
                responses: []
            }
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.round.questions.length > 0 && state.question_1.text === '') {
            return {
                ...state,
                question_1: {
                    id       : props.round.questions[0].id,
                    text     : props.round.questions[0].text,
                    delay    : props.round.questions[0].delay,
                    responses: props.round.questions[0].responses
                },
                question_2: {
                    id       : props.round.questions[1].id,
                    text     : props.round.questions[1].text,
                    delay    : props.round.questions[1].delay,
                    responses: props.round.questions[1].responses
                },
                question_3: {
                    id       : props.round.questions[2].id,
                    text     : props.round.questions[2].text,
                    delay    : props.round.questions[2].delay,
                    responses: props.round.questions[2].responses
                }
            }
        }
        return state
    }

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error === true && this.props.error !== prevProps.error)
            this.setState({
                categoryError: !SpeedQuizFormValidation.validateTitle(this.props.round.category),
                dateError    : !SpeedQuizFormValidation.validateTitle(this.props.round.startDate)
            })
    }

    render(): ReactNode {
        return (
            <Box style={{backgroundColor: 'white', padding: 10, borderRadius: 5}}>
                <Grid container spacing={2} pt={1} mb={3} justifyContent="end">
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth
                                   required
                                   label={i18n.speedQuiz.category}
                                   value={this.props.round.category}
                                   error={this.state.categoryError}
                                   onChange={(event) => this.props.onChange('category', event.target.value)}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth
                                   required
                                   disabled={true}
                                   label={i18n.speedQuiz.start_date}
                                   type="datetime-local"
                                   value={this.props.round.startDate}
                                   error={this.state.dateError}
                                   InputLabelProps={{ shrink: true }}
                                   onChange={(event) => this.props.onChange('startDate', event.target.value)}/>
                    </Grid>
                </Grid>

                <Divider/>

                <SpeedQuizQuestionContainer title={i18n.speedQuiz.question_1}
                                            question={this.state.question_1}
                                            error={this.props.error}
                                            onChange={(question_1: SpeedQuizQuestionType) => {
                                                this.setState({ question_1 })
                                                this.props.onChange('questions', [question_1, this.state.question_2, this.state.question_3])
                                            }}/>

                <Divider/>

                <SpeedQuizQuestionContainer title={i18n.speedQuiz.question_2}
                                            question={this.state.question_2}
                                            error={this.props.error}
                                            onChange={(question_2: SpeedQuizQuestionType) => {
                                                this.setState({ question_2 })
                                                this.props.onChange('questions', [this.state.question_1, question_2, this.state.question_3])
                                            }}/>

                <Divider/>

                <SpeedQuizQuestionContainer title={i18n.speedQuiz.question_3}
                                            question={this.state.question_3}
                                            error={this.props.error}
                                            onChange={(question_3: SpeedQuizQuestionType) => {
                                                this.setState({ question_3 })
                                                this.props.onChange('questions', [this.state.question_1, this.state.question_2, question_3])
                                            }}/>
            </Box>
        )
    }
}
