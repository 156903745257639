import { Employee } from "../../Domain/Entities/Employee";
import {
    ADD_EMPLOYEE,
    ADD_EMPLOYEE_FAILED, ADD_EMPLOYEE_SUCCEEDED,
    AddEmployeeAction,
    AddEmployeeFailedAction,
    AddEmployeeSucceededAction
} from "./actionTypes";

export const addEmployee = (employee: Employee): AddEmployeeAction => ({
    type   : ADD_EMPLOYEE,
    payload: employee
})

export const addEmployeeFailed = (error: string): AddEmployeeFailedAction => ({
    type   : ADD_EMPLOYEE_FAILED,
    payload: error
})

export const addEmployeeSucceeded = (): AddEmployeeSucceededAction => ({
    type: ADD_EMPLOYEE_SUCCEEDED
})