import React, { PureComponent, ReactNode } from 'react'
import { Alert, Box, Button } from "@mui/material";
import { FormBlock } from "../../../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { i18n } from "../../../../../../Configuration/I18n";
import AddIcon from '@mui/icons-material/Add';
import { MCQQuestion } from "../../../../../Domain/Entity/MCQQuestion";
import { MCQQuestionForm } from "./MCQQuestionForm/MCQQuestion.form";
import { MCQQuestionList } from "./MCQQuestionList/MCQQuestionList";
import { AdventureFormValidation } from "../../AdventureFormValidation";

interface Props {
    questions: MCQQuestion[]
    onChange: (value: MCQQuestion[]) => void
    error: boolean
}

interface State {
    questions: MCQQuestion[]
    showForm: boolean
    errorMsg: string
    question: MCQQuestion | undefined
}

export class MCQQuestionsBlock extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            questions: [],
            showForm : false,
            errorMsg : '',
            question : undefined
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.questions !== this.props.questions && this.state.questions.length === 0)
            this.setState({ questions: this.props.questions })
        if (this.props.error === true)
            if (AdventureFormValidation.isNotValidQuestions(this.state.questions))
                this.setState({ errorMsg: i18n.adventure.error_questions })
    }

    render(): ReactNode {
        return (
            <Box>
                <FormBlock title={i18n.adventure.mcq_questions}>
                    <MCQQuestionList questions={this.state.questions}
                                     onEdit={questionIndex => this.editQuestion(questionIndex)}/>

                    <Button disabled={this.state.questions.length > 9} startIcon={<AddIcon/>} sx={{ mb: 2 }}
                            onClick={() => this.addNewQuestion()}
                            variant="contained">{i18n.adventure.add_question}</Button>

                    <MCQQuestionForm handleQuestion={(question) => this.handleQuestion(question)}
                                     question={this.state.question}
                                     show={this.state.showForm}
                                     onClose={() => this.setState({ showForm: false })}/>

                    {this.state.errorMsg !== '' ? <Alert severity="error">{this.state.errorMsg}</Alert> : null}
                </FormBlock>
            </Box>
        )
    }

    addNewQuestion() {
        this.setState({ showForm: !this.state.showForm, errorMsg: '' })
    }

    handleQuestion(question: MCQQuestion) {
        const questions: MCQQuestion[] = [...this.state.questions]
        if (this.state.question !== undefined) {
            const indexQuestion: number = questions.findIndex(item => item.id === this.state.question?.id)
            questions.splice(indexQuestion, 1, question)
            this.setState({ questions, errorMsg: '', question: undefined })
            this.props.onChange(questions)
        } else {
            questions.push(question)
            this.setState({ questions, errorMsg: '', question: undefined })
            this.props.onChange(questions)
        }
        if (questions.length === 10)
            this.setState({ showForm: false, errorMsg: '' })
    }

    editQuestion(index: number) {
        this.setState({ showForm: !this.state.showForm, question: this.state.questions[index] })
    }
}
