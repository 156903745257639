import { combineReducers } from 'redux';
import { AppState } from "./AppState";
import { authenticationRootReducer } from "../Authentication/Config/RootReducer";
import { profileRootReducer } from "../Profile/Config/Reducers";
import { statisticsRootReducer } from "../Statistics/Config/RootReducer";
import { speedQuizRootReducer } from "../SpeedQuiz/Config/RootReducer";
import { employeeRootReducer } from "../Employee/Config/RootReducers";
import { agencyRootReducers } from "../Agence/Config/RootReducers";
import { adventureRootReducer } from "../Adventure/Config/RootReducer";
import { messageRootReducer } from "../Message/Config/RootReducer";
import { setConfigurationAppReducer } from "../Common/Usecases/SetConfigurationApp/reducer";

export const reduxReducer = combineReducers<AppState>({
    authentication: authenticationRootReducer,
    profile       : profileRootReducer,
    statistics    : statisticsRootReducer,
    speedQuiz     : speedQuizRootReducer,
    employee      : employeeRootReducer,
    agency        : agencyRootReducers,
    adventure     : adventureRootReducer,
    message       : messageRootReducer,
    application   : setConfigurationAppReducer
})
