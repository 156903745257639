import { PureComponent, ReactNode } from "react";
import * as React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { i18n } from "../../../../../Configuration/I18n";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    speedQuizStats: SerieData[];
}

export class SpeedQuizTable extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{i18n.statistics.questions}</TableCell>
                                    <TableCell align="center">{i18n.statistics.correct_answers}</TableCell>
                                    <TableCell align="center">{i18n.statistics.bad_answers}</TableCell>
                                    <TableCell align="center">{i18n.statistics.unanswered}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.props.speedQuizStats.map(stat => (
                                    <TableRow key={stat.label}
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{stat.label}</TableCell>
                                        <TableCell align="center"
                                                   style={{ color: Theme.darkGreen }}>{stat.correctAnswers()}</TableCell>
                                        <TableCell align="center"
                                                   style={{ color: Theme.red }}>{stat.wrongAnswers()}</TableCell>
                                        <TableCell align="center"
                                                   style={{ color: Theme.lightOrange }}>{stat.unAnswered()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    }

}
