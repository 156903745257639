import { AppState } from "../../../../Redux-configuration/AppState";
import {
    loginUserErrorSelector,
    loginUserLoadingSelector,
    loginUserSuccessSelector
} from "../../../Usecases/Login/selector";
import { Dispatch } from "redux";
import { LoginUserAction } from "../../../Usecases/Login/actionTypes";
import { connect } from "react-redux";
import { loginUser } from "../../../Usecases/Login/actions";
import { SignInContainer } from "./SignIn.container";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    success: boolean | null
}

interface DispatchToPropsType {
    login: (email: string, code: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: loginUserLoadingSelector(state),
    error  : loginUserErrorSelector(state),
    success: loginUserSuccessSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    login: (email: string, code: string): LoginUserAction => dispatch(loginUser(email, code))
})

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInContainer)
