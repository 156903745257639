import {
    GET_EMPLOYEE_STATS,
    GET_EMPLOYEE_STATS_FAILED,
    GET_EMPLOYEE_STATS_SUCCEEDED,
    GetEmployeeStatsActionTypes
} from "./actionTypes";
import { GetEmployeeStatsState } from "../../Config/State";
const initialState: GetEmployeeStatsState = {
    loading: false,
    error: undefined,
    stats: null
}

export const getEmployeeStatsReducer = (state= initialState, action: GetEmployeeStatsActionTypes): GetEmployeeStatsState =>{
    switch (action.type){
        case GET_EMPLOYEE_STATS:
            return {loading: true, error: undefined, stats: null}
        case GET_EMPLOYEE_STATS_FAILED:
            return {loading: false, error: action.payload, stats: null}
        case GET_EMPLOYEE_STATS_SUCCEEDED:
            return {loading: false, error: undefined, stats: action.payload}
        default:
            return state
    }
}