import { GetAppStatsState } from "../../Config/State";
import {
    GET_SPEED_QUIZ_RESPONSE_STATS, GET_SPEED_QUIZ_RESPONSE_STATS_FAILED, GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED,
    GetSpeedQuizResponseStatsActionTypes
} from './actionTypes';

const initialState: GetAppStatsState = {
    loading: false,
    stats  : null,
    error  : undefined
}

export const getSpeedQuizResponseStatsReducer = (state = initialState, action: GetSpeedQuizResponseStatsActionTypes): GetAppStatsState => {
    switch (action.type) {
        case GET_SPEED_QUIZ_RESPONSE_STATS:
            return {
                loading: true,
                stats  : null,
                error  : undefined
            }
        case GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED:
            return {
                loading: false,
                stats  : action.payload,
                error  : undefined
            }
        case GET_SPEED_QUIZ_RESPONSE_STATS_FAILED:
            return {
                loading: false,
                stats  : null,
                error  : action.payload
            }
        default:
            return state
    }
}
