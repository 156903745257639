import React, { PureComponent } from "react";
import { TextField } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { FormBlock } from "../../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { SpeedQuizFormValidation } from "../FormValidation";

interface Props {
    title: string;
    startDate: string;
    error: string | undefined;
    onChange: (value: string) => void;
    onChangeDate: (value: string) => void
}

interface State {
    titleError: boolean;
    dateError: boolean;
}

export class SpeedQuizDetailsForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            titleError: false,
            dateError : false
        }
    }

    componentDidUpdate() {
        if (this.props.error)
            this.setState({
                titleError: !SpeedQuizFormValidation.validateTitle(this.props.title),
                dateError : !SpeedQuizFormValidation.validateTitle(this.props.startDate)
            })
    }

    render() {
        return (
            <FormBlock title={i18n.speedQuiz.speed_quiz}>
                <TextField fullWidth
                           required
                           sx={{ mb: 2 }}
                           error={this.state.titleError}
                           label={i18n.speedQuiz.title}
                           value={this.props.title}
                           onChange={(event) => this.props.onChange(event.target.value)}/>

                <TextField fullWidth
                           required
                           label={i18n.speedQuiz.start_date}
                           type="date"
                           InputProps={{ inputProps: { min: "2022-01-31", step: 7 } }}
                           value={this.props.startDate}
                           error={this.state.dateError}
                           InputLabelProps={{ shrink: true }}
                           onChange={(event) => this.props.onChangeDate(event.target.value)}/>
            </FormBlock>
        )
    }
}
