import { Employee } from "../../../Domain/Entities/Employee";
import { AppState } from "../../../../Redux-configuration/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { addEmployeeErrorSelector, addEmployeeLoadingSelector } from "../../../Usecases/AddEmployee/selectors";
import { addEmployee } from "../../../Usecases/AddEmployee/actions";
import { AddEmployeeContainer } from "./AddEmployee.container";
import { AddEmployeeAction } from "../../../Usecases/AddEmployee/actionTypes";
import { Agency } from "../../../../Agence/Domain/Entities/Agency";
import { agencyListSelector } from "../../../../Agence/Usecases/GetAgencyList/selector";
import { GetAgencyListAction } from "../../../../Agence/Usecases/GetAgencyList/actionTypes";
import { getAgencyList } from "../../../../Agence/Usecases/GetAgencyList/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean
    error: string | undefined
    agencies: Agency[] | null;
}

interface DispatchToPropsType {
    addEmployee: (employee: Employee) => void;
    getAgencyList: () => void;
    setTopBarTitle: (title: string) => void

}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading : addEmployeeLoadingSelector(state),
    error   : addEmployeeErrorSelector(state),
    agencies: agencyListSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addEmployee  : (employee: Employee): AddEmployeeAction => dispatch(addEmployee(employee)),
    getAgencyList: (): GetAgencyListAction => dispatch(getAgencyList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AddEmployeePage = connect(mapStateToProps, mapDispatchToProps)(AddEmployeeContainer)