import React, { PureComponent, ReactNode } from "react";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { Box, Drawer, List, Theme } from "@mui/material";
import { SideBarListItemButton } from "./components/ListItem.button";
import { NestedListItem } from "./components/NestedListItem";
import { i18n } from "../../../../Configuration/I18n";
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
type Props = WithStyles<typeof styles>;

interface State {
    currentPath: string;
}

class AppSidebar extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            currentPath: location.pathname
        }
    }

    render(): ReactNode {
        return (
            <Drawer className={'app-sidebar'}
                    variant="permanent"
                    classes={{ paper: this.props.classes.drawerPaper }}
                    anchor="left">

                <Box className={'logo'}>
                    <img src={require('../../../../Assets/sonepar.png')}
                         alt={'Reds\'Adventure'}
                         className={'img-fluid'}/>
                </Box>

                <List>
                    <NestedListItem title={i18n.common.statistics}
                                    icon={<BarChartIcon color="secondary"/>}
                                    isActive={this.isActive(['/adventure', '/speed-quiz', '/statistics'])}
                                    path={'/statistics'}>

                        <SideBarListItemButton title={i18n.common.generalStats}
                                               path={'/statistics'}
                                               onClick={() => this.setState({ currentPath: '/statistics' })}/>

                        <SideBarListItemButton title={i18n.common.mission}
                                               path={'/adventure'}
                                               onClick={() => this.setState({ currentPath: '/adventure' })}/>

                        <SideBarListItemButton title={i18n.common.speedQuiz}
                                               path={'/speed-quiz'}
                                               onClick={() => this.setState({ currentPath: '/speed-quiz' })}/>

                        <SideBarListItemButton title={i18n.common.message}
                                               path={'/message'}
                                               onClick={() => this.setState({ currentPath: '/message' })}/>

                    </NestedListItem>

                    <NestedListItem title={i18n.common.mission}
                                    icon={<AccountBalanceIcon color="secondary"/>}
                                    isActive={this.isActive(['/adventure-list', '/adventure-add'])}
                                    path={'/adventure-list'}/>

                    <NestedListItem title={i18n.common.speedQuiz}
                                    icon={<WidgetsIcon color="secondary"/>}
                                    isActive={this.isActive(['/speed-quiz-list'])}
                                    path={'/speed-quiz-list'}/>

                    <NestedListItem title={i18n.common.message}
                                    icon={<MessageOutlinedIcon color="secondary"/>}
                                    isActive={this.isActive(['/add-message', '/message-list'])}
                                    path={'/message-list'}/>

                    <NestedListItem title={i18n.common.employees}
                                    icon={<GroupIcon color="secondary"/>}
                                    isActive={this.isActive(['/employee-list', '/employee-add', '/employee-update'])}
                                    path={'/employee-list'}/>

                    <NestedListItem title={i18n.common.agency}
                                    icon={<MessageOutlinedIcon color="secondary"/>}
                                    isActive={this.isActive(['/add-agency', '/agency-list'])}
                                    path={'/agency-list'}/>
                </List>
            </Drawer>
        )
    }

    isActive(path: string[]): boolean {
        const active = path.find(pathName => location.pathname === pathName)
        return active !== undefined
    }
}

const drawerWidth = 280

const styles = (theme: Theme) => createStyles({
    drawer     : {
        width: drawerWidth
    },
    drawerPaper: {
        width          : drawerWidth,
        color          : '#ffffff',
        backgroundColor: '#000000'
    },
    title      : {
        padding: theme.spacing(2)
    }
})

export default withStyles(styles)(AppSidebar);
