import {
    GET_LOGIN_STATS,
    GET_LOGIN_STATS_FAILED,
    GET_LOGIN_STATS_SUCCEEDED,
    GetLoginStatsActionTypes
} from './actionTypes';
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getLoginStats = (startDate: string, endDate: string): GetLoginStatsActionTypes => ({
    type   : GET_LOGIN_STATS,
    payload: {
        startDate,
        endDate
    }
})

export const getLoginStatsFailed = (error: string): GetLoginStatsActionTypes => ({
    type   : GET_LOGIN_STATS_FAILED,
    payload: error
})

export const getLoginStatsSucceeded = (stats: CartesianData[]): GetLoginStatsActionTypes => ({
    type   : GET_LOGIN_STATS_SUCCEEDED,
    payload: stats
})
