import { AgencyService } from "../../../Domain/Gateway/agencyService";
import { Observable } from "rxjs";
import { Agency } from "../../../Domain/Entities/Agency";
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs/index";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { AgencyDto, RegionDto } from "./DTO/agencyDto";
import { AgencyMapper } from "./Mapper/agencyMapper";
import { Region } from "../../../Domain/Entities/Region";

export class ApiAgencyService implements AgencyService {

    getAgencyList(): Observable<Agency[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/agency'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: AgencyDto[] }>(url)
            .pipe(
                map((response: { data: AgencyDto[] }) => AgencyMapper.mapAgencyDtoToAgency(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addAgency(agency: Agency): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/agency'
        const body = {
            agency_code: agency.agencyCode,
            agency_name: agency.agencyName,
            region_code: agency.region.regionCode,
            network    : agency.network
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    updateAgency(agency: Agency): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/agency/update'
        const body = {
            agency_code: agency.agencyCode,
            agency_name: agency.agencyName,
            region_code: agency.region.regionCode,
            network    :  agency.network
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getRegionList(): Observable<Region[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/region'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: RegionDto[] }>(url)
            .pipe(
                map((response: { data: RegionDto[] }) => AgencyMapper.mapRegionDtoToRegion(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
