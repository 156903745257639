import React, { PureComponent, ReactNode } from 'react'
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import { i18n } from "../../../../../../../Configuration/I18n";
import { MCQQuestion } from "../../../../../../Domain/Entity/MCQQuestion";
import { MCQQuestionBuilder } from "../../../../../../Domain/Builder/MCQQuestion.builder";
import { MCQResponseFormType } from "../../../type/AdventureFormType";
import { MCQResponseBuilder } from "../../../../../../Domain/Builder/MCQResponse.builder";
import { MCQResponseField } from "./MCQResponse.field";
import { PictureInput } from "../../../../../../../Common/Adapters/Primaries/Components/Form/PictureInput";
import { ApplicationContext } from "../../../../../../../Configuration/Application.context";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    question?: MCQQuestion | undefined
    handleQuestion: (question: MCQQuestion) => void
    show: boolean
    onClose: () => void
}

interface State {
    question: string
    description: string
    picture: File | undefined
    pictureName: string
    errorQuestion: boolean
    response_1: MCQResponseFormType
    response_2: MCQResponseFormType
    response_3: MCQResponseFormType
    response_4: MCQResponseFormType
    errorMessage: string
}


export class MCQQuestionForm extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            question     : '',
            errorQuestion: false,
            picture      : undefined,
            pictureName  : '',
            description  : '',
            response_1   : { text: '', isCorrect: false },
            response_2   : { text: '', isCorrect: false },
            response_3   : { text: '', isCorrect: false },
            response_4   : { text: '', isCorrect: false },
            errorMessage : ''
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.question !== this.props.question && this.props.question)
            this.setState({
                question     : this.props.question.question,
                errorQuestion: false,
                description  : this.props.question.description,
                pictureName  : this.props.question.pictureName,
                response_1   : {
                    text     : this.props.question.responses[ 0 ].text,
                    isCorrect: this.props.question.responses[ 0 ].isCorrect
                },
                response_2   : {
                    text     : this.props.question.responses[ 1 ].text,
                    isCorrect: this.props.question.responses[ 1 ].isCorrect
                },
                response_3   : {
                    text     : this.props.question.responses[ 2 ].text,
                    isCorrect: this.props.question.responses[ 2 ].isCorrect
                },
                response_4   : {
                    text     : this.props.question.responses[ 3 ].text,
                    isCorrect: this.props.question.responses[ 3 ].isCorrect
                },
                errorMessage : ''
            })
    }

    render(): ReactNode {
        return (
            <Dialog open={this.props.show} onClose={() => this.props.onClose()} maxWidth={'md'} fullWidth={true}>
                <DialogTitle>{this.props.question ? i18n.adventure.update_question : i18n.adventure.add_question}</DialogTitle>
                <DialogContent>
                    <TextField fullWidth required
                               sx={{ mb: 2, mt: 2 }}
                               label={i18n.adventure.question}
                               error={this.state.errorQuestion}
                               value={this.state.question}
                               onChange={(event) => this.setState({
                                   question     : event.target.value,
                                   errorQuestion: false
                               })}/>
                    <TextField fullWidth
                               sx={{ mb: 2 }}
                               label={i18n.adventure.description}
                               value={this.state.description}
                               multiline rows={3}
                               onChange={(event) => this.setState({ description: event.target.value })}/>

                    {this.props.question && <PictureInput value={this.state.pictureName}
                                                         id={'question-picture'}
                                                         description={i18n.adventure.picture_details(900, 355)}
                                                         width={900}
                                                         height={355}
                                                         label={i18n.adventure.picture_mcq}
                                                         onChange={(picture) => this.setState({
                                                             picture    : picture,
                                                             pictureName: picture.name
                                                         })}/>}
                    <MCQResponseField response={this.state.response_1}
                                      label={i18n.adventure.response_1}
                                      error={this.state.errorMessage !== ''}
                                      onChange={(key, value): void => {
                                          this.setState({
                                              response_1  : { ...this.state.response_1, [ key ]: value },
                                              errorMessage: ''
                                          })
                                      }}/>
                    <MCQResponseField response={this.state.response_2}
                                      label={i18n.adventure.response_2}
                                      error={this.state.errorMessage !== ''}
                                      onChange={(key, value): void => this.setState({
                                          response_2  : { ...this.state.response_2, [ key ]: value },
                                          errorMessage: ''
                                      })}/>
                    <MCQResponseField response={this.state.response_3}
                                      label={i18n.adventure.response_3}
                                      error={this.state.errorMessage !== ''}
                                      onChange={(key, value): void =>
                                          this.setState({
                                              response_3  : { ...this.state.response_3, [ key ]: value },
                                              errorMessage: ''
                                          })}/>
                    <MCQResponseField response={this.state.response_4}
                                      label={i18n.adventure.response_4}
                                      error={this.state.errorMessage !== ''}
                                      onChange={(key, value): void =>
                                          this.setState({
                                              response_4  : { ...this.state.response_4, [ key ]: value },
                                              errorMessage: ''
                                          })}/>
                    {this.state.errorMessage !== '' ? <Alert severity="error">{this.state.errorMessage}</Alert> : null}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => this.props.onClose()}>
                        {i18n.adventure.cancel}
                    </Button>
                    {!this.props.question ? <Button variant="contained" onClick={() => this.saveQuestion()}>
                        {i18n.adventure.save_and_continue}
                    </Button> : null}
                    <Button variant="contained" onClick={() => this.saveQuestionAndClose()}>
                        {i18n.adventure.save}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    saveQuestionAndClose() {
        if (this.validateQuestionForm()) {
            if (this.props.question)
                this.updateQuestion()
            else
                this.addQuestion()
            if (!this.state.errorMessage && this.state.errorMessage === '')
                this.props.onClose()
        }

    }

    saveQuestion() {
        if (this.validateQuestionForm())
            if (this.props.question)
                this.updateQuestion()
            else
                this.addQuestion()
    }

    updateQuestion() {
        if (this.props.question) {
            this.props.handleQuestion(new MCQQuestionBuilder()
                .fromMCQQuestion(this.props.question)
                .withText(this.state.question)
                .withDescription(this.state.description)
                .withPicture(this.state.picture)
                .withPictureName(this.state.picture ? this.state.picture.name : '')
                .withResponses([
                    new MCQResponseBuilder()
                        .withId(this.props.question.responses[ 0 ].id).withText(this.state.response_1.text)
                        .withIsCorrect(this.state.response_1.isCorrect).build(),
                    new MCQResponseBuilder()
                        .withId(this.props.question.responses[ 1 ].id).withText(this.state.response_2.text)
                        .withIsCorrect(this.state.response_2.isCorrect).build(),
                    new MCQResponseBuilder()
                        .withId(this.props.question.responses[ 2 ].id).withText(this.state.response_3.text)
                        .withIsCorrect(this.state.response_3.isCorrect).build(),
                    new MCQResponseBuilder()
                        .withId(this.props.question.responses[ 3 ].id).withText(this.state.response_4.text)
                        .withIsCorrect(this.state.response_4.isCorrect).build(),
                ])
                .build())
            this.initForm()
        }
    }

    addQuestion() {
        this.props.handleQuestion(new MCQQuestionBuilder()
            .withId(moment().valueOf())
            .withText(this.state.question)
            .withDescription(this.state.description)
            .withResponses([
                new MCQResponseBuilder().withText(this.state.response_1.text).withIsCorrect(this.state.response_1.isCorrect).build(),
                new MCQResponseBuilder().withText(this.state.response_2.text).withIsCorrect(this.state.response_2.isCorrect).build(),
                new MCQResponseBuilder().withText(this.state.response_3.text).withIsCorrect(this.state.response_3.isCorrect).build(),
                new MCQResponseBuilder().withText(this.state.response_4.text).withIsCorrect(this.state.response_4.isCorrect).build(),
            ])
            .build())
        this.initForm()
    }

    validateQuestionForm(): boolean {
        if (this.state.question.length < 3)
            this.setState({ errorQuestion: true })
        if (!this.state.response_1.isCorrect && !this.state.response_2.isCorrect &&
            !this.state.response_3.isCorrect && !this.state.response_4.isCorrect)
            this.setState({ errorMessage: i18n.adventure.error_good_response })
        if (this.state.response_1.text === '' || this.state.response_2.text === '' ||
            this.state.response_4.text === '' || this.state.response_4.text === '')
            this.setState({ errorMessage: i18n.adventure.error_empty_response })
        return this.state.question.length > 3 && this.validResponses()
    }

    validResponses(): boolean {
        return (this.state.response_1.isCorrect || this.state.response_2.isCorrect ||
            this.state.response_3.isCorrect || this.state.response_4.isCorrect) && (this.state.response_1.text !== '' &&
            this.state.response_2.text !== '' && this.state.response_4.text !== '' && this.state.response_4.text !== '')
    }

    initForm() {
        this.setState({
            question     : '',
            errorQuestion: false,
            description  : '',
            picture      : undefined,
            pictureName  : '',
            response_1   : { text: '', isCorrect: false },
            response_2   : { text: '', isCorrect: false },
            response_3   : { text: '', isCorrect: false },
            response_4   : { text: '', isCorrect: false },
            errorMessage : ''
        })
    }
}