import { AdventureData } from "../Entity/AdventureData";
import { CartesianData } from "../Entity/CartesianData";
import { MultivariateData } from "../Entity/MultivariateData";

export class AdventureDataBuilder {

    protected _players: CartesianData[]
    protected _agencies: CartesianData[]
    protected _playersByRegion: MultivariateData[]
    protected _agenciesByRegion: MultivariateData[]
    protected _totalTurnover: number
    protected _totalTurnoverGoal: number
    protected _totalActivatedClient: number
    protected _totalActivatedClientGoal: number

    withTotalTurnover(value: number): AdventureDataBuilder {
        this._totalTurnover = value
        return this
    }

    withTotalTurnoverGoal(value: number): AdventureDataBuilder {
        this._totalTurnoverGoal = value
        return this
    }

    withTotalActivatedClient(value: number): AdventureDataBuilder {
        this._totalActivatedClient = value
        return this
    }

    withTotalActivatedClientGoal(value: number): AdventureDataBuilder {
        this._totalActivatedClientGoal = value
        return this
    }

    withPlayers(value: CartesianData[]): AdventureDataBuilder {
        this._players = value
        return this
    }

    withAgencies(value: CartesianData[]): AdventureDataBuilder {
        this._agencies = value
        return this
    }

    withPlayersByRegion(value: MultivariateData[]): AdventureDataBuilder {
        this._playersByRegion = value
        return this
    }

    withAgenciesByRegion(value: MultivariateData[]): AdventureDataBuilder {
        this._agenciesByRegion = value
        return this
    }

    build(): AdventureData {
        return new AdventureData(
            this._players,
            this._agencies,
            this._playersByRegion,
            this._agenciesByRegion,
            this._totalTurnover,
            this._totalTurnoverGoal,
            this._totalActivatedClient,
            this._totalActivatedClientGoal
        )
    }
}
