import React, { PureComponent, ReactNode } from 'react'
import { MCQTable } from "./MCQTable";
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { i18n } from "../../../../../Configuration/I18n";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";
import { StatisticsPieChart } from "../../Component/PieChart";
import { Grid } from "@mui/material";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    mcqStats: SerieData[] | null;
}

export class AdventureMCQStats extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <MCQTable mcqStats={this.props.mcqStats ? this.props.mcqStats : []}/>
                </Grid>

                <StatisticsPieChart size={4} style={{ height: '100%' }}
                                    colors={[Theme.darkGreen, Theme.red]}
                                    title={i18n.statistics.correct_answers_data}
                                    info={i18n.statistics.correct_answers_data}
                                    stats={this.getPieData()}/>
            </Grid>
        )
    }

    getPieData(): CartesianData[] {
        let correctAnswers = 0
        let wrongAnswers = 0

        this.props.mcqStats?.map((mcqStat: SerieData) => {
            const foundCorrectAnswer = mcqStat.series.find(data => data.label === 'VALID');
            const foundWrongAnswer = mcqStat.series.find(data => data.label === 'INVALID');
            if (foundCorrectAnswer)
                correctAnswers = correctAnswers + foundCorrectAnswer.value
            if (foundWrongAnswer)
                wrongAnswers = wrongAnswers + foundWrongAnswer.value
        })

        return [
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.correct_answers)
                .withValue(correctAnswers)
                .build(),
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.bad_answers)
                .withValue(wrongAnswers)
                .build()
        ]
    }
}
