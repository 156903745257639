import React, { PureComponent, ReactNode } from 'react'
import { MCQQuestion } from "../../../../Domain/Entity/MCQQuestion";
import { i18n } from "../../../../../Configuration/I18n";
import { Alert, Box, Button } from "@mui/material";
import { FormBlock } from "../../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { MCQQuestionList } from "../../AddAdventure/components/MCQQuestion/MCQQuestionList/MCQQuestionList";
import { MCQQuestionForm } from "../../AddAdventure/components/MCQQuestion/MCQQuestionForm/MCQQuestion.form";
import AddIcon from '@mui/icons-material/Add';
import { AdventureFormValidation } from "../../AddAdventure/AdventureFormValidation";

interface Props {
    questions: MCQQuestion[]
    onCreate: (question: MCQQuestion) => void
    onUpdate: (question: MCQQuestion) => void
    error: boolean
}

interface State {
    showForm: boolean
    errorMsg: string
    question: MCQQuestion | undefined
}

export class MCQQuestionsUpdateBlock extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            showForm: false,
            errorMsg: '',
            question: undefined
        }
    }

    componentDidUpdate() {
        if (this.props.error === true)
            if (AdventureFormValidation.isNotValidQuestions(this.props.questions))
                this.setState({ errorMsg: i18n.adventure.error_questions })
    }

    render(): ReactNode {
        return (
            <Box>
                <FormBlock title={i18n.adventure.mcq_questions}>
                    <MCQQuestionList questions={this.props.questions}
                                     onEdit={questionIndex => this.editQuestion(questionIndex)}/>

                    <Button disabled={this.props.questions.length > 9} startIcon={<AddIcon/>} sx={{ mb: 2 }}
                            onClick={() => this.addNewQuestion()}
                            variant="contained">{i18n.adventure.add_question}</Button>

                    <MCQQuestionForm handleQuestion={(question) => this.handleQuestion(question)}
                                     question={this.state.question}
                                     show={this.state.showForm}
                                     onClose={() => this.setState({ showForm: false })}/>

                    {this.state.errorMsg !== '' ? <Alert severity="error">{this.state.errorMsg}</Alert> : null}
                </FormBlock>
            </Box>
        )
    }

    addNewQuestion() {
        this.setState({ showForm: !this.state.showForm, errorMsg: '' })
    }

    editQuestion(questionIndex: number) {
        this.setState({
            showForm: !this.state.showForm,
            question: this.props.questions[questionIndex]
        })
    }

    handleQuestion(question: MCQQuestion) {
        if (this.state.question !== undefined) {
            this.props.onUpdate(question)
            this.setState({ question: undefined })
        }
        else {
            this.props.onCreate(question)
            this.setState({ question: undefined })

        }
        if (this.props.questions.length === 9)
            this.setState({ showForm: false, errorMsg: '' })
    }
}
