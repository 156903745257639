import React, { Component, ReactNode } from "react";
import { Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import { SpeedQuizRoundContainer } from "./Round";
import { SpeedQuizQuestionType, SpeedQuizRoundType } from "../../../../Domain/SpeedQuiz.type";
import './Accordion.css';

interface Props {
    title: string;
    error: string | undefined;
    round: SpeedQuizRoundType;
    onChange: (key: string, value: string | number | SpeedQuizQuestionType[] | boolean) => void;
}

export class SpeedQuizAccordion extends Component<Props> {

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    render(): ReactNode {
        return (
            <MuiAccordion className={'speed-quiz-accordion'}
                          disableGutters
                          expanded={this.props.round.expanded}
                          onChange={() => this.props.onChange('expanded', !this.props.round.expanded)}>

                <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }}/>}>

                    <Typography>{this.props.title}</Typography>

                    {this.props.round.category.length > 0 &&
                    <Typography component={'span'}>({this.props.round.category})</Typography>}

                </MuiAccordionSummary>

                <MuiAccordionDetails style={{ backgroundColor: '#F3F4F6' }}>
                    <SpeedQuizRoundContainer round={this.props.round}
                                             error={!!this.props.error}
                                             onChange={(key, value) => this.props.onChange(key, value)}/>
                </MuiAccordionDetails>
            </MuiAccordion>
        )
    }
}
