
export const DELETE_MCQ_QUESTION = 'DELETE_MCQ_QUESTION'
export const DELETE_MCQ_QUESTION_FAILED = 'DELETE_MCQ_QUESTION_FAILED'
export const DELETE_MCQ_QUESTION_SUCCEEDED = 'DELETE_MCQ_QUESTION_SUCCEEDED'

export interface DeleteMCQQuestionAction {
    type: typeof DELETE_MCQ_QUESTION,
    payload: { questionId: number, mcqId: string }
}

export interface DeleteMCQQuestionFailedAction {
    type: typeof DELETE_MCQ_QUESTION_FAILED
    payload: string
}

export interface DeleteMCQQuestionSucceededAction {
    type: typeof DELETE_MCQ_QUESTION_SUCCEEDED
}

export type DeleteMCQQuestionActionTypes =
    DeleteMCQQuestionAction
    | DeleteMCQQuestionFailedAction
    | DeleteMCQQuestionSucceededAction