import React, { PureComponent, ReactNode } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { i18n } from "../../../../../Configuration/I18n";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    mcqStats: SerieData[];
}

export class MCQTable extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.statistics.questions_mcq}</TableCell>
                            <TableCell align="center">{i18n.statistics.correct_answers}</TableCell>
                            <TableCell align="center">{i18n.statistics.bad_answers}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.props.mcqStats.map(stat => (
                            <TableRow key={stat.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{stat.label}</TableCell>
                                <TableCell align="center" style={{ color: Theme.darkGreen }}>{stat.correctAnswers()}</TableCell>
                                <TableCell align="center" style={{ color: Theme.red }}>{stat.wrongAnswers()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

}
