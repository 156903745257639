import React, { PureComponent, ReactNode } from "react";
import { Button, CircularProgress } from "@mui/material";

interface Props {
    title: string
    loading: boolean;
    onClick: () => void;
}

export class LoginSubmitButton extends PureComponent<Props> {

    render(): ReactNode {
        const loading = this.props.loading ?
            <CircularProgress color="secondary" size={18}/> : this.props.title

        return (
            <Button onClick={() => this.props.onClick()}
                    fullWidth
                    variant="contained"
                    sx={{
                        mt                     : 2,
                        "&.MuiButton-contained": {
                            color : "#efefef",
                            height: 50
                        }
                    }}
            >
                {loading}
            </Button>
        )
    }
}
