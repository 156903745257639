import { CartesianDataDTO } from "../DTO/CartesianData.DTO";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { ApplicationContext } from "../../../../../Configuration/Application.context";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";

const moment = ApplicationContext.getInstance().momentJs()

export class StatisticsDateHelper {

    static getPreviousDatesWithZeroAsValue(startDate: string, endDate: string): CartesianData[] {
        const start = moment(startDate, 'YYYY-MM-DD')
        const end = moment(endDate, 'YYYY-MM-DD')
        const duration = moment.duration(end.diff(start))
        const days = duration.asDays()

        if (days > 0)
            return this.getCartesianDataWithZeroAsValue(startDate, endDate)
        else
            return []
    }

    static addMissingDates (data: CartesianDataDTO[]): CartesianData[] {
        const stats: CartesianData[] = []
        let startDate = data[0].label
        let i = 0;
        while (i < data.length) {
            if (startDate === data[i].label) {
                stats.push(
                    new CartesianDataBuilder()
                        .withLabel(data[i].label)
                        .withValue(data[i].value)
                        .build()
                )
                i++
            }
            else
                stats.push(
                    new CartesianDataBuilder()
                        .withLabel(startDate)
                        .withValue(0)
                        .build()
                )

            startDate = this.getNextDay(startDate)
        }
        return stats
    }

    private static getCartesianDataWithZeroAsValue (startDate: string, endDate: string): CartesianData[] {
        const stats: CartesianData[] = []
        while (endDate !== startDate) {
            stats.push(
                new CartesianDataBuilder()
                    .withLabel(startDate)
                    .withValue(0)
                    .build()
            )
            startDate = this.getNextDay(startDate)
        }
        return stats
    }

    static getNextDay(date: string): string {
        return moment(date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
    }

}
