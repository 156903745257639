import { Adventure } from "../../../Domain/Entity/Adventure";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { adventureListSelector } from "../../../Usecases/List/selector";
import { LoadAdventureListActionTypes } from "../../../Usecases/List/actionTypes";
import { loadAdventureList } from "../../../Usecases/List/actions";
import { MCQ } from "../../../Domain/Entity/MCQ";
import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import {
    updateAdventureErrorSelector,
    updateAdventureLoadingSelector
} from "../../../Usecases/UpdateAdventure/selectors";
import { UpdateAdventureAction } from "../../../Usecases/UpdateAdventure/actionTypes";
import { updateAdventure } from "../../../Usecases/UpdateAdventure/actions";
import { UpdateMCQQuestionAction } from "../../../Usecases/UpdateMCQ/UpdateQuestion/actionTypes";
import { updateMCQQuestion } from "../../../Usecases/UpdateMCQ/UpdateQuestion/actions";
import { CreateMCQQuestionAction } from "../../../Usecases/UpdateMCQ/CreateQuestion/actionTypes";
import { createMCQQuestion } from "../../../Usecases/UpdateMCQ/CreateQuestion/actions";
import { adventureMCQSelector } from "../../../Usecases/LoadMCQ/selectors";
import { LoadAdventureMCQAction } from "../../../Usecases/LoadMCQ/actionTypes";
import { loadAdventureMCQ } from "../../../Usecases/LoadMCQ/actions";
import { UpdateAdventureContainer } from "./UpdateAdventure.container";
import { updateMCQQuestionErrorSelector } from "../../../Usecases/UpdateMCQ/UpdateQuestion/selectors";
import { createMCQQuestionErrorSelector } from "../../../Usecases/UpdateMCQ/CreateQuestion/selectors";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    adventures: Adventure[] | null;
    loading: boolean;
    MCQ: MCQ | null
    error: string | undefined
}

interface DispatchToPropsType {
    getAdventureList: () => void;
    loadAdventureMCQ: (adventureId: string) => void
    updateAdventure: (adventure: Adventure) => void
    updateQuestion: (question: MCQQuestion, mcqId: string) => void
    createQuestion: (question: MCQQuestion, mcqId: string) => void
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    adventures: adventureListSelector(state),
    loading   : updateAdventureLoadingSelector(state),
    MCQ       : adventureMCQSelector(state),
    error     : updateAdventureErrorSelector(state) || updateMCQQuestionErrorSelector(state) || createMCQQuestionErrorSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    getAdventureList: (): LoadAdventureListActionTypes => dispatch(loadAdventureList()),
    loadAdventureMCQ: (adventureId: string): LoadAdventureMCQAction => dispatch(loadAdventureMCQ(adventureId)),
    updateAdventure : (adventure: Adventure): UpdateAdventureAction => dispatch(updateAdventure(adventure)),
    updateQuestion  : (question: MCQQuestion, mcqId: string): UpdateMCQQuestionAction => dispatch(updateMCQQuestion(question, mcqId)),
    createQuestion  : (question: MCQQuestion, mcqId: string): CreateMCQQuestionAction => dispatch(createMCQQuestion(question, mcqId)),
    setTopBarTitle  : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const UpdateAdventurePage = connect(mapStateToProps, mapDispatchToProps)(UpdateAdventureContainer)