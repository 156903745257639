import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import {
    CREATE_MCQ_QUESTION,
    CREATE_MCQ_QUESTION_FAILED,
    CREATE_MCQ_QUESTION_SUCCEEDED,
    CreateMCQQuestionAction, CreateMCQQuestionFailedAction, CreateMCQQuestionSucceededAction
} from "./actionTypes";

export const createMCQQuestion = (question: MCQQuestion, mcqId: string): CreateMCQQuestionAction =>({
    type: CREATE_MCQ_QUESTION,
    payload: {question, mcqId}
})


export const createMCQQuestionFailed = (error: string): CreateMCQQuestionFailedAction => ({
    type   : CREATE_MCQ_QUESTION_FAILED,
    payload: error
})

export const createMCQQuestionSucceeded = (): CreateMCQQuestionSucceededAction => ({
    type: CREATE_MCQ_QUESTION_SUCCEEDED
})