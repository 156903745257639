import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { Box, Grid } from "@mui/material";
import { StatisticsCard } from "../../Component/Card";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { PercentageCard } from "../../Component/PercentageCard";
import { SpeedQuizAreaChart } from "./AreaChart";

interface Props {
    usersCount: number | undefined;
    playerStats: CartesianData[] | null;
    speedQuizPlayerCount: number | undefined;
}

export class SpeedQuizPlayerStats extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Box>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <PercentageCard title={i18n.statistics.players_rate}
                                        numerator={this.props.speedQuizPlayerCount}
                                        denominator={this.props.usersCount}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                       <StatisticsCard title={i18n.statistics.subscription}
                                        value={this.props.speedQuizPlayerCount?.toString()}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.users}
                                        value={this.props.usersCount?.toString()}/>
                    </Grid>
                </Grid>

                <SpeedQuizAreaChart stats={this.props.playerStats}
                                    users={this.props.usersCount}/>
            </Box>
        )
    }
}