import {
    UPDATE_ADVENTURE,
    UPDATE_ADVENTURE_FAILED,
    UPDATE_ADVENTURE_SUCCEEDED,
    UpdateAdventureActionTypes
} from "./actionTypes";
import { HandleAdventureState } from "../../Config/State";

const initialState: HandleAdventureState = {
    loading: false,
    error  : undefined
}
export const updateAdventureReducer = (state = initialState, action: UpdateAdventureActionTypes): HandleAdventureState => {
    switch (action.type) {
        case UPDATE_ADVENTURE:
            return { loading: true, error: undefined }
        case UPDATE_ADVENTURE_FAILED:
            return { loading: false, error: action.payload }
        case UPDATE_ADVENTURE_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}