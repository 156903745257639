import React, { PureComponent, ReactNode } from 'react'
import { Button, Grid } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    label: string
}

export class BadResponse extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid item>
                <Button variant="outlined" disableElevation
                        disableRipple sx={{
                    borderRadius                : 5,
                    textTransform               : 'none',
                    ml                          : 1,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor    : "transparent",
                        borderWidth: 1
                    }
                }} startIcon={<ClearIcon/>} size="small">
                    {this.props.label}
                </Button></Grid>
        )
    }
}