import React from 'react';
import { i18n } from "../../../../Configuration/I18n";
import { Typography } from '@mui/material';

export const DisabledTag = () => {
    return (
        <Typography component="span" style={{
            fontSize    : 13,
            color       : 'red',
            borderRadius: 15,
            padding     : '2px 8px',
            border      : '1px solid red'
        }}>
            {i18n.common.disabled}
        </Typography>
    )
}
