import React, { PureComponent, ReactNode } from "react";
import { Box, CircularProgress } from '@mui/material';
import { i18n } from "../../../../Configuration/I18n";
import { PageTitleWithButton } from "../../../../Common/Adapters/Primaries/Components/PageTitleWithButton";
import { SpeedQuizHeader } from "../../../Domain/Entities/SpeedQuizHeader";
import { SpeedQuizListTable } from "./Components/Table";

interface Props {
    loading: boolean
    speedQuizList: SpeedQuizHeader[] | null;
    loadSpeedQuizList: () => void;
    setTopBarTitle: (title: string) => void
}

export class SpeedQuizListContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.loadSpeedQuizList()
        this.props.setTopBarTitle(i18n.speedQuiz.speed_quiz)
    }

    render(): ReactNode {
        return (
            <Box>
                <PageTitleWithButton title={i18n.speedQuiz.speed_quiz_list}
                                     button={'+ ' + i18n.speedQuiz.add_speed_quiz}
                                     path={'/add-speed-quiz'}/>


                {this.props.loading &&
                <Box className={'page-loading'}>
                    <CircularProgress/>
                </Box>
                }

                {this.props.speedQuizList && <SpeedQuizListTable speedQuizList={this.props.speedQuizList}/>}
            </Box>
        )
    }
}
