import { SpeedQuizHeadDto } from "../DTO/speedQuizHead.dto";
import { SpeedQuizHeader } from "../../../../Domain/Entities/SpeedQuizHeader";
import { ApplicationContext } from "../../../../../Configuration/Application.context";
import { SpeedQuizDto } from "../DTO/SpeedQuizDto";
import { SpeedQuiz } from "../../../../Domain/Entities/SpeedQuiz";
import { SpeedQuizBuilder } from "../../../../Domain/Builder/SpeedQuiz.builder";
import { SpeedQuizRoundBuilder } from "../../../../Domain/Builder/SpeedQuizRound.builder";
import { SpeedQuizRound } from "../../../../Domain/Entities/SpeedQuizRound";
import { SpeedQuizQuestionBuilder } from "../../../../Domain/Builder/SpeedQuizQuestion.builder";
import { SpeedQuizAnswerBuilder } from "../../../../Domain/Builder/SpeedQuizAnswer.builder";

const moment = ApplicationContext.getInstance().momentJs()

export class SpeedQuizMapper {
    static mapDataToSpeedQuizHeader(data: SpeedQuizHeadDto[]): SpeedQuizHeader[] {
        return data.map(speedQuiz => {
            if (speedQuiz.startDate && speedQuiz.endDate) {
                const now = moment()
                const startDate = moment(speedQuiz.startDate)
                const endDate = moment(speedQuiz.endDate)
                const durationStart = moment.duration(startDate.diff(now));
                const durationEnd = moment.duration(endDate.diff(now));
                if (durationStart > 0)
                    return new SpeedQuizHeader(speedQuiz.id, speedQuiz.label, 'new')
                else if (durationEnd > 0)
                    return new SpeedQuizHeader(speedQuiz.id, speedQuiz.label, 'in_progress')
                else
                    return new SpeedQuizHeader(speedQuiz.id, speedQuiz.label, 'finished')
            }
            else
                return new SpeedQuizHeader(speedQuiz.id, speedQuiz.label, 'new')
        })
    }

    static mapDataToSpeedQuiz(speedQuizDto: SpeedQuizDto): SpeedQuiz {
        const rounds: SpeedQuizRound[] = speedQuizDto.rounds.map(round =>{
            const questions = round.questions.map(question =>{
                const responses = question.responses.map(respone =>
                    new SpeedQuizAnswerBuilder()
                        .withId(Number(respone.id))
                        .withText(respone.text)
                        .withIsCorrect(respone.correct)
                        .build()
                )
                return new SpeedQuizQuestionBuilder()
                    .withId(Number(question.id))
                    .withDelay(15)
                    .withText(question.text)
                    .withAnswers(responses)
                    .build()
            })
            return new SpeedQuizRoundBuilder()
                .withId(round.id)
                .withCategory(round.category)
                .withStep(round.step)
                .withStartDate(moment(round.startDateTime))
                .withQuestions(questions)
                .build()
        })
        return new SpeedQuizBuilder().withId(speedQuizDto.id)
            .withLabel(speedQuizDto.label)
            .withEndDate(moment(speedQuizDto.endDate))
            .withRounds(rounds)
            .build()
    }
}
