import React, { ReactNode } from "react";
import { PureComponent } from "react";
import { i18n } from "../../../../Configuration/I18n";

export class PageNotFoundError extends PureComponent {

    render(): ReactNode {
        return (
            <h1>{i18n.navigation.not_found_error}</h1>
        )
    }
}
