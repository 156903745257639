export const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE';
export const RETRIEVE_PROFILE_FAILED = 'RETRIEVE_PROFILE_FAILED';
export const RETRIEVE_PROFILE_SUCCEEDED = 'RETRIEVE_PROFILE_SUCCEEDED';


export interface RetrieveProfileAction {
    type: typeof RETRIEVE_PROFILE;
}

interface RetrieveProfileFailedAction {
    type: typeof RETRIEVE_PROFILE_FAILED;
    payload: string;
}

interface RetrieveProfileSucceededAction {
    type: typeof RETRIEVE_PROFILE_SUCCEEDED;
}

export type RetrieveProfileActionsTypes = RetrieveProfileAction | RetrieveProfileFailedAction | RetrieveProfileSucceededAction