import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ADD_SPEED_QUIZ, AddSpeedQuizAction } from "./actionTypes";
import { HandleSpeedQuizState } from "../../Config/State";
import { addSpeedQuizFailed, addSpeedQuizSucceeded } from "./actions";
import { SpeedQuizService } from "../../Domain/Gateway/SpeedQuiz.service";

export const addSpeedQuizEpic: Epic = (action$: ActionsObservable<AddSpeedQuizAction>,
                                          store: StateObservable<HandleSpeedQuizState>,
                                          { speedQuizService }: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(ADD_SPEED_QUIZ),
        switchMap((action) => speedQuizService.addSpeedQuiz(action.payload)
            .pipe(
                map(() => addSpeedQuizSucceeded()
                ),
                catchError(error => of(addSpeedQuizFailed(error)))
            )
        )
    )