import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";

export const UPDATE_SPEED_QUIZ='UPDATE_SPEED_QUIZ'
export const UPDATE_SPEED_QUIZ_FAILED='UPDATE_SPEED_QUIZ_FAILED'
export const UPDATE_SPEED_QUIZ_SUCCEEDED='UPDATE_SPEED_QUIZ_SUCCEEDED'

export interface UpdateSpeedQuizAction {
    type: typeof UPDATE_SPEED_QUIZ
    payload: SpeedQuiz
}

export interface UpdateSpeedQuizFailedAction {
    type: typeof UPDATE_SPEED_QUIZ_FAILED
    payload: string
}

export interface UpdateSpeedQuizSucceedddAction {
    type: typeof UPDATE_SPEED_QUIZ_SUCCEEDED
}

export type UpdateSpeedQuizActionTypes = UpdateSpeedQuizAction | UpdateSpeedQuizFailedAction | UpdateSpeedQuizSucceedddAction