import { Message } from "../../Domain/Entity/Message";

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const UPDATE_MESSAGE_FAILED = 'UPDATE_MESSAGE_FAILED'
export const UPDATE_MESSAGE_SUCCEEDED = 'UPDATE_MESSAGE_SUCCEEDED'

export interface UpdateMessageAction {
    type: typeof UPDATE_MESSAGE;
    payload: Message;
}

interface UpdateMessageFailedAction {
    type: typeof UPDATE_MESSAGE_FAILED;
    payload: string;
}

interface UpdateMessageSucceededAction {
    type: typeof UPDATE_MESSAGE_SUCCEEDED;
}

export type UpdateMessageActionTypes = UpdateMessageAction | UpdateMessageFailedAction | UpdateMessageSucceededAction
