import React, { PureComponent } from "react";
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Message } from "../../../../Domain/Entity/Message";
import { i18n } from "../../../../../Configuration/I18n";
import { EnabledTag } from "../../../../../Common/Adapters/Primaries/Components/EnabledTag";
import { DisabledTag } from "../../../../../Common/Adapters/Primaries/Components/DisabledTag";
import { EditButton } from "../../../../../Common/Adapters/Primaries/Components/EditButton";
import { EmptyTableText } from "../../../../../Common/Adapters/Primaries/Components/EmptyTableText";
import { MessageListToolbar } from "./Toolbar";
import './Table.css';

interface Props {
    messages: Message[];
}

interface State {
    searchValue: string
    status: string;
}

export class MessageListTable extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            searchValue: '',
            status     : 'all'
        }
    }

    render() {
        const columns = [
            { field: 'title', headerName: i18n.message.title, flex: 2 },
            { field: 'startDate', headerName: i18n.message.start_date, flex: 2 },
            { field: 'endDate', headerName: i18n.message.end_date, flex: 2 },
            {
                field     : 'isEnabled', headerName: i18n.message.status, flex: 1,
                renderCell: (params: GridCellParams) => params.value ? <EnabledTag/> : <DisabledTag/>
            },
            {
                field     : 'id', headerName: i18n.message.actions, flex: 1, sortable: false,
                renderCell: (params: GridCellParams) => <EditButton path={'/update-message/' + params.value}/>
            }
        ]

        return (

            <DataGrid className={'message-table'}
                      rows={this.filterMessages(this.props.messages)}
                      columns={columns}
                      autoHeight={true}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick
                      disableColumnSelector
                      disableColumnFilter
                      sx={{
                          '& .MuiDataGrid-cell:focus': {
                              outline: 0
                          }
                      }}
                      disableColumnMenu
                      components={{
                          NoRowsOverlay: () => <EmptyTableText text={i18n.message.no_messages}/>,
                          Toolbar      : () => <MessageListToolbar searchValue={this.state.searchValue}
                                                                   status={this.state.status}
                                                                   onChange={(value: string) => this.setState({ searchValue: value })}
                                                                   filter={(value: string) => this.setState({ status: value })}/>
                      }}
            />
        )
    }

    filterMessages(messages: Message[]): Message[] {
        return messages
            .filter(message => message.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1)
            .filter(message => this.state.status === 'enabled' ? message.isEnabled :
                this.state.status === 'disabled' ? !message.isEnabled : message)
    }

}
