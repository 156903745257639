import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_PAGE_STATS = 'GET_PAGE_STATS'
export const GET_PAGE_STATS_FAILED = 'GET_PAGE_STATS_FAILED'
export const GET_PAGE_STATS_SUCCEEDED = 'GET_PAGE_STATS_SUCCEEDED'

export interface GetPageStatsAction {
    type: typeof GET_PAGE_STATS
}

export interface GetPageStatsFailedAction {
    type: typeof GET_PAGE_STATS_FAILED
    payload: string
}

export interface GetPageStatsSucceededAction {
    type: typeof GET_PAGE_STATS_SUCCEEDED
    payload: CartesianData[]
}

export type GetPageStatsActionTypes = GetPageStatsAction | GetPageStatsFailedAction | GetPageStatsSucceededAction