export const GET_SPEED_QUIZ_PLAYER_COUNT = 'GET_SPEED_QUIZ_PLAYER_COUNT'
export const GET_SPEED_QUIZ_PLAYER_COUNT_FAILED = 'GET_SPEED_QUIZ_PLAYER_COUNT_FAILED'
export const GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED = 'GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED'

export interface GetSpeedQuizPlayerCountAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_COUNT
    payload: string
}

export interface GetSpeedQuizPlayerCountFailedAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_COUNT_FAILED
    payload: string
}

export interface GetSpeedQuizPlayerCountSucceededAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED
    payload: number
}

export type GetSpeedQuizPlayerCountActionTypes =
    GetSpeedQuizPlayerCountAction
    | GetSpeedQuizPlayerCountFailedAction
    | GetSpeedQuizPlayerCountSucceededAction