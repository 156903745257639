import {
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCEEDED,
    LoginUserAction,
    LoginUserActionTypes
} from './actionTypes';

export const loginUser = (email: string, password: string): LoginUserAction => ({
    type   : LOGIN_USER,
    payload: { email, password }
})

export const loginUserFailed = (error: string): LoginUserActionTypes => ({
    type   : LOGIN_USER_FAILED,
    payload: error
})

export const loginUserSucceeded = (): LoginUserActionTypes => ({
    type: LOGIN_USER_SUCCEEDED
})
