import {
    UPDATE_SPEED_QUIZ,
    UPDATE_SPEED_QUIZ_FAILED,
    UPDATE_SPEED_QUIZ_SUCCEEDED,
    UpdateSpeedQuizActionTypes
} from "./actionTypes";
import { HandleSpeedQuizState } from "../../Config/State";

const initialState: HandleSpeedQuizState = {
    loading: false,
    error  : undefined
}
export const updateSpeedQuizReducer = (state = initialState, action: UpdateSpeedQuizActionTypes): HandleSpeedQuizState => {
    switch (action.type) {
        case UPDATE_SPEED_QUIZ:
            return { loading: true, error: undefined }
        case UPDATE_SPEED_QUIZ_FAILED:
            return { loading: false, error: action.payload }
        case UPDATE_SPEED_QUIZ_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}