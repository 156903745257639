import { Agency } from "../../Domain/Entities/Agency";
import {
    UPDATE_AGENCY,
    UPDATE_AGENCY_FAILED, UPDATE_AGENCY_SUCCEEDED,
    UpdateAgencyAction,
    UpdateAgencyFailedAction,
    UpdateAgencySucceededAction
} from "./actionTypes";

export const updateAgency = (agency: Agency): UpdateAgencyAction => ({
    type   : UPDATE_AGENCY,
    payload: agency
})

export const updateAgencyFailed = (error: string): UpdateAgencyFailedAction => ({
    type   : UPDATE_AGENCY_FAILED,
    payload: error
})

export const updateAgencySucceeded = (): UpdateAgencySucceededAction => ({
    type: UPDATE_AGENCY_SUCCEEDED
})