import React, { PureComponent, ReactNode } from 'react'
import { Box, TextField } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { FormBlock } from "../../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { PictureInput } from "../../../../../Common/Adapters/Primaries/Components/Form/PictureInput";
import { MCQSettingsFormType } from "../type/AdventureFormType";
import { AdventureFormValidation } from "../AdventureFormValidation";

interface Props {
    MCQSettings: MCQSettingsFormType
    onChange: (key: keyof MCQSettingsFormType, value: string | number | File) => void
    error: boolean
}

interface State {
    errorTitle: boolean
    errorReward: boolean
}

export class MCQSettingsBlock extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            errorTitle      : false,
            errorReward     : false
        }
    }

    componentDidUpdate() {
        if (this.props.error === true) {
            this.setState({
                errorTitle      : AdventureFormValidation.isNotValidName(this.props.MCQSettings.title),
                errorReward     : AdventureFormValidation.isNotValidReward(this.props.MCQSettings.reward)
               })
        }
    }


    render(): ReactNode {
        return (
            <Box>
                <FormBlock title={i18n.adventure.mcq_settings}>
                    <TextField fullWidth required
                               sx={{ mb: 2 }}
                               label={i18n.adventure.title}
                               value={this.props.MCQSettings.title}
                               error={this.state.errorTitle}
                               onChange={(event) => this.props.onChange('title', event.target.value)}/>
                    <TextField fullWidth required
                               sx={{ mb: 2 }}
                               label={i18n.adventure.reward_bonus}
                               value={this.props.MCQSettings.reward}
                               error={this.state.errorReward}
                               onChange={(event) => this.props.onChange('reward', Number(event.target.value))}/>
                    <PictureInput value={this.props.MCQSettings.picture?.name}
                                  id={'mcq-picture'}
                                  description={i18n.adventure.picture_details(900, 355)}
                                  width={900}
                                  height={355}
                                  label={i18n.adventure.picture_mcq}
                                  onChange={(picture) => this.props.onChange('picture', picture)}
                    />
                </FormBlock>
            </Box>
        )
    }
}