import { Adventure } from "../../Domain/Entity/Adventure";
import {
    ADD_ADVENTURE,
    ADD_ADVENTURE_FAILED, ADD_ADVENTURE_SUCCEEDED,
    AddAdventureAction,
    AddAdventureFailedAction,
    AddAdventureSucceededAction
} from "./actionTypes";
import { MCQ } from "../../Domain/Entity/MCQ";

export const addAdventure = (adventure: Adventure, mcq: MCQ): AddAdventureAction => ({
    type   : ADD_ADVENTURE,
    payload: {adventure, mcq}
})

export const addAdventureFailed = (error: string): AddAdventureFailedAction => ({
    type   : ADD_ADVENTURE_FAILED,
    payload: error
})

export const addAdventureSucceeded = (): AddAdventureSucceededAction => ({
    type: ADD_ADVENTURE_SUCCEEDED
})