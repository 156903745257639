import { SpeedQuizQuestion } from "../Entities/SpeedQuizQuestion";
import { SpeedQuizRound } from "../Entities/SpeedQuizRound";

export class SpeedQuizRoundBuilder {
    protected _id: string
    protected _category: string
    protected _step: number
    protected _startDate: Date
    protected _questions: SpeedQuizQuestion[]

    withId(value: string): SpeedQuizRoundBuilder {
        this._id = value
        return this
    }

    withCategory(value: string): SpeedQuizRoundBuilder {
        this._category = value
        return this
    }

    withStep(value: number): SpeedQuizRoundBuilder {
        this._step = value
        return this
    }

    withStartDate(value: Date): SpeedQuizRoundBuilder {
        this._startDate = value
        return this
    }

    withQuestions(value: SpeedQuizQuestion[]): SpeedQuizRoundBuilder {
        this._questions = value
        return this
    }

    build(): SpeedQuizRound {
        return new SpeedQuizRound(this._id, this._category, this._step, this._startDate, this._questions)
    }
}