import { AuthenticationService } from '../Domain/Gateway/Authentication.service';
import { AuthenticationRepository } from '../Domain/Gateway/Authentication.repository';
import { APIAuthenticationService } from "../Adapters/Secondaries/Real/APIAuthentication.service";
import { LocalStorageAuthenticationRepository } from "../Adapters/Secondaries/Real/LocalStorageAuthentication.repository";

export class AuthenticationDependenciesFactory {
    static getAuthenticationService(): AuthenticationService {
        return new APIAuthenticationService()

    }

    static getAuthenticationRepository(): AuthenticationRepository {
        return new LocalStorageAuthenticationRepository()
    }
}
