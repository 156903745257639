import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_SPEED_QUIZ_PLAYER_STATS = 'GET_SPEED_QUIZ_PLAYER_STATS'
export const GET_SPEED_QUIZ_PLAYER_STATS_FAILED = 'GET_SPEED_QUIZ_PLAYER_STATS_FAILED'
export const GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED = 'GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED'

export interface GetSpeedQuizPlayerStatsAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_STATS
    payload: string
}

export interface GetSpeedQuizPlayerStatsFailedAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_STATS_FAILED
    payload: string
}

export interface GetSpeedQuizPlayerStatsSucceededAction {
    type: typeof GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED
    payload: CartesianData[]
}

export type GetSpeedQuizPlayerStatsActionTypes =
    GetSpeedQuizPlayerStatsAction
    | GetSpeedQuizPlayerStatsFailedAction
    | GetSpeedQuizPlayerStatsSucceededAction