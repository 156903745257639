import React, { PureComponent } from "react";
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import './Table.css';
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { EditButton } from "../../../../Common/Adapters/Primaries/Components/EditButton";
import { i18n } from "../../../../Configuration/I18n";
import SearchIcon from '@mui/icons-material/Search';
import { EmptyTableText } from "../../../../Common/Adapters/Primaries/Components/EmptyTableText";

export interface AgencyDataTable {
    code: string
    id: string
    name: string
    network: string
    region: string
}

interface Props {
    agencies: AgencyDataTable[];
}

interface State {
    searchValue: string
    status: string;
}

export class AgencyListTable extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            searchValue: '',
            status     : 'all'
        }
    }

    render() {
        const columns = [
            { field: 'code', headerName: i18n.agency.agency_code, flex: 2 },
            { field: 'name', headerName: i18n.agency.agency_name, flex: 2 },
            { field: 'region', headerName: i18n.agency.region_name, flex: 2 },
            { field: 'network', headerName: i18n.agency.network, flex: 2 },
            {
                field     : 'id', headerName: i18n.agency.actions, flex: 1, sortable: false,
                renderCell: (params: GridCellParams) => <EditButton path={'/update-agency/' + params.value}/>
            }
        ]

        return (

            <DataGrid className={'agency-table'}
                      rows={this.filterAgencies(this.props.agencies)}
                      columns={columns}
                      autoHeight={true}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick
                      disableColumnSelector
                      disableColumnFilter
                      sx={{
                          '& .MuiDataGrid-cell:focus': {
                              outline: 0
                          }
                      }}
                      disableColumnMenu
                      components={{
                          NoRowsOverlay: () => <EmptyTableText text={i18n.agency.no_agencies}/>,
                          Toolbar      : () => this.CustomToolbar()

                      }}
            />
        )
    }

    filterAgencies(agencies: AgencyDataTable[]): AgencyDataTable[] {
        return agencies
            .filter(agency => agency.code !== '')
            .filter(agency => agency.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1)
    }

    CustomToolbar(): JSX.Element {
        return (
            <Box component="form"
                 noValidate
                 autoComplete="off">

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   placeholder={i18n.agency.search}
                                   autoFocus={this.state.searchValue !== ''}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start"><SearchIcon/></InputAdornment>)
                                   }}
                                   value={this.state.searchValue}
                                   onChange={event => this.setState({ searchValue: event.target.value })}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
