import React, { Component, ReactNode } from 'react'
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { SpeedQuizAnswerType } from "../../../../Domain/SpeedQuiz.type";
import { SpeedQuizFormValidation } from "../FormValidation";

interface Props {
    label: string;
    error: boolean;
    answersError: boolean;
    answer: SpeedQuizAnswerType
    onChange: (key: keyof SpeedQuizAnswerType, value: string | boolean) => void;
}

interface State {
    answerError: boolean;
}

export class SpeedQuizAnswerContainer extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            answerError: false
        }
    }

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error === true && this.props.error !== prevProps.error)
            this.setState({
                answerError: !SpeedQuizFormValidation.validateTitle(this.props.answer.text) || this.props.answersError
            })
    }

    render(): ReactNode {
        return (
            <Grid item xs={12} sm={6}>
                <TextField fullWidth required
                           label={this.props.label}
                           value={this.props.answer.text}
                           error={this.state.answerError}
                           onChange={(event) => this.props.onChange('text', event.target.value)}/>

                <FormControlLabel label={this.props.answer.correct ? i18n.speedQuiz.good_answer : i18n.speedQuiz.bad_answer}
                                  control={<Switch checked={this.props.answer.correct}
                                                   color={'success'}
                                                   onChange={event => this.props.onChange('correct', event.target.checked)}/>
                                  }/>
            </Grid>

        )
    }
}