import { SpeedQuizService } from "../../../Domain/Gateway/SpeedQuiz.service";
import { Observable, throwError } from "rxjs";
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { catchError, map } from "rxjs/operators";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { SpeedQuizMapper } from "./mapper/SpeedQuiz.mapper";
import { SpeedQuizHeadDto } from "./DTO/speedQuizHead.dto";
import { SpeedQuizHeader } from "../../../Domain/Entities/SpeedQuizHeader";
import { SpeedQuiz } from "../../../Domain/Entities/SpeedQuiz";
import { SpeedQuizRound } from "../../../Domain/Entities/SpeedQuizRound";
import { SpeedQuizDto } from "./DTO/SpeedQuizDto";

export class ApiSpeedQuizService implements SpeedQuizService {
    getAllSpeedQuiz(): Observable<SpeedQuizHeader[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/speedQuiz/all'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizHeadDto[] }>(url)
            .pipe(
                map((response: { data: SpeedQuizHeadDto[] }) => SpeedQuizMapper.mapDataToSpeedQuizHeader(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addSpeedQuiz(speedQuiz: SpeedQuiz): Observable<void> {

        const url = process.env.REACT_APP_API_URL + '/v1/back/speedQuiz'
        const body = new FormData();
        body.append('label', speedQuiz.label);
        body.append('end_date', speedQuiz.endDate.toString());
        body.append('rounds', JSON.stringify(this.mapRoundsSpeedQuiz(speedQuiz.rounds)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void  0),
                catchError(err => throwError(err.response.message)
                )
            )
    }

    updateSpeedQuiz(speedQuiz: SpeedQuiz): Observable<void> {

        const url = process.env.REACT_APP_API_URL + '/v1/back/speedQuiz/' + speedQuiz.id + '/update'
        const body = new FormData();
        body.append('label', speedQuiz.label);
        body.append('end_date', speedQuiz.endDate.toString());
        body.append('rounds', JSON.stringify(this.mapUpdatesRoundsSpeedQuiz(speedQuiz.rounds)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void  0),
                catchError(err => throwError(err.response.message)
                )
            )
    }

    getSpeedQuiz(speedQuizId: string): Observable<SpeedQuiz> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/speedQuiz/' + speedQuizId
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizDto }>(url)
            .pipe(
                map((response: { data: SpeedQuizDto }) => SpeedQuizMapper.mapDataToSpeedQuiz(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    mapRoundsSpeedQuiz(rounds: SpeedQuizRound[]) {
        return rounds.map(round => {
            const questions = round.questions.map(item => {
                const responses = item.answers.map(answer => ({
                    text     : answer.text,
                    isCorrect: answer.isCorrect
                }))
                return {
                    text : item.text,
                    delay: item.delay,
                    responses
                }
            })
            return {
                category : round.category,
                step     : round.step,
                startDate: round.startDate,
                questions
            }
        })

    }

    mapUpdatesRoundsSpeedQuiz(rounds: SpeedQuizRound[]) {
        return rounds.map(round => {
            const questions = round.questions.map(item => {
                const responses = item.answers.map(answer => ({
                    id       : answer.id,
                    text     : answer.text,
                    isCorrect: answer.isCorrect
                }))
                return {
                    id   : item.id,
                    text : item.text,
                    delay: item.delay,
                    responses
                }
            })
            return {
                id       : round.id,
                category : round.category,
                step     : round.step,
                startDate: round.startDate,
                questions
            }
        })

    }

}