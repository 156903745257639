import {
    DELETE_MCQ_QUESTION,
    DELETE_MCQ_QUESTION_FAILED,
    DELETE_MCQ_QUESTION_SUCCEEDED, DeleteMCQQuestionActionTypes,
} from "./actionTypes";
import { HandleAdventureState } from "../../../Config/State";

const initialState: HandleAdventureState = {
    loading: false,
    error  : undefined
}
export const deleteMCQQuestionReducer = (state = initialState, action: DeleteMCQQuestionActionTypes): HandleAdventureState => {
    switch (action.type) {
        case DELETE_MCQ_QUESTION:
            return { loading: true, error: undefined }
        case DELETE_MCQ_QUESTION_FAILED:
            return { loading: false, error: action.payload }
        case DELETE_MCQ_QUESTION_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state;
    }
}