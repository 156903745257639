import {
    GET_SPEED_QUIZ_QUESTION_STATS,
    GET_SPEED_QUIZ_QUESTION_STATS_FAILED,
    GET_SPEED_QUIZ_QUESTION_STATS_SUCCEEDED,
    GetSpeedQuizQuestionStatsActionTypes,
    GetSpeedQuizQuestionStatsAction
} from './actionTypes';
import { SerieData } from "../../Domain/Entity/SerieData";

export const getSpeedQuizQuestionStats = (speedQuizId: string): GetSpeedQuizQuestionStatsAction => ({
    type   : GET_SPEED_QUIZ_QUESTION_STATS,
    payload: speedQuizId
})

export const getSpeedQuizQuestionStatsFailed = (error: string): GetSpeedQuizQuestionStatsActionTypes => ({
    type   : GET_SPEED_QUIZ_QUESTION_STATS_FAILED,
    payload: error
})

export const getSpeedQuizQuestionStatsSucceeded = (stats: SerieData[]): GetSpeedQuizQuestionStatsActionTypes => ({
    type   : GET_SPEED_QUIZ_QUESTION_STATS_SUCCEEDED,
    payload: stats
})
