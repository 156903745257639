import { SpeedQuiz } from "../../../Domain/Entities/SpeedQuiz";
import { AppState } from "../../../../Redux-configuration/AppState";
import { addSpeedQuizErrorSelector, addSpeedQuizLoadingSelector } from "../../../Usecases/AddSpeedQuiz/selectors";
import { Dispatch } from "redux";
import { addSpeedQuiz } from "../../../Usecases/AddSpeedQuiz/actions";
import { AddSpeedQuizAction } from "../../../Usecases/AddSpeedQuiz/actionTypes";
import { connect } from "react-redux";
import { AddSpeedQuizContainer } from "./AddSpeedQuiz.container";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined
}

interface DispatchToPropsType {
    addSpeedQuiz: (speedQuiz: SpeedQuiz) => void
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: addSpeedQuizLoadingSelector(state),
    error  : addSpeedQuizErrorSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addSpeedQuiz  : (speedQuiz: SpeedQuiz): AddSpeedQuizAction => dispatch(addSpeedQuiz(speedQuiz)),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AddSpeedQuizPage = connect(mapStateToProps, mapDispatchToProps)(AddSpeedQuizContainer)