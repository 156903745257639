import React from "react";
import { Typography } from "@mui/material";

interface Props {
    title: string
}

export const PageTitle = (props: Props) => {
    return (
        <Typography component="h2" className={'page-title'} style={{
            fontSize    : 20,
            fontWeight  : '600',
            color       : '#111827',
            marginTop   : 24,
            marginBottom: 40,
        }}>
            {props.title}
        </Typography>
    )
}
