import { MCQ } from "../../Domain/Entity/MCQ";

export const LOAD_ADVENTURE_MCQ = 'LOAD_ADVENTURE_MCQ'
export const LOAD_ADVENTURE_MCQ_FAILED = 'LOAD_ADVENTURE_MCQ_FAILED'
export const LOAD_ADVENTURE_MCQ_SUCCEEDED = 'LOAD_ADVENTURE_MCQ_SUCCEEDED'

export interface LoadAdventureMCQAction {
    type: typeof LOAD_ADVENTURE_MCQ
    payload: string
}

export interface LoadAdventureMCQFailedAction {
    type: typeof LOAD_ADVENTURE_MCQ_FAILED
    payload: string
}

export interface LoadAdventureMCQSucceededAction {
    type: typeof LOAD_ADVENTURE_MCQ_SUCCEEDED
    payload: MCQ
}

export type LoadAdventureMCQActionTypes =
    LoadAdventureMCQAction
    | LoadAdventureMCQFailedAction
    | LoadAdventureMCQSucceededAction