import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../Configuration/I18n";
import { Box } from "@mui/material";
import { LoginTextField } from "../Form/LoginTextField";
import { LoginSubmitButton } from "../Form/Button";

interface Props {
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
    requestPassword: (email: string) => void;
    taskSucceeded: (email: string) => void;
}

interface State {
    email: string;
    errorMsg: string | undefined;
}

export class Ask4PasswordContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            email   : '',
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.error === '400' && this.props.error !== prevProps.error)
            this.setState({ errorMsg: i18n.authentication.request_password_400 })

        else if (this.props.error === '406' && this.props.error !== prevProps.error)
            this.setState({ errorMsg: i18n.authentication.request_password_406 })

        if (this.props.success && this.props.success !== prevProps.success) {
            this.props.taskSucceeded(this.state.email)
        }
    }

    render(): ReactNode {
        return (
            <Box component="form" noValidate>
                <LoginTextField margin="normal"
                                fullWidth
                                id="email"
                                value={this.state.email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                    email   : event.target.value,
                                    errorMsg: undefined
                                })}
                                label={i18n.authentication.email_address}
                                error={this.state.errorMsg !== undefined}
                                helperText={this.state.errorMsg}/>

                <LoginSubmitButton title={i18n.authentication.ask_for_password}
                                   loading={this.props.loading}
                                   onClick={() => this.requestPassword()}/>
            </Box>
        )
    }

    private requestPassword() {
        if (this.isValid(this.state.email))
            this.props.requestPassword(this.state.email)
        else
            this.setState({ errorMsg: i18n.authentication.invalid_email })
    }

    private isValid(email: string): boolean {
        const regex = new RegExp(['^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
            '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
            '[a-zA-Z]{2,}))$'].join(''));
        return regex.test(email?.toLowerCase())
    }
}
