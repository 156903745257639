import React, { PureComponent, ReactNode } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";
import { Message } from "../../../../../Message/Domain/Entity/Message";

interface Props {
    value: string;
    messages: Message[] | null;
    onChange: (id: string) => void;
}

export class MessageSelectInput extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <FormControl fullWidth>
                        <InputLabel id="message-select-label">{i18n.statistics.message}</InputLabel>

                        <Select id="message-select"
                                labelId="message-select-label"
                                value={this.props.value}
                                label={i18n.statistics.message}
                                onChange={(event: SelectChangeEvent) => this.props.onChange(event.target.value)}>

                            {this.props.messages?.map(item => <MenuItem key={item.id}
                                                                        value={item.id}>{item.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}
