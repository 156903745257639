import { EmployeeDto } from "../DTO/EmployeeDto";
import { Employee } from "../../../../Domain/Entities/Employee";
import { EmployeeBuilder } from "../../../../Domain/Builder/EmployeeBuilder";
import { Agency } from "../../../../../Agence/Domain/Entities/Agency";
import { AgencyBuilder } from "../../../../../Agence/Domain/Builder/AgencyBuilder";
import { Region } from "../../../../../Agence/Domain/Entities/Region";

export class EmployeeMapper {
    static mapEmployeeDtoToEmployee(employeesDto: EmployeeDto[]): Employee[] {
        return employeesDto.map(employee => {
            const region = new Region(employee.regionCode, employee.regionName,employee.regionLabel)
            const agency: Agency = new AgencyBuilder()
                .withAgencyCode(employee.agencyCode)
                .withAgencyName(employee.agencyName)
                .withRegion(region)
                .build()
            return new EmployeeBuilder()
                .withEmail(employee.email)
                .withFirstName(employee.firstName)
                .withLastName(employee.lastName)
                .withQualification(employee.qualification)
                .withActive(employee.active)
                .withJob(employee.job)
                .withAgency(agency)
                .withAvatar(employee.avatar)
                .build()
        })
    }
}