export class CartesianData {
    constructor(
        protected _label: string,
        protected _value: number
    ) {
    }

    get label(): string {
        return this._label;
    }

    get value(): number {
        return this._value;
    }
}
