import { AddAgencyState } from "../../Config/State";
import {
    ADD_AGENCY,
    ADD_AGENCY_FAILED,
    ADD_AGENCY_SUCCEEDED, AddAgencyActionTypes
} from './actionTypes';

const initialState: AddAgencyState = {
    loading: false,
    success: null,
    error  : undefined
}

export const addAgencyReducer = (state = initialState, action: AddAgencyActionTypes): AddAgencyState => {
    switch (action.type) {
        case ADD_AGENCY:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case ADD_AGENCY_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case ADD_AGENCY_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
};
