import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { MessageService } from "../../../Domain/Gateway/Message.service";
import { Message } from "../../../Domain/Entity/Message";
import { MessageMapper } from "./Mapper/Message.mapper";
import { MessageDTO } from "./DTO/Message.DTO";

export class ApiMessageService implements MessageService {

    private headers: object = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept'      : 'application/json'
    }

    addMessage(message: Message): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/news'
        const body = {
            title     : message.title,
            message   : message.text,
            active    : message.isEnabled,
            start_date: message.startDate,
            end_date  : message.endDate
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    updateMessage(message: Message): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/news/' + message.id
        const body = {
            id        : message.id,
            title     : message.title,
            message   : message.text,
            active    : message.isEnabled,
            start_date: message.startDate,
            end_date  : message.endDate
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getMessages(): Observable<Message[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/news'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: MessageDTO[] }>(url)
            .pipe(
                map((response: { data: MessageDTO[] }) => MessageMapper.mapDataToMessageList(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

}
