import React, { PureComponent } from "react";
import { Box } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { Message } from "../../../Domain/Entity/Message";
import { MessageForm } from "../Components/MessageForm";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
    success: boolean | null;
    addMessage: (message: Message) => void;
    setTopBarTitle: (title: string) => void
}

export class AddMessageContainer extends PureComponent<Props> {

    componentDidMount(){
        this.props.setTopBarTitle(i18n.message.message_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.success && this.props.success !== prevProps.success)
            this.props.history.push("/message-list");
    }

    render() {
        return (
            <Box>
                <PageTitle title={i18n.message.add_message}/>

                <MessageForm message={null}
                             submitMessage={(message: Message) => this.props.addMessage(message)}/>
            </Box>
        )
    }
}
