import { AuthenticationI18n } from "../Authentication/Config/I18n";
import { CommonI18n } from "../Common/Config/I18n";
import { StatisticsI18n } from "../Statistics/Config/I18n";
import { EmployeeI18n } from "../Employee/Config/I18n";
import { AdventureI18n } from "../Adventure/Config/I18n";
import { MessageI18n } from "../Message/Config/I18n";
import { SpeedQuizI18n } from "../SpeedQuiz/Config/I18n";
import { NavigationI18n } from "../Navigations/config/I18n";
import { AgencyI18n } from "../Agence/Config/I18n";

export const i18n = {
    navigation    : NavigationI18n,
    authentication: AuthenticationI18n,
    common        : CommonI18n,
    statistics    : StatisticsI18n,
    employee      : EmployeeI18n,
    message       : MessageI18n,
    adventure     : AdventureI18n,
    speedQuiz     : SpeedQuizI18n,
    agency        : AgencyI18n
}
