import {
    RETRIEVE_PROFILE,
    RETRIEVE_PROFILE_FAILED,
    RETRIEVE_PROFILE_SUCCEEDED,
    RetrieveProfileAction,
    RetrieveProfileActionsTypes
} from "./RetrieveProfile.types";

export const retrieveProfile = (): RetrieveProfileAction => ({
    type: RETRIEVE_PROFILE
})

export const retrieveProfileFailed = (error: string): RetrieveProfileActionsTypes => ({
    type   : RETRIEVE_PROFILE_FAILED,
    payload: error
})

export const retrieveProfileSucceeded = (): RetrieveProfileActionsTypes => ({
    type: RETRIEVE_PROFILE_SUCCEEDED,
})