import {
    UPDATE_MCQ_QUESTION,
    UPDATE_MCQ_QUESTION_FAILED,
    UPDATE_MCQ_QUESTION_SUCCEEDED,
    UpdateMCQQuestionActionTypes
} from "./actionTypes";
import { HandleAdventureState } from "../../../Config/State";

const initialState: HandleAdventureState = {
    loading: false,
    error  : undefined
}
export const updateMCQQuestionReducer = (state = initialState, action: UpdateMCQQuestionActionTypes): HandleAdventureState => {
    switch (action.type) {
        case UPDATE_MCQ_QUESTION:
            return { loading: true, error: undefined }
        case UPDATE_MCQ_QUESTION_FAILED:
            return { loading: false, error: action.payload }
        case UPDATE_MCQ_QUESTION_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state;
    }
}