import {
    LOAD_MESSAGE_LIST,
    LOAD_MESSAGE_LIST_FAILED,
    LOAD_MESSAGE_LIST_SUCCEEDED,
    LoadMessageListActionTypes
} from './actionTypes';
import { Message } from "../../Domain/Entity/Message";

export const loadMessageList = (): LoadMessageListActionTypes => ({
    type: LOAD_MESSAGE_LIST
})

export const loadMessageListSucceeded = (messages: Message[]): LoadMessageListActionTypes => ({
    type   : LOAD_MESSAGE_LIST_SUCCEEDED,
    payload: messages
})

export const loadMessageListFailed = (error: string): LoadMessageListActionTypes => ({
    type   : LOAD_MESSAGE_LIST_FAILED,
    payload: error
})
