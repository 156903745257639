export const CommonI18n = {
    mission                : 'Mission Challenge',
    speedQuiz              : 'Mission Speed Quiz',
    statistics             : 'Statistiques',
    generalStats           : 'Stats générales',
    adventureList          : 'Liste des aventures',
    mcq                    : 'QCM',
    employees              : 'Gestion des employés',
    list                   : 'Liste',
    message                : 'Messagerie',
    agency                 : 'Agence',
    add                    : 'Ajouter',
    start_date             : 'Date de début',
    end_date               : 'Date de fin',
    enabled                : 'Activé',
    disabled               : 'Désactivé',
    add_picture            : 'Ajouter une image',
    add_picture_description: 'Parcourir les fichiers de votre ordinateur',
    empty_picture          : 'Veuillez ajouter une image ',
    error_size_picture     : `Veuillez vérifier la taille et les dimensions de l'image`,
    error_file_size        : 'Veuillez vérifier la taille de l\'image'
}
