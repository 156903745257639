import React, { PureComponent, ReactNode } from 'react'
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    onClick: () => void
}

export class EditQuestionButton extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <IconButton edge="end" aria-label="edit" onClick={() => this.props.onClick()}>
                <EditIcon/>
            </IconButton>
        )
    }
}