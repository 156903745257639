import { catchError, map, switchMap } from "rxjs/operators";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { GET_AGENCY_LIST, GetAgencyListAction } from "./actionTypes";
import { GetAgencyListState } from "../../Config/State";
import {
    getAgencyListFailed,
    getAgencyListSucceeded,


} from "./actions";
import { AgencyService } from "../../Domain/Gateway/agencyService";
import { Agency } from "../../Domain/Entities/Agency";

export const getAgencyListEpic: Epic = (action$: ActionsObservable<GetAgencyListAction>,
                                          store: StateObservable<GetAgencyListState>,
                                          { agencyService }: { agencyService: AgencyService }) =>
    action$.pipe(
        ofType(GET_AGENCY_LIST),
        switchMap(() => agencyService.getAgencyList()
            .pipe(
                map((agencies:Agency[]) => getAgencyListSucceeded(agencies)),
                catchError(error => of(getAgencyListFailed(error)))
            )
        )
    )
