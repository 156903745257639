import { Region } from "./Region";

export class Agency{
    constructor(
        protected _agencyCode: string,
        protected _agencyName: string,
        protected _region: Region,
        protected _network: string
    ){
}

    get agencyCode(): string {
        return this._agencyCode;
    }

    get agencyName(): string {
        return this._agencyName;
    }

    get region(): Region {
        return this._region;
    }

    get network(): string {
        return this._network;
    }
}
