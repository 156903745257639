import { Employee } from "../../Domain/Entities/Employee";

export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST'
export const GET_EMPLOYEE_LIST_FAILED = 'GET_EMPLOYEE_LIST_FAILED'
export const GET_EMPLOYEE_LIST_SUCCEEDED = 'GET_EMPLOYEE_LIST_SUCCEEDED'


export interface GetEmployeeListAction {
    type: typeof GET_EMPLOYEE_LIST
}

export interface GetEmployeeListFailedAction {
    type: typeof GET_EMPLOYEE_LIST_FAILED
    payload: string
}

export interface GetEmployeeListSucceededAction {
    type: typeof GET_EMPLOYEE_LIST_SUCCEEDED
    payload: Employee[]
}

export type GetEmployeeListActionTypes =
    GetEmployeeListAction
    | GetEmployeeListFailedAction
    | GetEmployeeListSucceededAction