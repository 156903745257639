import { LoginUserState } from "../../Config/State";
import { LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCEEDED } from './actionTypes';
import { LoginUserActionTypes } from './actionTypes';

const initialState: LoginUserState = {
    loading: false,
    error  : undefined,
    success: null
}
export const loginUserReducer = (state = initialState, action: LoginUserActionTypes): LoginUserState => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                loading: true,
                error  : undefined,
                success: null
            }
        case LOGIN_USER_FAILED:
            return {
                loading: false,
                error  : action.payload,
                success: null
            }
        case LOGIN_USER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                success: true
            }
        default:
            return state
    }
}