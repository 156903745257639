import { catchError, map, switchMap } from "rxjs/operators";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { ADD_EMPLOYEE, AddEmployeeAction } from "./actionTypes";
import { HandleEmployeeState } from "../../Config/State";
import { EmployeeService } from "../../Domain/Gateway/Employee.service";
import { addEmployeeFailed, addEmployeeSucceeded, } from "./actions";

export const addEmployeeEpic: Epic = (action$: ActionsObservable<AddEmployeeAction>,
                                          store: StateObservable<HandleEmployeeState>,
                                          { employeeService }: { employeeService: EmployeeService }) =>
    action$.pipe(
        ofType(ADD_EMPLOYEE),
        switchMap((action) => employeeService.addEmployee(action.payload)
            .pipe(
                map(() => addEmployeeSucceeded()),
                catchError(error => of(addEmployeeFailed(error)))
            )
        )
    )
