import { SpeedQuizAnswer } from "../Entities/SpeedQuizAnswer";
import { SpeedQuizQuestion } from "../Entities/SpeedQuizQuestion";

export class SpeedQuizQuestionBuilder {
    protected _id: number
    protected _text: string
    protected _delay: number
    protected _answers: SpeedQuizAnswer[]

    withId(value: number): SpeedQuizQuestionBuilder {
        this._id = value
        return this
    }

    withText(value: string): SpeedQuizQuestionBuilder {
        this._text = value
        return this
    }

    withDelay(value: number): SpeedQuizQuestionBuilder {
        this._delay = value
        return this
    }

    withAnswers(value: SpeedQuizAnswer[]): SpeedQuizQuestionBuilder {
        this._answers = value
        return this
    }

    fromQuestion(question : SpeedQuizQuestion): SpeedQuizQuestionBuilder{
        this._id= question.id
        this._text= question.text
        this._delay= question.delay
        this._answers= question.answers
        return this
    }

    build(): SpeedQuizQuestion {
        return new SpeedQuizQuestion(this._id, this._text, this._delay, this._answers)
    }
}