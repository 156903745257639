import {
    GET_ADVENTURE_SUBSCRIPTION_STATS,
    GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED,
    GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED,
    GetAdventureSubscriptionStatsAction,
    GetAdventureSubscriptionStatsFailedAction,
    GetAdventureSubscriptionStatsSucceededAction
} from "./actionTypes";
import { SerieData } from "../../Domain/Entity/SerieData";

export const getAdventureSubscriptionStats = (adventureId: string): GetAdventureSubscriptionStatsAction => ({
    type   : GET_ADVENTURE_SUBSCRIPTION_STATS,
    payload: adventureId
})

export const getAdventureSubscriptionStatsFailed = (error: string): GetAdventureSubscriptionStatsFailedAction => ({
    type   : GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED,
    payload: error
})

export const getAdventureSubscriptionStatsSucceeded = (data: SerieData[]): GetAdventureSubscriptionStatsSucceededAction => ({
    type   : GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED,
    payload: data
})