import { SpeedQuizQuestion } from "./SpeedQuizQuestion";

export class SpeedQuizRound {
    constructor(
        protected _id: string,
        protected _category: string,
        protected _step: number,
        protected _startDate: Date,
        protected _questions: SpeedQuizQuestion[]
    ){}

    get id(): string {
        return this._id;
    }

    get category(): string {
        return this._category;
    }

    get step(): number {
        return this._step;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get questions(): SpeedQuizQuestion[] {
        return this._questions;
    }
}