import React, { PureComponent, ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import './FormBlock.css';

interface Props {
    title: string
    description?: string
    children: ReactNode;
}

export class FormBlock extends PureComponent<Props> {

    render() {
        return (
            <Box className={'form-block'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Typography className={'form-block-title'}>
                            {this.props.title}
                        </Typography>

                        <Typography className={'form-block-subtitle'} component="span">
                            {this.props.description}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        {this.props.children}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
