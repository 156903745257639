import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AuthenticationService } from '../../Domain/Gateway/Authentication.service';
import { RequestPasswordState } from "../../Config/State";
import { requestPasswordFailed, requestPasswordSucceeded } from "./actions";
import { REQUEST_PASSWORD, RequestPasswordAction } from "./actionTypes";

export const requestPasswordEpic: Epic = (action$: ActionsObservable<RequestPasswordAction>,
                                          store: StateObservable<RequestPasswordState>,
                                          { authenticationService }: { authenticationService: AuthenticationService }) =>
    action$.pipe(
        ofType(REQUEST_PASSWORD),
        switchMap(
            action => authenticationService.requestPassword(action.payload)
                .pipe(
                    map(() => requestPasswordSucceeded()),
                    catchError(error => of(requestPasswordFailed(error)))
                )
        )
    )
