import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const LoginTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline"             : {
            borderColor: "#c8c8c8"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline"       : {
            borderColor: "#c8c8c8"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderColor: "#c8c8c8"
        },
        "& .MuiOutlinedInput-input"                                             : {
            color: "#c8c8c8"
        },
        "&:hover .MuiOutlinedInput-input"                                       : {
            color: "#c8c8c8"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input"          : {
            color: "#c8c8c8"
        },
        "& .MuiInputLabel-outlined"                                             : {
            color: "#c8c8c8"
        },
        "&:hover .MuiInputLabel-outlined"                                       : {
            color: "#c8c8c8"
        },
        "& .MuiInputLabel-outlined.Mui-focused"                                 : {
            color: "#c8c8c8"
        },
        "& .MuiInputLabel-outlined.Mui-error"                                   : {
            color: "#d32f2f"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline"   : {
            borderColor: "#d32f2f"
        },
        "& .MuiFormHelperText-root.Mui-error"                                   : {
            borderColor: "#d32f2f",
            marginTop  : 8,
            marginLeft : 0,
            marginRight: 0
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.3)"
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input"         : {
            WebkitTextFillColor: "rgba(255, 255, 255, 0.3)"
        }
    }
})(TextField);
