import React, { PureComponent, ReactNode } from "react";
import AppSidebar from "./Sidebar/AppSidebar";
import { AppTopBar } from "./Topbar";
import './style.css'
import { MainContent } from "./Main/MainContent";

interface Props {
    children: JSX.Element;
}

export class LayoutContainer extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'appRoot'}>
                <AppTopBar/>

                <AppSidebar/>

                <MainContent>{this.props.children}</MainContent>
            </div>
        )
    }
}