import { combineReducers, Reducer } from "redux";
import { SpeedQuizState } from "./State";
import { getAllSpeedQuizReducer } from "../Usecases/GetAllSpeedQuiz/reducer";
import { addSpeedQuizReducer } from "../Usecases/AddSpeedQuiz/reducer";
import { updateSpeedQuizReducer } from "../Usecases/UpdateSpeedQuiz/reducer";
import { getSpeedQuizReducer } from "../Usecases/GetSpeedQuiz/reducer";

export const speedQuizRootReducer: Reducer = combineReducers<SpeedQuizState>({
    getAllSpeedQuiz: getAllSpeedQuizReducer,
    addSpeedQuiz   : addSpeedQuizReducer,
    updateSpeedQuiz: updateSpeedQuizReducer,
    getSpeedQuiz   : getSpeedQuizReducer
})