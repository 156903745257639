import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";
import {
    ADD_SPEED_QUIZ,
    ADD_SPEED_QUIZ_FAILED, ADD_SPEED_QUIZ_SUCCEEDED,
    AddSpeedQuizAction,
    AddSpeedQuizFailedAction,
    AddSpeedQuizSucceedddAction
} from "./actionTypes";

export const addSpeedQuiz =(speedQuiz : SpeedQuiz): AddSpeedQuizAction =>({
    type: ADD_SPEED_QUIZ,
    payload: speedQuiz
})

export const addSpeedQuizFailed = (error: string): AddSpeedQuizFailedAction =>({
    type: ADD_SPEED_QUIZ_FAILED,
    payload: error
})

export const addSpeedQuizSucceeded = (): AddSpeedQuizSucceedddAction =>({
    type: ADD_SPEED_QUIZ_SUCCEEDED
})