import React, { PureComponent, ReactNode } from "react";

interface Props {
    children: JSX.Element;
}

export class MainContent extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'page'}>
                {this.props.children}
            </div>
        )
    }
}
