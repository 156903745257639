import { SpeedQuiz } from "../../../Domain/Entities/SpeedQuiz";
import { AppState } from "../../../../Redux-configuration/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { UpdateSpeedQuizAction } from "../../../Usecases/UpdateSpeedQuiz/actionTypes";
import { updateSpeedQuiz } from "../../../Usecases/UpdateSpeedQuiz/actions";
import { UpdateSpeedQuizContainer } from "./UpdateSpeedQuiz.container";
import {
    updateSpeedQuizErrorSelector,
    updateSpeedQuizLoadingSelector
} from "../../../Usecases/UpdateSpeedQuiz/selectors";
import { speedQuizSelector } from "../../../Usecases/GetSpeedQuiz/selectors";
import { GetSpeedQuizAction } from "../../../Usecases/GetSpeedQuiz/actionTypes";
import { getSpeedQuiz } from "../../../Usecases/GetSpeedQuiz/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined
    speedQuiz: SpeedQuiz | null
}

interface DispatchToPropsType {
    updateSpeedQuiz: (speedQuiz: SpeedQuiz) => void
    getSpeedQuiz: (speedQuizId: string) => void
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading  : updateSpeedQuizLoadingSelector(state),
    error    : updateSpeedQuizErrorSelector(state),
    speedQuiz: speedQuizSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    updateSpeedQuiz: (speedQuiz: SpeedQuiz): UpdateSpeedQuizAction => dispatch(updateSpeedQuiz(speedQuiz)),
    getSpeedQuiz   : (speedQuizId: string): GetSpeedQuizAction => dispatch(getSpeedQuiz(speedQuizId)),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const UpdateSpeedQuizPage = connect(mapStateToProps, mapDispatchToProps)(UpdateSpeedQuizContainer)