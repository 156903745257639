import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { Agency } from "../../../Domain/Entities/Agency";
import {
    addAgencyErrorSelector,
    addAgencyLoadingSelector,
    addAgencySuccessSelector
} from "../../../Usecases/Add/selector";
import { AddAgencyActionTypes } from "../../../Usecases/Add/actionTypes";
import { addAgency } from "../../../Usecases/Add/actions";
import { AddAgencyContainer } from "./AddAgency.container";
import { Region } from "../../../Domain/Entities/Region";
import { regionsListSelector } from "../../../Usecases/GetRegionList/selector";

interface StateToPropsType {
    success: boolean | null;
    error: string | undefined;
    loading: boolean
    regions: Region[] | null
}

interface DispatchToPropsType {
    addAgency: (agency: Agency) => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    success: addAgencySuccessSelector(state),
    error: addAgencyErrorSelector(state),
    loading: addAgencyLoadingSelector(state),
    regions: regionsListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addAgency     : (agency: Agency): AddAgencyActionTypes => dispatch(addAgency(agency)),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AddAgencyPage = connect(mapStateToProps, mapDispatchToProps)(AddAgencyContainer)
