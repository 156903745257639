import {
    GET_SPEED_QUIZ_PLAYER_COUNT,
    GET_SPEED_QUIZ_PLAYER_COUNT_FAILED, GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED, GetSpeedQuizPlayerCountActionTypes,
} from "./actionTypes";
import { SpeedQuizPlayerCountState } from "../../Config/State";

const initialState: SpeedQuizPlayerCountState = {
    loading    : false,
    error      : undefined,
    playerCount: undefined
}
export const getSpeedQuizPlayerCountReducer = (state = initialState, action: GetSpeedQuizPlayerCountActionTypes): SpeedQuizPlayerCountState => {
    switch (action.type) {
        case GET_SPEED_QUIZ_PLAYER_COUNT:
            return { loading: true, playerCount: undefined, error: undefined }
        case GET_SPEED_QUIZ_PLAYER_COUNT_FAILED:
            return { loading: false, error: action.payload, playerCount: undefined }
        case GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED:
            return { loading: false, error: undefined, playerCount: action.payload }
        default:
            return state
    }
}