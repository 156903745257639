import { SerieData } from "../../Domain/Entity/SerieData";

export const GET_ADVENTURE_SUBSCRIPTION_STATS = 'GET_ADVENTURE_SUBSCRIPTION_STATS'
export const GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED = 'GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED'
export const GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED = 'GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED'

export interface GetAdventureSubscriptionStatsAction {
    type: typeof GET_ADVENTURE_SUBSCRIPTION_STATS
    payload: string
}

export interface GetAdventureSubscriptionStatsFailedAction {
    type: typeof GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED
    payload: string
}

export interface GetAdventureSubscriptionStatsSucceededAction {
    type: typeof GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED
    payload: SerieData[]
}

export type GetAdventureSubscriptionStatsActionTypes =
    GetAdventureSubscriptionStatsAction
    | GetAdventureSubscriptionStatsFailedAction
    | GetAdventureSubscriptionStatsSucceededAction