import { combineReducers } from 'redux';
import { AdventureState, MCQState } from './State';
import { loadAdventureListReducer } from "../Usecases/List/reducer";
import { addAdventureReducer } from "../Usecases/Add/reducer";
import { updateAdventureReducer } from "../Usecases/UpdateAdventure/reducer";
import { updateMCQQuestionReducer } from "../Usecases/UpdateMCQ/UpdateQuestion/reducer";
import { createMCQQuestionReducer } from "../Usecases/UpdateMCQ/CreateQuestion/reducer";
import { deleteMCQQuestionReducer } from "../Usecases/UpdateMCQ/DeleteQuestion/reducer";
import { loadAdventureMCQReducer } from "../Usecases/LoadMCQ/reducer";

export const adventureRootReducer = combineReducers<AdventureState>({
    list           : loadAdventureListReducer,
    addAdventure   : addAdventureReducer,
    updateAdventure: updateAdventureReducer,
    mcq            : combineReducers<MCQState>({
        updateQuestion: updateMCQQuestionReducer,
        createQuestion: createMCQQuestionReducer,
        deleteQuestion: deleteMCQQuestionReducer,
        loadMCQ       : loadAdventureMCQReducer
    })
})
