import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { MessageListContainer } from "./MessageList.container";
import { loadMessageList } from "../../../Usecases/List/actions";
import { LoadMessageListActionTypes } from "../../../Usecases/List/actionTypes";
import { Message } from "../../../Domain/Entity/Message";
import { loadMessageListLoadingSelector, messageListSelector } from "../../../Usecases/List/selector";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean
    messages: Message[] | null;
}

interface DispatchToPropsType {
    loadMessages: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading : loadMessageListLoadingSelector(state),
    messages: messageListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadMessages  : (): LoadMessageListActionTypes => dispatch(loadMessageList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const MessageListPage = connect(mapStateToProps, mapDispatchToProps)(MessageListContainer)
