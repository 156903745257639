import { AuthenticateUserState } from "../../Config/State";
import {
    USER_IS_AUTHENTICATED,
    USER_IS_NOT_AUTHENTICATED,
    AUTHENTICATE_USER,
    AuthenticateUserActionTypes
} from './actionTypes';

const initialState: AuthenticateUserState = {
    loading        : false,
    isAuthenticated: null,
    token          : null
}

export const authenticateUserReducer = (state = initialState, action: AuthenticateUserActionTypes): AuthenticateUserState => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            return {
                loading        : true,
                isAuthenticated: null,
                token          : null
            }
        case USER_IS_NOT_AUTHENTICATED:
            return {
                loading        : false,
                isAuthenticated: false,
                token          : null
            }
        case USER_IS_AUTHENTICATED:
            return {
                loading        : false,
                isAuthenticated: true,
                token          : action.payload
            }
        default:
            return state
    }
}
