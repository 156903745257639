import { catchError, map, switchMap } from "rxjs/operators";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { GET_EMPLOYEE_LIST, GetEmployeeListAction } from "./actionTypes";
import { GetEmployeeListState } from "../../Config/State";
import { EmployeeService } from "../../Domain/Gateway/Employee.service";
import { Employee } from "../../Domain/Entities/Employee";
import { getEmployeeListFailed, getEmployeeListSucceeded } from "./actions";

export const getEmployeeListEpic: Epic = (action$: ActionsObservable<GetEmployeeListAction>,
                                          store: StateObservable<GetEmployeeListState>,
                                          { employeeService }: { employeeService: EmployeeService }) =>
    action$.pipe(
        ofType(GET_EMPLOYEE_LIST),
        switchMap(() => employeeService.getEmployeeList()
            .pipe(
                map((employees: Employee[]) => getEmployeeListSucceeded(employees)),
                catchError(error => of(getEmployeeListFailed(error)))
            )
        )
    )
