import React, { PureComponent, ReactNode } from 'react';
import { Provider } from "react-redux";
import { reduxStore } from "./Redux-configuration/store.redux";
import './App.css';
import { authenticateUser } from "./Authentication/Usecases/AuthenticateUser/actions";
import { Unsubscribe } from "redux";
import { storedTokenSelector } from "./Authentication/Usecases/AuthenticateUser/selector";
import { ThemeProvider } from "@mui/material";
import { globalTheme } from "./Configuration/Theme";
import { retrieveProfile } from "./Profile/Usecases/Retrieve-Profile/RetrieveProfile.actions";
import { RootNavigationContainer } from "./Navigations/adapters/primaries/rootNavigationContainer";
import { getRegionList } from "./Agence/Usecases/GetRegionList/actions";

const store = reduxStore()

interface State {
    isAuthenticated: null | boolean;
}

export class App extends PureComponent<object, State> {

    private unsubscribe: Unsubscribe;

    constructor(props: object) {
        super(props);
        this.state = {
            isAuthenticated: null
        }
    }

    componentDidMount(): void {
        this.readinessListener()
            .finally(() => {
                this.setState({ isAuthenticated: this.hasSession() })
                this.unsubscribe()
            })
        store.dispatch(authenticateUser())
    }


    render(): ReactNode {
        if (this.state.isAuthenticated !== null)
            return (
                <ThemeProvider theme={globalTheme}>
                    <Provider store={store}>
                        <RootNavigationContainer isAuthenticated={this.state.isAuthenticated}/>
                    </Provider>
                </ThemeProvider>
            )
        else
            return (
                <div>
                    loader
                </div>
            )
    }

    private readinessListener(): Promise<void> {
        return new Promise<void>((resolve: () => void) => {
            let eventCounter = 0
            this.unsubscribe = store.subscribe(() => {
                if (eventCounter === 0 && this.hasFinishAuthenticating()) {
                    eventCounter++
                    if (this.hasSession()) {
                        this.initiateActions()
                    }
                    else {
                        resolve()
                    }
                }
                else if (eventCounter === 1 && this.hasSession() && this.hasFinishLoadingProfile()) {
                    ++eventCounter
                    resolve()
                }
            })
        })
    }

    hasFinishAuthenticating(): boolean {
        return storedTokenSelector(store.getState()) !== null
    }

    hasSession(): boolean {
        return storedTokenSelector(store.getState()) !== null && storedTokenSelector(store.getState()) !== 'TOKEN_NOT_FOUND'
    }

    hasFinishLoadingProfile(): boolean {
        return store.getState().profile.user !== undefined
    }

    initiateActions(): void {
        store.dispatch(retrieveProfile())
        store.dispatch(getRegionList())
    }
}

export default App;
