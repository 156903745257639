import { Profile } from "../../../../Domain/Entities/Profile";
import { ProfileDto } from "../DTO/Profile.dto";
import { ProfileBuilder } from "../../../../Domain/Entities/Profile.builder";

export class ProfileMapper {
    static mapProfileDtoToProfile(profileDto: ProfileDto): Profile {
        const profile = new ProfileBuilder()
            .withEmail(profileDto.email)
            .withFirstName(profileDto.firstName)
            .withLastName(profileDto.lastName)
            .withRegion(profileDto.regionLabel)
            .withAgency(profileDto.agencyName)
            .withAgencyCode(profileDto.agencyCode)
            .withScore(profileDto.score)
            .withType(profileDto.type)

        if (profileDto.avatar)
            profile.withAvatar(profileDto.avatar)
        return profile.build()
    }
}
