export const MessageI18n = {
    add_message       : 'Ajouter un message',
    update_message    : 'Modifier le message: ',
    title             : 'Titre',
    details           : 'Details',
    message           : 'Le message',
    date              : 'Date',
    activate          : 'Activer',
    save              : 'Enregistrer',
    message_list      : 'Liste des messages',
    text              : 'Texte',
    start_date        : 'Date de début',
    end_date          : 'Date de fin',
    status            : 'Statut',
    actions           : 'Actions',
    no_messages       : 'Pas de messages',
    all               : 'Tous',
    enabled           : 'Activé',
    disabled          : 'Désactivé',
    search            : 'chercher un message',
    fill_in_all_inputs: 'Veuillez remplir tous les champs',
    title_error       : 'Veuillez saisir un titre d\'au moins 3 caractéres',
    text_error        : 'Veuillez saisir un texte d\'au moins 15 caractéres',
    date_error        : 'Veuillez vérifier les dates',
    update_succeeded  : 'Message mis à jour',
    message_title     : 'Messagerie'
}
