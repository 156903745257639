import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_MESSAGE_STATS = 'GET_MESSAGE_STATS'
export const GET_MESSAGE_STATS_FAILED = 'GET_MESSAGE_STATS_FAILED'
export const GET_MESSAGE_STATS_SUCCEEDED = 'GET_MESSAGE_STATS_SUCCEEDED'

export interface GetMessageStatsAction {
    type: typeof GET_MESSAGE_STATS
    payload: string
}

export interface GetMessageStatsFailedAction {
    type: typeof GET_MESSAGE_STATS_FAILED
    payload: string
}

export interface GetMessageStatsSucceededAction {
    type: typeof GET_MESSAGE_STATS_SUCCEEDED
    payload: CartesianData[]
}

export type GetMessageStatsActionTypes =
    GetMessageStatsAction
    | GetMessageStatsFailedAction
    | GetMessageStatsSucceededAction
