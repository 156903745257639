import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_MESSAGE_LIST, LoadMessageListAction } from "./actionTypes";
import { LoadMessageListState } from "../../Config/State";
import { MessageService } from "../../Domain/Gateway/Message.service";
import { loadMessageListFailed, loadMessageListSucceeded } from "./actions";
import { Message } from "../../Domain/Entity/Message";

export const loadMessageListEpic: Epic = (action$: ActionsObservable<LoadMessageListAction>,
                                          store: StateObservable<LoadMessageListState>,
                                          { messageService }: { messageService: MessageService }) =>
    action$.pipe(
        ofType(LOAD_MESSAGE_LIST),
        switchMap(() => messageService.getMessages()
            .pipe(
                map((messages: Message[]) => loadMessageListSucceeded(messages)),
                catchError(error => of(loadMessageListFailed(error)))
            )
        )
    )
