import { CartesianData } from "../../Domain/Entity/CartesianData";

export const GET_AVATAR_STATS = 'GET_AVATAR_STATS'
export const GET_AVATAR_STATS_FAILED = 'GET_AVATAR_STATS_FAILED'
export const GET_AVATAR_STATS_SUCCEEDED = 'GET_AVATAR_STATS_SUCCEEDED'

export  interface GetAvatarStatsAction {
    type: typeof GET_AVATAR_STATS;
}

interface GetAvatarStatsFailedAction {
    type: typeof GET_AVATAR_STATS_FAILED;
    payload: string;
}

interface GetAvatarStatsSucceededAction {
    type: typeof GET_AVATAR_STATS_SUCCEEDED;
    payload: CartesianData[];
}

export type GetAvatarStatsActionTypes = GetAvatarStatsAction | GetAvatarStatsFailedAction | GetAvatarStatsSucceededAction
