import React, { PureComponent, ReactNode } from 'react'
import { MCQQuestion } from "../../../../../../Domain/Entity/MCQQuestion";
import { List, ListItem } from "@mui/material";
import { MCQQuestionItem } from "./MCQQuestion.Item";

interface Props {
    questions: MCQQuestion[]
    onEdit: (questionId: number) => void
}

export class MCQQuestionList extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <List>
                {this.props.questions.map((question: MCQQuestion, index) =>
                    <ListItem key={index}>
                        <MCQQuestionItem question={question} rank={index + 1}
                                         onEdit={() => this.props.onEdit(index)}/>
                    </ListItem>
                )}
            </List>
        )
    }
}