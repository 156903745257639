import {
    GET_SPEED_QUIZ_PLAYER_STATS, GET_SPEED_QUIZ_PLAYER_STATS_FAILED, GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED,
    GetSpeedQuizPlayerStatsAction,
    GetSpeedQuizPlayerStatsFailedAction, GetSpeedQuizPlayerStatsSucceededAction
} from "./actionTypes";
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getSpeedQuizPlayerStats = (speedQuizId: string): GetSpeedQuizPlayerStatsAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_STATS,
    payload: speedQuizId
})

export const getSpeedQuizPlayerStatsFailed = (error: string): GetSpeedQuizPlayerStatsFailedAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_STATS_FAILED,
    payload: error
})

export const getSpeedQuizPlayerStatsSucceeded = (stats: CartesianData[]): GetSpeedQuizPlayerStatsSucceededAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_STATS_SUCCEEDED,
    payload: stats
})