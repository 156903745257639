import React, { PureComponent } from "react";
import { Box, CircularProgress } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { RouteComponentProps } from "react-router";
import { Agency } from "../../../Domain/Entities/Agency";
import { AgencyForm } from "../Components/AgencyForm";
import { Region } from "../../../Domain/Entities/Region";

interface Props extends RouteComponentProps {
    success: boolean | null;
    addAgency: (agency: Agency) => void;
    setTopBarTitle: (title: string) => void
    error: string | undefined;
    loading: boolean
    regions: Region[] | null
}

export class AddAgencyContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.setTopBarTitle(i18n.agency.agency_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.success && this.props.success !== prevProps.success)
            this.props.history.push("/agency-list");
    }

    render() {
        return (
            <Box>
                <PageTitle title={i18n.agency.add_agency}/>

                <AgencyForm agency={null}
                            error={this.props.error}
                            regions={this.props.regions}
                            submitAgency={(agency: Agency) => this.props.addAgency(agency)}/>

                {this.props.loading && this.renderLoading()}
            </Box>
        )
    }

    renderLoading(): JSX.Element {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress/>
            </Box>
        )
    }
}
