import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';
import { MultivariateData } from "../../../Domain/Entity/MultivariateData";
import { StatisticsGridTitle } from "./Title";

interface Props {
    title: string;
    stats: MultivariateData[];
}

export class HorizontalBarChart extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid item sm={12}>
                <Box className={'statistics-grid'}>
                    <StatisticsGridTitle title={this.props.title}
                                         info={this.props.title}/>

                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={500}
                                  height={300}
                                  style={{ fontFamily: 'sans-serif', fontSize: 12 }}
                                  data={this.props.stats}
                                  margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                  }}
                                  barSize={25} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3"/>

                            <XAxis type="number" />
                            <YAxis type="category" dataKey="label"  axisLine={false} tickLine={false} />

                            <Bar dataKey="valueB" fill="#77bb40" stackId="a" minPointSize={2}
                               >
                                <LabelList dataKey="valueB" position={'insideRight'} formatter={(value: string) => `${value} %`}
                                           style={{ fill: "white", fontWeight: 'bold',  }}/>
                            </Bar>
                            <Bar dataKey="valueA" fill="#e22401" stackId="a" minPointSize={2}
                                >
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        )
    }
}
