import { combineEpics } from 'redux-observable';
import { loadAdventureListEpic } from "../Usecases/List/epic";
import { addAdventureEpic } from "../Usecases/Add/epic";
import { updateAdventureEpic } from "../Usecases/UpdateAdventure/epic";
import { updateMCQQuestionEpic } from "../Usecases/UpdateMCQ/UpdateQuestion/epic";
import { createMCQQuestionEpic } from "../Usecases/UpdateMCQ/CreateQuestion/epic";
import { deleteMCQQuestionEpic } from "../Usecases/UpdateMCQ/DeleteQuestion/epic";
import { loadAdventureMCQEpic } from "../Usecases/LoadMCQ/epic";

export const adventureRootEpic = combineEpics(
    loadAdventureListEpic,
    addAdventureEpic,
    updateAdventureEpic,
    updateMCQQuestionEpic,
    createMCQQuestionEpic,
    deleteMCQQuestionEpic,
    loadAdventureMCQEpic
)
