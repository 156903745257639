import { SpeedQuizHeader } from "../../Domain/Entities/SpeedQuizHeader";

export const GET_ALL_SPEED_QUIZ = 'GET_ALL_SPEED_QUIZ'
export const GET_ALL_SPEED_QUIZ_FAILED = 'GET_ALL_SPEED_QUIZ_FAILED'
export const GET_ALL_SPEED_QUIZ_SUCCEEDED = 'GET_ALL_SPEED_QUIZ_SUCCEEDED'


export interface GetAllSpeedQuizAction {
    type: typeof GET_ALL_SPEED_QUIZ
}

export interface GetAllSpeedQuizFailedAction {
    type: typeof GET_ALL_SPEED_QUIZ_FAILED
    payload: string
}

export interface GetAllSpeedQuizSucceededAction {
    type: typeof GET_ALL_SPEED_QUIZ_SUCCEEDED
    payload: SpeedQuizHeader[]
}

export type GetAllSpeedQuizActionTypes =
    GetAllSpeedQuizAction
    | GetAllSpeedQuizFailedAction
    | GetAllSpeedQuizSucceededAction