import React, { PureComponent } from "react";
import { Box, CircularProgress } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitleWithButton } from "../../../../Common/Adapters/Primaries/Components/PageTitleWithButton";
import { Agency } from "../../../Domain/Entities/Agency";
import { AgencyDataTable, AgencyListTable } from "../Components/AgencyTable";


interface Props {
    loading: boolean
    agencies: Agency[] | null;
    loadAgencies: () => void;
    setTopBarTitle: (title: string) => void
}

export class AgencyListContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.setTopBarTitle(i18n.agency.agency_title)

        if (!this.props.agencies && !this.props.loading)
            this.props.loadAgencies()
    }

    render() {
        return (
            <Box>
                <PageTitleWithButton title={i18n.agency.agency_list}
                                     button={'+ ' + i18n.agency.add_agency}
                                     path={'/add-agency'}/>

                {this.props.loading &&
                <Box className={'page-loading'}>
                    <CircularProgress/>
                </Box>
                }
                {this.props.agencies &&
                <AgencyListTable agencies={this.mapAgenciesToAgencyDataTable(this.props.agencies)}/>}
            </Box>
        )
    }

    mapAgenciesToAgencyDataTable(agencies: Agency[]): AgencyDataTable[] {
        return agencies.map(agency => (
            {
                code   : agency.agencyCode,
                id     : agency.agencyCode,
                name   : agency.agencyName,
                network: agency.network,
                region : agency.region.regionName
            }))
    }
}
