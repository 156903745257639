import { of } from 'rxjs';
import { loginUserFailed, loginUserSucceeded } from './actions';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { AppState } from "../../../Redux-configuration/AppState";
import { catchError, concatMap, map, switchMap } from 'rxjs/operators'
import { AuthenticationService } from '../../Domain/Gateway/Authentication.service';
import { AuthenticationRepository } from '../../Domain/Gateway/Authentication.repository';
import { LOGIN_USER, LoginUserAction } from './actionTypes';

export const loginUserEpic: Epic = (action$: ActionsObservable<LoginUserAction>,
                                    store: StateObservable<AppState>,
                                    { authenticationService, authenticationRepository }: {
                                        authenticationService: AuthenticationService;
                                        authenticationRepository: AuthenticationRepository;
                                    }) =>
    action$.pipe(
        ofType(LOGIN_USER),
        switchMap(action => authenticationService.signInUser(action.payload.email, action.payload.password)
            .pipe(
                concatMap((token: string) => authenticationRepository.saveAuthenticationToken(token)
                    .pipe(
                        map(() => loginUserSucceeded()),
                        catchError((error: string) => of(loginUserFailed(error)))
                    )
                ), catchError(error => of(loginUserFailed(error)))
            )
        )
    )
