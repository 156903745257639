import React, { PureComponent, ReactNode } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Adventure } from "../../../../../Adventure/Domain/Entity/Adventure";
import { i18n } from "../../../../../Configuration/I18n";

interface Props {
    value: string;
    adventures: Adventure[];
    onChange: (id: string) => void;
}

export class AdventureSelectInput extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <FormControl fullWidth>
                        <InputLabel id="simple-select-label">{i18n.statistics.adventure}</InputLabel>

                        <Select id="simple-select"
                                labelId="simple-select-label"
                                value={this.props.value}
                                label={i18n.statistics.adventure}
                                onChange={(event: SelectChangeEvent) => this.props.onChange(event.target.value)}>

                            {this.props.adventures.map(adventure => <MenuItem key={adventure.id}
                                                                              value={adventure.id}>{adventure.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}
