export class Region {

    constructor(
        protected _regionCode: string,
        protected _regionName: string,
        protected _regionLabel: string
    ) {
    }

    get regionCode(): string {
        return this._regionCode;
    }

    get regionName(): string {
        return this._regionName;
    }

    get regionLabel(): string {
        return this._regionLabel;
    }
}
