import React, { PureComponent, ReactNode } from 'react'
import { Box, Typography } from "@mui/material";

interface Props {
    title: string;
    value: string | undefined;
    type?: 'CURRENCY' | 'DEFAULT';
}

export class StatisticsCard extends PureComponent<Props> {

    static defaultProps = {
        type: 'DEFAULT'
    };

    render(): ReactNode {

        return (
            <Box className={'statistics-grid card card-text'}>
                <Typography>{this.formatCurrency(this.props.value)}</Typography>
                <Typography component={'span'}>
                    {this.props.title}
                </Typography>
            </Box>
        )
    }

    formatCurrency(value: string | undefined): number | string {
        if (value) {
            let options: object;
            switch (this.props.type) {
                case'CURRENCY':
                    options = { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 };
                    break
                case "DEFAULT":
                    options = { style: 'decimal', maximumFractionDigits: 0 };
                    break
                default:
                    options = { style: 'decimal', maximumFractionDigits: 0 };
                    break
            }
            const numberFormat = new Intl.NumberFormat('fr-FR', options);

            return numberFormat.format(parseInt(value)).replace(/\s/g, '  ');
        }
        else
            return ''
    }
}
