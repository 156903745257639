import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../Configuration/I18n";
import { Box } from "@mui/material";
import { LoginTextField } from "../Form/LoginTextField";
import { LoginSubmitButton } from "../Form/Button";

interface Props {
    email: string;
    onPressBack: () => void;
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
    login: (email: string, code: string) => void;
    taskSucceeded: () => void;
}

interface State {
    password: string;
    errorMsg: string | undefined;
}

export class SignInContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            password: '',
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.error === '400' && this.props.error !== prevProps.error) {
            this.setState({ errorMsg: i18n.authentication.sign_in_error_400 })
        }
        else if (this.props.error === '401' && this.props.error !== prevProps.error) {
            this.setState({ errorMsg: i18n.authentication.code_error })
        }
        else if (this.props.success && this.props.success !== prevProps.success) {
            this.props.taskSucceeded()
        }

    }

    render(): ReactNode {
        return (
            <Box component="form" noValidate>
                <LoginTextField margin="normal"
                                fullWidth disabled
                                value={this.props.email}/>

                <LoginTextField margin="normal"
                                fullWidth
                                value={this.state.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                    password: event.target.value,
                                    errorMsg: undefined
                                })}
                                label={i18n.authentication.password}
                                type="password"
                                autoComplete="current-password"
                                error={this.state.errorMsg !== undefined}
                                helperText={this.state.errorMsg}/>

                <LoginSubmitButton title={i18n.authentication.sign_in}
                                   loading={this.props.loading}
                                   onClick={() => this.loginUser()}/>
            </Box>
        )
    }

    loginUser(): void {
        if (this.state.password.length === 8)
            this.props.login(this.props.email, this.state.password)
        else if (this.state.password.length === 0)
            this.setState({ errorMsg: i18n.authentication.empty_code_error })
        else
            this.setState({ errorMsg: i18n.authentication.code_error })
    }
}
