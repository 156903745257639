import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs/index";
import { UPDATE_MCQ_QUESTION, UpdateMCQQuestionAction } from "./actionTypes";

import {
    updateMCQQuestionFailed,
    updateMCQQuestionSucceeded
} from "./actions";
import { HandleAdventureState } from "../../../Config/State";
import { AdventureService } from "../../../Domain/Gateway/Adventure.service";

export const updateMCQQuestionEpic: Epic = (action$: ActionsObservable<UpdateMCQQuestionAction>,
                                            store: StateObservable<HandleAdventureState>,
                                            { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(UPDATE_MCQ_QUESTION),
        switchMap((action) => adventureService.updateMCQQuestion(action.payload.question, action.payload.mcqId)
            .pipe(
                map(() => updateMCQQuestionSucceeded()
                ),
                catchError(error => of(updateMCQQuestionFailed(error)))
            )
        ))
