import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs/index";
import {
    CREATE_MCQ_QUESTION,
    CreateMCQQuestionAction,
} from "./actionTypes";

import {
    createMCQQuestionFailed,
    createMCQQuestionSucceeded,
} from "./actions";
import { HandleAdventureState } from "../../../Config/State";
import { AdventureService } from "../../../Domain/Gateway/Adventure.service";

export const createMCQQuestionEpic: Epic = (action$: ActionsObservable<CreateMCQQuestionAction>,
                                            store: StateObservable<HandleAdventureState>,
                                            { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(CREATE_MCQ_QUESTION),
        switchMap((action) => adventureService.createMCQQuestion(action.payload.question, action.payload.mcqId)
            .pipe(
                map(() => createMCQQuestionSucceeded()
                ),
                catchError(error => of(createMCQQuestionFailed(error)))
            )
        ))
