import { MCQQuestion } from "../Entity/MCQQuestion";
import { MCQ } from "../Entity/MCQ";

export class MCQBuilder {
    protected _id: string
    protected _adventureId: string
    protected _title: string
    protected _questions: MCQQuestion[]
    protected _reward: number


    withId(value: string): MCQBuilder {
        this._id = value
        return this
    }

    withAdventureId(value: string): MCQBuilder {
        this._adventureId = value
        return this
    }

    withTitle(value: string): MCQBuilder {
        this._title = value
        return this
    }

    withQuestions(value: MCQQuestion[]): MCQBuilder {
        this._questions = value
        return this
    }

    withReward(value: number): MCQBuilder {
        this._reward = value
        return this
    }

    fromMCQ(mcq: MCQ): MCQBuilder {
        this._id = mcq.id
        this._title = mcq.title
        this._adventureId = mcq.adventureId
        this._reward = mcq.reward
        this._questions = mcq.questions
        return this
    }

    build(): MCQ {
        return new MCQ(
            this._id,
            this._adventureId,
            this._title,
            this._questions,
            this._reward
        )
    }
}