import {
    DELETE_MCQ_QUESTION,
    DELETE_MCQ_QUESTION_FAILED,
    DELETE_MCQ_QUESTION_SUCCEEDED,
    DeleteMCQQuestionAction, DeleteMCQQuestionFailedAction, DeleteMCQQuestionSucceededAction,
} from "./actionTypes";

export const deleteMCQQuestion = (questionId: number, mcqId: string): DeleteMCQQuestionAction => ({
    type   : DELETE_MCQ_QUESTION,
    payload: { questionId, mcqId }
})

export const deleteMCQQuestionFailed = (error: string): DeleteMCQQuestionFailedAction => ({
    type   : DELETE_MCQ_QUESTION_FAILED,
    payload: error
})

export const deleteMCQQuestionSucceeded = (): DeleteMCQQuestionSucceededAction => ({
    type: DELETE_MCQ_QUESTION_SUCCEEDED
})