import { CartesianData } from './CartesianData';
import { MultivariateData } from "./MultivariateData";

export class AdventureData {
    constructor(
        protected _players: CartesianData[],
        protected _agencies: CartesianData[],
        protected _playersByRegion: MultivariateData[],
        protected _agenciesByRegion: MultivariateData[],
        protected _totalTurnover: number,
        protected _totalTurnoverGoal: number,
        protected _totalActivatedClient: number,
        protected _totalActivatedClientGoal: number

    ) {
    }

    get totalTurnover(): number {
        return this._totalTurnover;
    }

    get totalTurnoverGoal(): number {
        return this._totalTurnoverGoal;
    }

    get totalActivatedClient(): number {
        return this._totalActivatedClient;
    }

    get totalActivatedClientGoal(): number {
        return this._totalActivatedClientGoal;
    }

    get players(): CartesianData[] {
        return this._players;
    }

    get agencies(): CartesianData[] {
        return this._agencies;
    }

    get playersByRegion(): MultivariateData[] {
        return this._playersByRegion;
    }

    get agenciesByRegion(): MultivariateData[] {
        return this._agenciesByRegion;
    }
}
