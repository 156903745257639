import { combineReducers, Reducer } from 'redux';
import { StatisticsState } from "./State";
import { getLoginStatsReducer } from "../Usecases/GetLoginStats/reducer";
import { getAvatarStatsReducer } from "../Usecases/GetAvatarStats/reducer";
import { getMcqStatsReducer } from "../Usecases/GetMcqStats/reducer";
import { getSpeedQuizQuestionStatsReducer } from "../Usecases/GetSpeedQuizQuestionStats/reducer";
import { getAdventureStatsReducer } from "../Usecases/GetAdventureStats/reducer";
import { getSessionStatsReducer } from "../Usecases/GetSessionStats/reducer";
import { getSpeedQuizResponseStatsReducer } from "../Usecases/GetSpeedQuizResponseStats/reducer";
import { getMessageStatsReducer } from "../Usecases/GetMessageStats/reducer";
import { getAdventureSubscriptionStatsReducer } from "../Usecases/GetAdventureSubscriptionStats/reducer";
import { getEmployeeStatsReducer } from "../Usecases/GetEmployeeStats/reducer";
import { getBadgesStatsReducer } from "../Usecases/GetBadgesStats/reducer";
import { getPageStatsReducer } from "../Usecases/GetPageStats/reducer";
import { getSpeedQuizPlayerStatsReducer } from "../Usecases/GetSpeedQuizPlayerStats/reducer";
import { getSpeedQuizPlayerCountReducer } from "../Usecases/GetSpeedQuizPlayerCount/reducer";

export const statisticsRootReducer: Reducer = combineReducers<StatisticsState>({
    loginStats              : getLoginStatsReducer,
    sessionStats            : getSessionStatsReducer,
    avatarStats             : getAvatarStatsReducer,
    mcqStats                : getMcqStatsReducer,
    speedQuizStats          : getSpeedQuizQuestionStatsReducer,
    adventureStats          : getAdventureStatsReducer,
    speedQuizResponseStats  : getSpeedQuizResponseStatsReducer,
    getMessageStats         : getMessageStatsReducer,
    getAdventureSubscription: getAdventureSubscriptionStatsReducer,
    getEmployeeStats        : getEmployeeStatsReducer,
    getBadgesStats          : getBadgesStatsReducer,
    getPageStats            : getPageStatsReducer,
    getSpeedQuizPlayerStats : getSpeedQuizPlayerStatsReducer,
    speedQuizPlayerCount    : getSpeedQuizPlayerCountReducer
})
