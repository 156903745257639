import { UPDATE_AGENCY, UPDATE_AGENCY_FAILED, UPDATE_AGENCY_SUCCEEDED, UpdateAgencyActionTypes } from "./actionTypes";
import { UpdateAgencyState } from "../../Config/State";

const initialState: UpdateAgencyState = {
    loading: false,
    error  : undefined
}
export const updateAgencyReducer = (state = initialState, action: UpdateAgencyActionTypes): UpdateAgencyState => {
    switch (action.type) {
        case UPDATE_AGENCY:
            return { loading: true, error: undefined }
        case UPDATE_AGENCY_FAILED:
            return { loading: false, error: action.payload }
        case UPDATE_AGENCY_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state
    }
}