import { CartesianData } from './CartesianData'

export class SerieData {

    constructor(
        protected _label: string,
        protected _series: CartesianData[]
    ) {
    }

    get label(): string {
        return this._label;
    }

    get series(): CartesianData[] {
        return this._series;
    }

    accumulatedData(): number {
        return this._series.reduce((previousValue, data) => previousValue + data.value, 0)
    }

    correctAnswers(): string {

        const foundData = this._series.find(data => data.label === 'VALID');
        return foundData && foundData.value !== 0 ? Math.round((foundData.value / this.accumulatedData()) * 100) + '%' : '0%'
    }

    wrongAnswers(): string {
        const foundData = this._series.find(data => data.label === 'INVALID');
        return foundData && foundData.value !== 0 ? Math.round((foundData.value / this.accumulatedData()) * 100) + '%' : '0%'
    }

    unAnswered(): string {
        const foundData = this._series.find(data => data.label === 'UNANSWERED');
        return foundData && foundData.value !== 0 ? Math.round((foundData.value / this.accumulatedData()) * 100) + '%' : '0%'
    }
}
