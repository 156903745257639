import React, { PureComponent } from "react";
import { Box } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { Message } from "../../../Domain/Entity/Message";
import { match, RouteComponentProps } from "react-router";
import { MessageForm } from "../Components/MessageForm";

interface Props extends RouteComponentProps{
    success: boolean | null;
    messages: Message[] | null;
    loadMessages: () => void;
    updateMessage: (message: Message) => void;
    match: match<{ id: string }>
    setTopBarTitle: (title: string) => void
}

interface State {
    message: Message | null
}

export class UpdateMessageContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            message: null
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.messages && !state.message) {
            const message: Message | undefined = props.messages.find((item: Message) => item.id === props.match.params.id)
            if (message)
                return {
                    message
                }
        }
        return null
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.message.message_title)
        if (!this.props.messages)
            this.props.loadMessages()
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.success && this.props.success !== prevProps.success)
            this.props.history.push("/message-list");
    }

    render() {
        const title = this.state.message ? this.state.message.title : ''
        return (
            <Box>
                <PageTitle title={i18n.message.update_message + title}/>

                <MessageForm message={this.state.message}
                             submitMessage={(message: Message) => this.props.updateMessage(message)}/>
            </Box>
        )
    }
}
