import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import {
   GET_SPEED_QUIZ_PLAYER_STATS,
    GetSpeedQuizPlayerStatsAction,
} from "./actionTypes";

import { CartesianData } from "../../Domain/Entity/CartesianData";
import { getSpeedQuizPlayerStatsFailed, getSpeedQuizPlayerStatsSucceeded } from "./actions";

export const getSpeedQuizPlayerStatsEpic: Epic = (action$: ActionsObservable<GetSpeedQuizPlayerStatsAction>,
                                                     store: StateObservable<GetAppStatsState>,
                                                     { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_SPEED_QUIZ_PLAYER_STATS),
        switchMap((action) => statisticsService.getSpeedQuizPlayerStats(action.payload)
            .pipe(
                map((stats: CartesianData[]) => getSpeedQuizPlayerStatsSucceeded(stats)),
                catchError(error => of(getSpeedQuizPlayerStatsFailed(error)))
            )
        )
    )
