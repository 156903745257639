import {
    GET_MESSAGE_STATS,
    GET_MESSAGE_STATS_FAILED, GET_MESSAGE_STATS_SUCCEEDED,
    GetMessageStatsAction,
    GetMessageStatsFailedAction, GetMessageStatsSucceededAction
} from "./actionTypes";
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getMessageStats = (messageId: string): GetMessageStatsAction => ({
    type   : GET_MESSAGE_STATS,
    payload: messageId
})

export const getMessageStatsFailed = (error: string): GetMessageStatsFailedAction => ({
    type   : GET_MESSAGE_STATS_FAILED,
    payload: error
})

export const getMessageStatsSucceeded = (data: CartesianData[]): GetMessageStatsSucceededAction => ({
    type   : GET_MESSAGE_STATS_SUCCEEDED,
    payload: data
})