import { SpeedQuizStatusType } from "../SpeedQuiz.type";

export class SpeedQuizHeader {
    constructor(
        protected _id: string,
        protected _name: string,
        protected _status: SpeedQuizStatusType
    ){}

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get status(): SpeedQuizStatusType {
        return this._status;
    }
}
