import { EmployeeService } from "../../../Domain/Gateway/Employee.service";
import { Observable, throwError } from "rxjs";
import { Employee } from "../../../Domain/Entities/Employee";
import { catchError, map } from "rxjs/operators";
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { EmployeeDto } from "./DTO/EmployeeDto";
import { EmployeeMapper } from "./Mapper/EmployeeMapper";

export class ApiEmployeeService implements EmployeeService {

    private headers: object = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept'      : 'application/json'
    }

    getEmployeeList(): Observable<Employee[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/employee'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: EmployeeDto[] }>(url)
            .pipe(
                map((response: { data: EmployeeDto[] }) => EmployeeMapper.mapEmployeeDtoToEmployee(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addEmployee(employee: Employee): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/employee'
        const body = {
            email        : employee.email,
            first_name   : employee.firstName,
            last_name    : employee.lastName,
            job          : employee.job,
            qualification: employee.qualification,
            agency_code  : employee.agency.agencyCode
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    updateEmployee(employee: Employee): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/employee/update'
        const body = {
            email        : employee.email,
            first_name   : employee.firstName,
            last_name    : employee.lastName,
            job          : employee.job,
            qualification: employee.qualification,
            agency_code  : employee.agency.agencyCode,
            active       : employee.active
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }
}