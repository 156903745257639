export const SpeedQuizI18n = {
    speed_quiz_list  : 'Liste des Speed Quiz',
    add_speed_quiz   : 'Ajouter un Speed Quiz',
    update_speed_quiz: 'Modifier un Speed Quiz',
    speed_quiz       : 'Speed Quiz',
    speed_quiz_title : 'Mission Speed Quiz',
    category         : 'Catégorie des questions',
    step             : 'Step',
    save             : 'Enregistrer',
    round_1          : (date: string) => `Manche 1  ${date}`,
    round_2          : (date: string) => `Manche 2  ${date}`,
    round_3          : (date: string) => `Manche 3  ${date}`,
    question         : 'Question',
    question_1       : 'Question 1',
    question_2       : 'Question 2',
    question_3       : 'Question 3',
    answer           : 'Réponse',
    answer_1         : 'Réponse 1',
    answer_2         : 'Réponse 2',
    answer_3         : 'Réponse 3',
    answer_4         : 'Réponse 4',
    good_answer      : 'Bonne réponse',
    bad_answer       : 'Mauvaise réponse',
    title            : 'Titre',
    start_date       : 'Date de début',
    end_date         : 'Date de fin',
    status           : 'Statut',
    actions          : 'Actions',
    no_speed_quiz    : 'Pas de speed quiz',
    all              : 'Tous',
    new              : 'Nouveau',
    in_progress      : 'En cours',
    finished         : 'Terminé',
    search           : 'chercher un speed quiz',
    empty_field_error: 'Veuillez vérifier tous les champs',
}
