import { Employee } from "../../Domain/Entities/Employee";

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED'
export const UPDATE_EMPLOYEE_SUCCEEDED = 'UPDATE_EMPLOYEE_SUCCEEDED'

export interface UpdateEmployeeAction {
    type: typeof UPDATE_EMPLOYEE
    payload: Employee
}

export interface UpdateEmployeeFailedAction {
    type: typeof UPDATE_EMPLOYEE_FAILED
    payload: string
}

export interface UpdateEmployeeSucceededAction {
    type: typeof UPDATE_EMPLOYEE_SUCCEEDED
}

export type UpdateEmployeeActionTypes =
    UpdateEmployeeAction
    | UpdateEmployeeFailedAction
    | UpdateEmployeeSucceededAction