import { GetSerieStatsState } from "../../Config/State";
import {
    GET_MCQ_STATS,
    GET_MCQ_STATS_FAILED,
    GET_MCQ_STATS_SUCCEEDED,
    GetMcqStatsActionTypes
} from './actionTypes';

const initialState: GetSerieStatsState = {
    loading: false,
    stats  : null,
    error  : undefined
}

export const getMcqStatsReducer = (state = initialState, action: GetMcqStatsActionTypes): GetSerieStatsState => {
    switch (action.type) {
        case GET_MCQ_STATS:
            return {
                loading: true,
                stats  : null,
                error  : undefined
            }
        case GET_MCQ_STATS_SUCCEEDED:
            return {
                loading: false,
                stats  : action.payload,
                error  : undefined
            }
        case GET_MCQ_STATS_FAILED:
            return {
                loading: false,
                stats  : null,
                error  : action.payload
            }
        default:
            return state
    }
}
