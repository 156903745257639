import { Employee } from "../../Domain/Entities/Employee";

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED'
export const ADD_EMPLOYEE_SUCCEEDED = 'ADD_EMPLOYEE_SUCCEEDED'

export interface AddEmployeeAction {
    type: typeof ADD_EMPLOYEE
    payload: Employee
}

export interface AddEmployeeFailedAction {
    type: typeof ADD_EMPLOYEE_FAILED
    payload: string
}

export interface AddEmployeeSucceededAction {
    type: typeof ADD_EMPLOYEE_SUCCEEDED
}

export type AddEmployeeActionTypes = AddEmployeeAction | AddEmployeeFailedAction | AddEmployeeSucceededAction