import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router";

interface Props {
    path: string;
    color?: 'default' | 'primary'
}

export const EditButton = (props: Props) => {

    const history = useHistory();
    const color = props.color ? props.color : 'default'
    return (
        <IconButton aria-label="edit" color={color} onClick={() => history.push(props.path)}>
            <EditIcon/>
        </IconButton>
    )
};
