export const EmployeeI18n = {
    email                  : 'Email',
    first_name             : 'Prénom',
    last_name              : 'Nom',
    qualification          : 'Type',
    agency                 : 'Agence',
    region                 : 'Region',
    actions                : 'Actions',
    title_employee_list    : 'Liste des employés',
    no_employee            : 'Pas d\'employés',
    add_employee           : 'Ajouter un employé',
    employee               : 'Employé',
    job                    : 'Fonction',
    save                   : 'Enregistrer',
    error_handle_employee  : 'Une erreur est survenue, veuillez vérifier les données saisies',
    employee_already_exist : 'Employé existe déja.',
    success_add_employee   : 'Employé ajouté avec succès',
    update_employee        : (fullName: string) => `Modifier l’employé : ${fullName}`,
    success_update_employee: 'Employé modifié avec succès',
    active                 : 'Activée',
    inactive               : 'Désactivé',
    search_employee        : 'Recherche des employés',
    status                 : 'Statut',
    all                    : 'Tous',
    cancel                 : 'Annuler',
    employee_title         : 'Gestion des employés'
}
