import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AgencyListContainer } from "./AgencyList.container";
import { Agency } from "../../../Domain/Entities/Agency";
import { agencyListSelector, getAgencyListLoadingSelector } from "../../../Usecases/GetAgencyList/selector";
import { GetAgencyListActionTypes } from "../../../Usecases/GetAgencyList/actionTypes";
import { getAgencyList } from "../../../Usecases/GetAgencyList/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { AppState } from "../../../../Redux-configuration/AppState";

interface StateToPropsType {
    loading: boolean
    agencies: Agency[] | null;
}

interface DispatchToPropsType {
    loadAgencies: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading : getAgencyListLoadingSelector(state),
    agencies: agencyListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAgencies  : (): GetAgencyListActionTypes => dispatch(getAgencyList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const AgencyListPage = connect(mapStateToProps, mapDispatchToProps)(AgencyListContainer)
