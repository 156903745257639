import { SpeedQuizRound } from "../Entities/SpeedQuizRound";
import { SpeedQuiz } from "../Entities/SpeedQuiz";

export class SpeedQuizBuilder {
    protected _id: string
    protected _label: string
    protected _endDate: Date
    protected _rounds: SpeedQuizRound[]

    withId(value: string): SpeedQuizBuilder {
        this._id = value
        return this
    }

    withLabel(value: string): SpeedQuizBuilder {
        this._label = value
        return this
    }

    withEndDate(value: Date): SpeedQuizBuilder {
        this._endDate = value
        return this
    }

    withRounds(value: SpeedQuizRound[]): SpeedQuizBuilder {
        this._rounds = value
        return this
    }

    build(): SpeedQuiz {
        return new SpeedQuiz(this._id, this._label, this._endDate, this._rounds)
    }
}