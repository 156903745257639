export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED'
export const USER_IS_NOT_AUTHENTICATED = 'USER_IS_NOT_AUTHENTICATED'

export interface AuthenticateUserAction {
    type: typeof AUTHENTICATE_USER;
}

interface UserIsAuthenticatedAction {
    type: typeof USER_IS_AUTHENTICATED;
    payload: string
}

interface UserIsNotAuthenticatedAction {
    type: typeof USER_IS_NOT_AUTHENTICATED;
}

export type AuthenticateUserActionTypes =
    AuthenticateUserAction
    | UserIsNotAuthenticatedAction
    | UserIsAuthenticatedAction