import {
    LOAD_ADVENTURE_MCQ,
    LOAD_ADVENTURE_MCQ_FAILED, LOAD_ADVENTURE_MCQ_SUCCEEDED,
    LoadAdventureMCQAction,
    LoadAdventureMCQFailedAction, LoadAdventureMCQSucceededAction
} from "./actionTypes";
import { MCQ } from "../../Domain/Entity/MCQ";

export const loadAdventureMCQ = (adventureId: string): LoadAdventureMCQAction => ({
    type   : LOAD_ADVENTURE_MCQ,
    payload: adventureId
})

export const loadAdventureMCQFailed = (error: string): LoadAdventureMCQFailedAction => ({
    type   : LOAD_ADVENTURE_MCQ_FAILED,
    payload: error
})

export const loadAdventureMCQSucceeded = (mcq: MCQ): LoadAdventureMCQSucceededAction => ({
    type   : LOAD_ADVENTURE_MCQ_SUCCEEDED,
    payload: mcq
})