import { combineReducers, Reducer } from 'redux';
import { AuthenticationState } from './State';
import { requestPasswordReducer } from '../Usecases/RequestPassword/reducer';
import { authenticateUserReducer } from '../Usecases/AuthenticateUser/reducer';
import { loginUserReducer } from '../Usecases/Login/reducer';
import { logoutUserReducer } from "../Usecases/Logout/reducer";

export const authenticationRootReducer: Reducer = combineReducers<AuthenticationState>({
    requestPassword : requestPasswordReducer,
    login           : loginUserReducer,
    logout          : logoutUserReducer,
    authenticateUser: authenticateUserReducer
})
