import { from, Observable } from 'rxjs';
import { AuthenticationRepository } from '../../../Domain/Gateway/Authentication.repository';

export class LocalStorageAuthenticationRepository implements AuthenticationRepository {

    saveAuthenticationToken(token: string): Observable<void> {
        const saveTokenPromise = new Promise<void>((resolve) => {
            localStorage.setItem('token', token)
            resolve(void 0)
        })
        return from(saveTokenPromise)
    }

    getAuthenticationToken(): Observable<string> {
        const getTokenPromise = new Promise<string>((resolve) => {
            const token = localStorage.getItem('token')
            if (typeof token === "string")
                resolve(token)
            else
                resolve('TOKEN_NOT_FOUND')
        })
        return from(getTokenPromise)
    }

    removeAuthenticationToken(): Observable<void> {
        const removeTokenPromise = new Promise<void>((resolve) => {
            localStorage.removeItem('token')
            resolve()
        })
        return from(removeTokenPromise)
    }

}
