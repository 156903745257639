import React, { PureComponent } from "react";
import { Box, CircularProgress } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { Message } from "../../../Domain/Entity/Message";
import { PageTitleWithButton } from "../../../../Common/Adapters/Primaries/Components/PageTitleWithButton";
import { MessageListTable } from "./Components/Table";

interface Props {
    loading: boolean
    messages: Message[] | null;
    loadMessages: () => void;
    setTopBarTitle: (title: string) => void
}

export class MessageListContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.setTopBarTitle(i18n.message.message_title)
        if (!this.props.messages)
            this.props.loadMessages()
    }

    render() {
        return (
            <Box>
                <PageTitleWithButton title={i18n.message.message_list}
                                     button={'+ ' + i18n.message.add_message}
                                     path={'/add-message'}/>


                {this.props.loading &&
                <Box className={'page-loading'}>
                    <CircularProgress/>
                </Box>
                }

                {this.props.messages &&
                <MessageListTable messages={this.props.messages.map(message => message.formatDate())}/>}
            </Box>
        )
    }

}
