import React from "react";
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
    loading: boolean;
    text: string;
}

export const StatisticsSpinner = (props: Props) => {
    if (props.loading)
        return (
            <Box sx={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                <CircularProgress/>
            </Box>
        )
    else
        return (
            <Box sx={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                <Typography component="span" className={'empty-page-text'}>{props.text}</Typography>
            </Box>
        )
};
