import React, { PureComponent, ReactNode } from "react";
import { Box, CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { DataGrid, GridCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Employee } from "../../../Domain/Entities/Employee";
import SearchIcon from '@mui/icons-material/Search';
import { i18n } from "../../../../Configuration/I18n";
import { EnabledTag } from "../../../../Common/Adapters/Primaries/Components/EnabledTag";
import { DisabledTag } from "../../../../Common/Adapters/Primaries/Components/DisabledTag";
import { EditButton } from "../../../../Common/Adapters/Primaries/Components/EditButton";
import { PageTitleWithButton } from "../../../../Common/Adapters/Primaries/Components/PageTitleWithButton";
import { EmptyTableText } from "../../../../Common/Adapters/Primaries/Components/EmptyTableText";

interface Props {
    employees: Employee[] | null
    getEmployeeList: () => void;
    loading: boolean
    setTopBarTitle: (title: string) => void
}

interface DataTableEmployeeType {
    id: string
    email: string,
    first_name: string
    last_name: string
    qualification: string
    agency: string
    region: string
    active: boolean
}

interface State {
    searchValue: string
    employees: Employee[] | null
    active: string
}

export class EmployeeListContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            employees  : null,
            searchValue: '',
            active     : 'all'
        }
    }

    componentDidMount() {
        this.props.getEmployeeList()
        this.props.setTopBarTitle(i18n.employee.employee_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.employees !== this.props.employees && this.props.employees !== null)
            this.setState({ employees: this.props.employees })
    }

    render(): ReactNode {
        const columns = [
            { field: 'email', headerName: i18n.employee.email, flex: 1 },
            { field: 'first_name', headerName: i18n.employee.first_name, flex: 0.5 },
            { field: 'last_name', headerName: i18n.employee.last_name, flex: 0.5 },
            { field: 'qualification', headerName: i18n.employee.qualification, flex: 0.5 },
            { field: 'agency', headerName: i18n.employee.agency, flex: 0.6 },
            { field: 'region', headerName: i18n.employee.region, flex: 0.5 },
            {
                field     : 'active', headerName: i18n.employee.status, flex: 0.5,
                renderCell: (params: GridCellParams) => params.value ? <EnabledTag/> : <DisabledTag/>
            },
            {
                field     : 'id',
                headerName: 'Actions',
                flex      : 0.5,
                sortable  : false,
                renderCell: (params: GridCellParams) => <EditButton path={'/employee-update/' + params.value}/>
            }
        ]

        return (
            <Box>
                <PageTitleWithButton title={i18n.employee.title_employee_list}
                                     button={i18n.employee.add_employee}
                                     path={'/employee-add'}/>

                {this.props.loading ? <CircularProgress/> : null}

                {this.props.employees &&
                <DataGrid style={{ backgroundColor: 'white' }}
                          rows={this.getDataTable(this.props.employees)}
                          columns={columns}
                          pageSize={9}
                          rowsPerPageOptions={[9]}
                          disableSelectionOnClick
                          disableColumnSelector
                          autoHeight={true}
                          disableColumnFilter
                          disableColumnMenu
                          getRowClassName={(params) => `employee-active--${params.row.active}`}
                          sx={{
                              '& .MuiDataGrid-cell:focus': {
                                  outline: 0,
                              }
                          }}
                          components={{
                              NoRowsOverlay: () => <EmptyTableText text={i18n.employee.no_employee}/>,
                              Toolbar      : () => this.CustomToolbar()
                          }}/>
                }
            </Box>
        )
    }

    getDataTable(employees: Employee[]): DataTableEmployeeType[] {
        return this.filterEmployeeList(employees).map(employee => ({
            id           : employee.email,
            email        : employee.email,
            first_name   : employee.firstName,
            last_name    : employee.lastName,
            qualification: employee.qualification,
            agency       : employee.agency.agencyName,
            region       : employee.agency.region.regionLabel,
            active       : employee.active
        }))
    }

    CustomToolbar(): JSX.Element {
        return (
            <GridToolbarContainer>
                <Box component="form"
                     noValidate
                     autoComplete="off" sx={{ width: '100%', p: 2 }}>
                    <TextField fullWidth
                               placeholder={i18n.employee.search_employee}
                               autoFocus={this.state.searchValue !== ''}
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start"><SearchIcon/></InputAdornment>)
                               }}
                               sx={{ width: 5 / 6 }}
                               value={this.state.searchValue}
                               onChange={event => this.setState({ searchValue: event.target.value })}/>

                    <TextField select
                               sx={{ width: 1 / 7, ml: 3 }}
                               label={i18n.employee.status}
                               value={this.state.active}
                               onChange={event => this.setState({ active: event.target.value })}>
                        <MenuItem key={'none'} value={'all'}>{i18n.employee.all}</MenuItem>
                        <MenuItem key={'true'} value={'true'}>{i18n.employee.active}</MenuItem>
                        <MenuItem key={'false'} value={'false'}>{i18n.employee.inactive}</MenuItem>
                    </TextField>
                </Box>
            </GridToolbarContainer>
        );
    }

    filterEmployeeList(employees: Employee[]): Employee[] {
        return employees.filter(item => {
                const statusCondition = this.state.active !== 'all' ? item.active.toString() === this.state.active : this.state.active === 'all'
                return (item.email.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
                    item.firstName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
                    item.lastName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
                    item.agency.agencyName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
                    item.agency.region.regionLabel.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) &&
                    statusCondition
            }
        )
    }
}
