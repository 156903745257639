import { SpeedQuizAnswer } from "../Entities/SpeedQuizAnswer";

export class SpeedQuizAnswerBuilder {
    protected _id: number
    protected _text: string
    protected _isCorrect: boolean

    withId(value: number): SpeedQuizAnswerBuilder {
        this._id = value
        return this
    }

    withText(value: string): SpeedQuizAnswerBuilder {
        this._text = value
        return this
    }

    withIsCorrect(value: boolean): SpeedQuizAnswerBuilder {
        this._isCorrect = value
        return this
    }

    build(): SpeedQuizAnswer{
        return new SpeedQuizAnswer(this._id, this._text, this._isCorrect)
    }
}