import React, { PureComponent } from "react";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { match, RouteComponentProps } from "react-router";
import {
    SpeedQuizAnswerType,
    SpeedQuizQuestionType,
    SpeedQuizRoundType,
    SpeedQuizType
} from "../../../Domain/SpeedQuiz.type";
import { SpeedQuiz } from "../../../Domain/Entities/SpeedQuiz";
import { SpeedQuizDetailsForm } from "../Add/Components/Details";
import { SpeedQuizAccordion } from "../Add/Components/Accordion";
import { SpeedQuizFormValidation } from "../Add/FormValidation";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { SpeedQuizQuestion } from "../../../Domain/Entities/SpeedQuizQuestion";
import { SpeedQuizAnswer } from "../../../Domain/Entities/SpeedQuizAnswer";
import { SpeedQuizBuilder } from "../../../Domain/Builder/SpeedQuiz.builder";
import { SpeedQuizQuestionBuilder } from "../../../Domain/Builder/SpeedQuizQuestion.builder";
import { SpeedQuizRoundBuilder } from "../../../Domain/Builder/SpeedQuizRound.builder";
import { SpeedQuizRound } from "../../../Domain/Entities/SpeedQuizRound";
import { SpeedQuizAnswerBuilder } from "../../../Domain/Builder/SpeedQuizAnswer.builder";

const moment = ApplicationContext.getInstance().momentJs()

interface Props extends RouteComponentProps {
    loading: boolean;
    error: string | undefined
    updateSpeedQuiz: (speedQuiz: SpeedQuiz) => void
    speedQuiz: SpeedQuiz | null
    getSpeedQuiz: (speedQuizId: string) => void
    match: match<{ id: string }>
    setTopBarTitle: (title: string) => void
}

interface State {
    title: string;
    endDate: string;
    startDate: string;
    errorMessage: string | undefined
    round_1: SpeedQuizRoundType
    round_2: SpeedQuizRoundType
    round_3: SpeedQuizRoundType
}

export class UpdateSpeedQuizContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            title       : '',
            endDate     : '',
            startDate   : '',
            errorMessage: '',
            round_1     : {
                expanded : true,
                category : '',
                startDate: '',
                questions: []
            },
            round_2     : {
                expanded : false,
                category : '',
                startDate: '',
                questions: []
            },
            round_3     : {
                expanded : false,
                category : '',
                startDate: '',
                questions: []
            }
        }
    }


    componentDidMount() {
        this.props.getSpeedQuiz(this.props.match.params.id)
        this.props.setTopBarTitle(i18n.speedQuiz.speed_quiz_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.speedQuiz && this.props.speedQuiz !== prevProps.speedQuiz) {
            this.setState({
                title  : this.props.speedQuiz.label,
                startDate: moment(this.props.speedQuiz.rounds[0].startDate).format('YYYY-MM-DD'),
                endDate: moment(this.props.speedQuiz.endDate).format('YYYY-MM-DDTHH:mm'),
                round_1: {
                    expanded : true,
                    category : this.props.speedQuiz.rounds[0].category,
                    startDate: moment(this.props.speedQuiz.rounds[0].startDate).format('YYYY-MM-DDTHH:mm'),
                    questions: this.getDerivedQuestionFromProps(this.props.speedQuiz.rounds[0].questions)
                },
                round_2: {
                    expanded : false,
                    category : this.props.speedQuiz.rounds[1].category,
                    startDate: moment(this.props.speedQuiz.rounds[1].startDate).format('YYYY-MM-DDTHH:mm'),
                    questions: this.getDerivedQuestionFromProps(this.props.speedQuiz.rounds[1].questions)
                },
                round_3: {
                    expanded : false,
                    category : this.props.speedQuiz.rounds[2].category,
                    startDate: moment(this.props.speedQuiz.rounds[2].startDate).format('YYYY-MM-DDTHH:mm'),
                    questions: this.getDerivedQuestionFromProps(this.props.speedQuiz.rounds[2].questions)
                }
            })
        }

        if (this.props.error && prevProps.error === undefined)
            this.setState({ errorMessage: this.props.error })

        if (this.props.loading === false && prevProps.loading === true && this.props.error === undefined) {
            this.props.history.push('/speed-quiz-list')
        }
    }

    getDerivedQuestionFromProps(questions: SpeedQuizQuestion[]): SpeedQuizQuestionType[] {
        return questions.map((question: SpeedQuizQuestion) => ({
            id       : question.id,
            text     : question.text,
            delay    : question.delay,
            responses: this.getDerivedResponseFromQuestion(question.answers)
        }))
    }

    getDerivedResponseFromQuestion(responses: SpeedQuizAnswer[]): SpeedQuizAnswerType[] {
        return responses.map((response: SpeedQuizAnswer) => ({
            id     : response.id,
            text   : response.text,
            correct: response.isCorrect
        }))
    }

    render() {
        return (
            <Box>
                <PageTitle title={i18n.speedQuiz.update_speed_quiz}/>

                <Box component="form" noValidate autoComplete="off">
                    <SpeedQuizDetailsForm title={this.state.title}
                                          startDate={this.state.startDate}
                                          error={this.state.errorMessage}
                                          onChangeDate={value => this.setDatesSpeedQuiz(value)}
                                          onChange={(title: string) => this.setState({ title })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_1(this.formatDate(this.state.round_1.startDate))}
                                        round={this.state.round_1}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_1: {
                                                ...this.state.round_1,
                                                [key]: value
                                            }
                                        })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_2(this.formatDate(this.state.round_2.startDate))}
                                        round={this.state.round_2}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_2: {
                                                ...this.state.round_2,
                                                [key]: value
                                            }
                                        })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_3(this.formatDate(this.state.round_3.startDate))}
                                        round={this.state.round_3}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_3: {
                                                ...this.state.round_3,
                                                [key]: value
                                            }
                                        })}/>

                    {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}

                    {this.props.loading &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress/></Box>}

                    <Button sx={{ mt: 2 }} variant="contained" onClick={(): void => this.updateSpeedQuiz()}>
                        {i18n.speedQuiz.save}
                    </Button>
                </Box>
            </Box>
        )
    }

    formatDate(date: string): string {
        if (date !== '')
            return moment(date, 'YYYY-MM-DDTHH:mm').format(' - dddd DD/MM/YYYY à HH:mm')
        return ''
    }

    updateSpeedQuiz() {
        if (SpeedQuizFormValidation.validate(this.speedQuizValues()) && this.props.speedQuiz) {
            this.setState({errorMessage: ''})
            const rounds: SpeedQuizRound[] = [
                new SpeedQuizRoundBuilder()
                    .withId(this.props.speedQuiz.rounds[0].id)
                    .withCategory(this.state.round_1.category)
                    .withStep(1)
                    .withStartDate(moment(this.state.round_1.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_1))
                    .build(),
                new SpeedQuizRoundBuilder()
                    .withId(this.props.speedQuiz.rounds[1].id)
                    .withCategory(this.state.round_2.category)
                    .withStep(2)
                    .withStartDate(moment(this.state.round_2.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_2))
                    .build(),
                new SpeedQuizRoundBuilder()
                    .withId(this.props.speedQuiz.rounds[2].id)
                    .withCategory(this.state.round_3.category)
                    .withStep(3)
                    .withStartDate(moment(this.state.round_3.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_3))
                    .build()

            ]
            this.props.updateSpeedQuiz(new SpeedQuizBuilder()
                .withId(this.props.speedQuiz.id)
                .withLabel(this.state.title)
                .withEndDate(moment(this.state.endDate, 'YYYY-MM-DDTHH:mm').format())
                .withRounds(rounds)
                .build()
            )
        }
        else
            this.setState({ errorMessage: i18n.speedQuiz.empty_field_error })
    }

    getQuestionsRound(round: SpeedQuizRoundType): SpeedQuizQuestion[] {
        return round.questions.map((question: SpeedQuizQuestionType) =>
            new SpeedQuizQuestionBuilder()
                .withId(question.id ? question.id : 0)
                .withText(question.text)
                .withDelay(question.delay)
                .withAnswers(this.getAnswersQuestion(question.responses))
                .build())
    }

    getAnswersQuestion(answers: SpeedQuizAnswerType[]): SpeedQuizAnswer[] {
        return answers.map((answer: SpeedQuizAnswerType) => new SpeedQuizAnswerBuilder()
            .withId(answer.id ? answer.id : 0)
            .withText(answer.text)
            .withIsCorrect(answer.correct)
            .build())
    }

    setDatesSpeedQuiz(value: string) {
        this.setState({
            startDate: value,
            endDate  : moment(value + 'T20:00', 'YYYY-MM-DDTHH:mm').add(4, 'day').format('YYYY-MM-DDTHH:mm'),
            round_1  : {
                ...this.state.round_1,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm')
            },
            round_2  : {
                ...this.state.round_2,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').add(2, 'day').format('YYYY-MM-DDTHH:mm')
            },
            round_3  : {
                ...this.state.round_3,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').add(4, 'day').format('YYYY-MM-DDTHH:mm')
            }
        })
    }

    speedQuizValues(): SpeedQuizType {
        return {
            label  : this.state.title,
            endDate: this.state.endDate,
            rounds : [
                this.state.round_1,
                this.state.round_2,
                this.state.round_3
            ]
        }
    }
}
