import { Message } from "../../Domain/Entity/Message";

export const LOAD_MESSAGE_LIST = 'LOAD_MESSAGE_LIST'
export const LOAD_MESSAGE_LIST_FAILED = 'LOAD_MESSAGE_LIST_FAILED'
export const LOAD_MESSAGE_LIST_SUCCEEDED = 'LOAD_MESSAGE_LIST_SUCCEEDED'

export interface LoadMessageListAction {
    type: typeof LOAD_MESSAGE_LIST;
}

interface LoadMessageListSucceededAction {
    type: typeof LOAD_MESSAGE_LIST_SUCCEEDED;
    payload: Message[];
}

interface LoadMessageListFailedAction {
    type: typeof LOAD_MESSAGE_LIST_FAILED;
    payload: string;
}

export type LoadMessageListActionTypes = LoadMessageListAction | LoadMessageListFailedAction | LoadMessageListSucceededAction
