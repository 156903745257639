import { Message } from "../Entity/Message";

export class MessageBuilder {

    protected _id: string
    protected _title: string
    protected _text: string
    protected _startDate: string
    protected _endDate: string
    protected _isEnabled: boolean

    withId(id: string): MessageBuilder {
        this._id = id
        return this
    }

    withTitle(title: string): MessageBuilder {
        this._title = title
        return this
    }

    withText(text: string): MessageBuilder {
        this._text = text
        return this
    }

    withStartDate(startDate: string): MessageBuilder {
        this._startDate = startDate
        return this
    }

    withEndDate(endDate: string): MessageBuilder {
        this._endDate = endDate
        return this
    }

    withIsEnabled(isEnabled: boolean): MessageBuilder {
        this._isEnabled = isEnabled
        return this
    }

    build(): Message {
        return new Message(
            this._id,
            this._title,
            this._text,
            this._startDate,
            this._endDate,
            this._isEnabled
        )
    }
}
