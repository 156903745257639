import React, { PureComponent, ReactNode } from 'react'
import { MCQQuestion } from "../../../../../../Domain/Entity/MCQQuestion";
import { Grid, Typography } from "@mui/material";
import { BadResponse } from "./BadResponse";
import { GoodResponse } from "./GoodResponse";
import '../../style.css'
import { EditQuestionButton } from './EditQuestionButton';

interface Props {
    question: MCQQuestion
    rank: number
    onEdit: () => void
}

export class MCQQuestionItem extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid container spacing={2} className={'question-container'}>
                <Grid item xs={12} sm={11}>
                    <Typography className={'question-title'}>
                        {`${this.props.rank}) ${this.props.question.question}`}
                    </Typography>
                    
                    <Grid container spacing={1} sx={{pb: 1}}>
                        {
                            this.props.question.responses.map((response, index) => response.isCorrect === true ?
                                <GoodResponse key={index} label={response.text}/> :
                                <BadResponse key={index} label={response.text}/>
                            )
                        }
                    </Grid>
                </Grid>
                
                <Grid item xs={12} sm={1}>
                    <EditQuestionButton onClick={() => this.props.onEdit()}/>
                </Grid>
            </Grid>
        )
    }
}