import {
    CREATE_MCQ_QUESTION,
    CREATE_MCQ_QUESTION_FAILED,
    CREATE_MCQ_QUESTION_SUCCEEDED,
    CreateMCQQuestionActionTypes
} from "./actionTypes";
import { HandleAdventureState } from "../../../Config/State";

const initialState: HandleAdventureState = {
    loading: false,
    error  : undefined
}
export const createMCQQuestionReducer = (state = initialState, action: CreateMCQQuestionActionTypes): HandleAdventureState => {
    switch (action.type) {
        case CREATE_MCQ_QUESTION:
            return { loading: true, error: undefined }
        case CREATE_MCQ_QUESTION_FAILED:
            return { loading: false, error: action.payload }
        case CREATE_MCQ_QUESTION_SUCCEEDED:
            return { loading: false, error: undefined }
        default:
            return state;
    }
}