import {
    GET_SPEED_QUIZ,
    GET_SPEED_QUIZ_FAILED,
    GET_SPEED_QUIZ_SUCCEEDED,
    GetSpeedQuizActionTypes
} from "./actionTypes";
import { GetSpeedQuizState } from "../../Config/State";

const initialState: GetSpeedQuizState = {
    loading  : false,
    error    : undefined,
    speedQuiz: null
}
export const getSpeedQuizReducer = (state = initialState, action: GetSpeedQuizActionTypes): GetSpeedQuizState => {
    switch (action.type) {
        case GET_SPEED_QUIZ:
            return { loading: true, speedQuiz: null, error: undefined }
        case GET_SPEED_QUIZ_FAILED:
            return { loading: false, speedQuiz: null, error: action.payload }
        case GET_SPEED_QUIZ_SUCCEEDED:
            return { loading: false, speedQuiz: action.payload, error: undefined }
        default:
            return state
    }
}