import { Employee } from "../../../Domain/Entities/Employee";
import { AppState } from "../../../../Redux-configuration/AppState";
import { employeeListSelector, getEmployeeListLoadingSelector } from "../../../Usecases/GetEmployeeList/selectors";
import { Dispatch } from "redux";
import { GetEmployeeListAction } from "../../../Usecases/GetEmployeeList/actionTypes";
import { getEmployeeList } from "../../../Usecases/GetEmployeeList/actions";
import { connect } from "react-redux";
import { EmployeeListContainer } from "./EmployeeList.container";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    employees: Employee[] | null;
    loading: boolean;
}

interface DispatchToPropsType {
    getEmployeeList: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    employees: employeeListSelector(state),
    loading: getEmployeeListLoadingSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    getEmployeeList: (): GetEmployeeListAction => dispatch(getEmployeeList()),
    setTopBarTitle: (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const EmployeeListPage = connect(mapStateToProps, mapDispatchToProps)(EmployeeListContainer)