import { Region } from "../Entities/Region";
import { Agency } from "../Entities/Agency";

export class AgencyBuilder {

    protected _agencyCode: string
    protected _agencyName: string
    protected _region: Region
    protected _network: string

    withAgencyCode(code: string): AgencyBuilder {
        this._agencyCode = code
        return this
    }

    withAgencyName(name: string): AgencyBuilder {
        this._agencyName = name
        return this
    }

    withRegion(region: Region): AgencyBuilder {
        this._region = region
        return this
    }

    withNetwork(network: string): AgencyBuilder {
        this._network = network
        return this
    }

    build(): Agency {
        return new Agency(
            this._agencyCode,
            this._agencyName,
            this._region,
            this._network
        )
    }
}
