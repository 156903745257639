import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetEmployeeStatsState } from "../../Config/State";
import { GET_EMPLOYEE_STATS, GetEmployeeStatsAction } from "./actionTypes";
import {
    getEmployeeStatsFailed,
    getEmployeeStatsSucceeded
} from "./actions";
import { EmployeeData } from "../../Domain/Entity/EmployeeData";

export const getEmployeeStatsEpic: Epic = (action$: ActionsObservable<GetEmployeeStatsAction>,
                                         store: StateObservable<GetEmployeeStatsState>,
                                         { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_EMPLOYEE_STATS),
        switchMap(() => statisticsService.getEmployeeStats()
            .pipe(
                map((stats: EmployeeData) => getEmployeeStatsSucceeded(stats)),
                catchError(error => of(getEmployeeStatsFailed(error)))
            )
        )
    )
