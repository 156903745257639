import { SpeedQuizAnswer } from "./SpeedQuizAnswer";

export class SpeedQuizQuestion {
    constructor(
        protected _id: number,
        protected _text: string,
        protected _delay: number,
        protected _answers: SpeedQuizAnswer[]
    ){

    }

    get id(): number {
        return this._id;
    }

    get text(): string {
        return this._text;
    }

    get delay(): number {
        return this._delay;
    }

    get answers(): SpeedQuizAnswer[] {
        return this._answers;
    }
}