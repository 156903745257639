import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    loadAdventureMCQFailed,
    loadAdventureMCQSucceeded
} from './actions';
import { AdventureService } from "../../Domain/Gateway/Adventure.service";
import {
    LOAD_ADVENTURE_MCQ,
    LoadAdventureMCQAction
} from "./actionTypes";

import { MCQ } from "../../Domain/Entity/MCQ";
import { LoadMCQState } from "../../Config/State";

export const loadAdventureMCQEpic: Epic = (action$: ActionsObservable<LoadAdventureMCQAction>,
                                            store: StateObservable<LoadMCQState>,
                                            { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(LOAD_ADVENTURE_MCQ),
        switchMap((action) => adventureService.getAdventureMCQ(action.payload)
            .pipe(
                map((mcq: MCQ) => loadAdventureMCQSucceeded(mcq)
                ),
                catchError((error: string) => of(loadAdventureMCQFailed(error)))
            )
        )
    )
