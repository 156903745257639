import React, { PureComponent, ReactNode } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { AvatarType } from "../../../../Profile/Domain/Entities/Profile-Types";
import { Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { Logout } from "@mui/icons-material";

interface Props {
    avatar: AvatarType | undefined;
    topBarTitle: string;
    successLogout: null | boolean;
    logout: () => void;
}

interface State {
    AnchorEl: HTMLElement | null
}

export class AppTopBarContainer extends PureComponent<Props, State> {

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            AnchorEl: null
        }
    }


    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.successLogout && this.props.successLogout != prevProps.successLogout) {
            window.location.href = '/login'
        }
    }

    render(): ReactNode {
        const open = Boolean(this.state.AnchorEl);

        return (
            <AppBar position="fixed" className={'app-topBar'} elevation={0} color="inherit">
                <Toolbar>
                    <Typography className={'text'}>
                       {this.props.topBarTitle}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account settings">
                            <IconButton onClick={(event) => this.handleClick(event)} size="small" sx={{ ml: 2 }}>
                                <Avatar className={'avatar'} src={`./assets/avatars/${this.props.avatar}.png`}/>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Menu anchorEl={this.state.AnchorEl}
                          open={open}
                          onClose={() => this.handleClose()}
                          onClick={() => this.handleClose()}
                          PaperProps={{
                              elevation: 0,
                              sx       : {
                                  overflow           : 'visible',
                                  filter             : 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt                 : 1.5,
                                  '& .MuiAvatar-root': {
                                      width : 32,
                                      height: 32,
                                      ml    : -0.5,
                                      mr    : 1,
                                  },
                                  '&:before'         : {
                                      content  : '""',
                                      display  : 'block',
                                      position : 'absolute',
                                      top      : 0,
                                      right    : 14,
                                      width    : 10,
                                      height   : 10,
                                      bgcolor  : 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex   : 0,
                                  }
                              }
                          }}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                        <Divider/>

                        <MenuItem>
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            <Button variant="text" className={'logout-btn'} onClick={() => this.props.logout()}>
                                Logout
                            </Button>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        )
    }

    handleClick(event: React.MouseEvent<HTMLElement>) {
        this.setState({ AnchorEl: event.currentTarget })
    }

    handleClose() {
        this.setState({ AnchorEl: null })
    }
}
