import { MultivariateData } from "../Entity/MultivariateData";

export class MultivariateDataBuilder {
    protected _label: string
    protected _valueA: number
    protected _valueB: number

    withLabel(label: string): MultivariateDataBuilder {
        this._label = label
        return this
    }

    withValueA(valueA: number): MultivariateDataBuilder {
        this._valueA = valueA
        return this
    }

    withValueB(valueB: number): MultivariateDataBuilder {
        this._valueB = valueB
        return this
    }

    build(): MultivariateData {
        return new MultivariateData(
            this._label,
            this._valueA,
            this._valueB
        )
    }
}
