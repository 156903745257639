import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { Grid } from "@mui/material";
import { StatisticsPieChart } from "../../Component/PieChart";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { SerieData } from "../../../../Domain/Entity/SerieData";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";
import { StatisticsBarChart } from "../../Component/BarChart";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    speedQuizStats: SerieData[] | null;
    speedQuizResponseStats: CartesianData[] | null;
}

export class SpeedQuizResponseStats extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <StatisticsPieChart size={6}
                                    colors={[Theme.darkGreen, Theme.red, Theme.lightOrange]}
                                    title={i18n.statistics.title_chart_responses}
                                    info={i18n.statistics.title_chart_responses}
                                    stats={this.getResponseStats(this.props.speedQuizStats)}/>

                <StatisticsBarChart size={6}
                                    title={i18n.statistics.evolution_speedQuiz_title}
                                    legend={i18n.statistics.evolution_response_legend}
                                    stats={this.props.speedQuizResponseStats ? this.props.speedQuizResponseStats : []}/>
            </Grid>
        )
    }

    getResponseStats(data: SerieData[] | null): CartesianData[] {
        let totalCorrectAnswers = 0
        let totalBadAnswers = 0
        let totalUnAnswered = 0

        data?.map(item => {
            const foundCorrectAnswer = item.series.find(series => series.label === 'VALID');
            const foundBadAnswer = item.series.find(series => series.label === 'INVALID');
            const foundUnAnswered = item.series.find(series => series.label === 'UNANSWERED');

            if (foundCorrectAnswer)
                totalCorrectAnswers = totalCorrectAnswers + foundCorrectAnswer.value
            if (foundBadAnswer)
                totalBadAnswers = totalBadAnswers + foundBadAnswer.value
            if (foundUnAnswered)
                totalUnAnswered = totalUnAnswered + foundUnAnswered.value
        })

        return [
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.correct_answers)
                .withValue(totalCorrectAnswers)
                .build(),
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.bad_answers)
                .withValue(totalBadAnswers)
                .build(),
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.unanswered)
                .withValue(totalUnAnswered)
                .build()
        ]
    }

}
