import React from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import { useHistory } from "react-router";

interface Props {
    title: string;
    path: string;
    onClick: () => void;
}

export const SideBarListItemButton = (props: Props) => {

    const history = useHistory();

    return (
        <ListItemButton sx={{ pl: 5 }} className={'menu-item'}
                        selected={location.pathname === props.path}
                        onClick={() => {
                            history.push(props.path)
                            props.onClick()
                        }}>

            <ListItemText primary={props.title}/>
        </ListItemButton>
    )
}
