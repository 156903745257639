import {
    GET_REGION_LIST,
    GET_REGION_LIST_FAILED,
    GET_REGION_LIST_SUCCEEDED,
    GetRegionListActionTypes
} from "./actionTypes";
import { GetRegionListState } from "../../Config/State";

const initialState: GetRegionListState ={
    loading: false,
    error: undefined,
    regions: null
}
export const getRegionListReducer = (state= initialState, action: GetRegionListActionTypes):GetRegionListState =>{
    switch (action.type){
        case GET_REGION_LIST:
            return {loading: true, error: undefined, regions: null}
        case GET_REGION_LIST_FAILED:
            return {loading: false, error: action.payload, regions: null}
        case GET_REGION_LIST_SUCCEEDED:
            return {loading: false, error: undefined, regions: action.payload}
        default:
            return state
    }
}