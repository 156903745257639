import {
    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_FAILED, GET_EMPLOYEE_LIST_SUCCEEDED,
    GetEmployeeListAction,
    GetEmployeeListFailedAction, GetEmployeeListSucceededAction
} from "./actionTypes";
import { Employee } from "../../Domain/Entities/Employee";

export const getEmployeeList = (): GetEmployeeListAction => ({
    type: GET_EMPLOYEE_LIST
})

export const getEmployeeListFailed = (error: string): GetEmployeeListFailedAction => ({
    type   : GET_EMPLOYEE_LIST_FAILED,
    payload: error
})

export const getEmployeeListSucceeded = (employees: Employee[]): GetEmployeeListSucceededAction => ({
    type   : GET_EMPLOYEE_LIST_SUCCEEDED,
    payload: employees
})