import {
    GET_SPEED_QUIZ_PLAYER_COUNT,
    GET_SPEED_QUIZ_PLAYER_COUNT_FAILED,
    GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED,
    GetSpeedQuizPlayerCountAction, GetSpeedQuizPlayerCountFailedAction, GetSpeedQuizPlayerCountSucceededAction,
} from "./actionTypes";

export const getSpeedQuizPlayerCount = (speedQuizId: string): GetSpeedQuizPlayerCountAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_COUNT,
    payload: speedQuizId
})

export const getSpeedQuizPlayerCountFailed = (error: string): GetSpeedQuizPlayerCountFailedAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_COUNT_FAILED,
    payload: error
})

export const getSpeedQuizPlayerCountSucceeded = (playerCount: number): GetSpeedQuizPlayerCountSucceededAction => ({
    type   : GET_SPEED_QUIZ_PLAYER_COUNT_SUCCEEDED,
    payload: playerCount
})