import { MCQQuestion } from "./MCQQuestion";

export class MCQ {
    constructor(
        protected _id: string,
        protected _adventureId: string,
        protected _title: string,
        protected _questions: MCQQuestion[],
        protected _reward: number,

    ) {}

    get id(): string {
        return this._id;
    }

    get adventureId(): string {
        return this._adventureId;
    }

    get title(): string {
        return this._title;
    }

    get questions(): MCQQuestion[] {
        return this._questions;
    }

    get reward(): number {
        return this._reward;
    }

}