import {
    GET_AGENCY_LIST,
    GET_AGENCY_LIST_FAILED, GET_AGENCY_LIST_SUCCEEDED,
    GetAgencyListAction,
    GetAgencyListFailedAction,
    GetAgencyListSucceededAction
} from "./actionTypes";
import { Agency } from "../../Domain/Entities/Agency";

export const getAgencyList = (): GetAgencyListAction => ({
    type: GET_AGENCY_LIST
})

export const getAgencyListFailed = (error: string): GetAgencyListFailedAction => ({
    type   : GET_AGENCY_LIST_FAILED,
    payload: error
})

export const getAgencyListSucceeded = (agencies: Agency[]): GetAgencyListSucceededAction => ({
    type   : GET_AGENCY_LIST_SUCCEEDED,
    payload: agencies
})