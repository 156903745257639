import React, { PureComponent, ReactNode } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { PageNotFoundError } from "./components/pageNotFound.error";
import { LoginContainer } from "../../../Authentication/Adapters/Primaries/Login.container";
import { LayoutContainer } from "../../../Common/Adapters/Primaries/Layout.container";
import { StatisticsPage } from "../../../Statistics/Adapters/Primaries/AppStats/index";
import { AdventureStatisticsPage } from "../../../Statistics/Adapters/Primaries/Adventure";
import { SpeedQuizStatisticsPage } from "../../../Statistics/Adapters/Primaries/SpeedQuiz";
import { EmployeeListPage } from "../../../Employee/Adapters/Primaries/EmployeeList";
import { AddEmployeePage } from "../../../Employee/Adapters/Primaries/AddEmployee";
import { MessageListPage } from "../../../Message/Adapters/Primaries/List";
import { AddMessagePage } from "../../../Message/Adapters/Primaries/Add";
import { UpdateEmployeePage } from "../../../Employee/Adapters/Primaries/UpdateEmployee";
import { SpeedQuizListPage } from "../../../SpeedQuiz/Adapters/Primaries/List";
import { AdventureListPage } from "../../../Adventure/Adapters/Primaries/AdventureList";
import { AddAdventurePage } from "../../../Adventure/Adapters/Primaries/AddAdventure";
import { UpdateMessagePage } from "../../../Message/Adapters/Primaries/Update";
import { UpdateAdventurePage } from "../../../Adventure/Adapters/Primaries/UpdateAdventure";
import { AddSpeedQuizPage } from "../../../SpeedQuiz/Adapters/Primaries/Add";
import { MessageStatisticsPage } from "../../../Statistics/Adapters/Primaries/Message";
import { UpdateSpeedQuizPage } from "../../../SpeedQuiz/Adapters/Primaries/Update";
import { AgencyListPage } from "../../../Agence/Adapters/Primaries/List";
import { AddAgencyPage } from "../../../Agence/Adapters/Primaries/Add";
import { UpdateAgencyPage } from "../../../Agence/Adapters/Primaries/Update";

interface Props {
    isAuthenticated: boolean;
}

export class RootNavigationContainer extends PureComponent<Props> {
    render(): ReactNode {
        return (
            this.props.isAuthenticated ? dashboardNavigation() : loginNavigation()
        )
    }
}

function dashboardNavigation(): JSX.Element {
    return (
        <BrowserRouter>
            <LayoutContainer>
                <Switch>
                    <Route component={StatisticsPage} path="/statistics"/>

                    <Route component={AdventureStatisticsPage} path="/adventure"/>

                    <Route component={SpeedQuizStatisticsPage} path="/speed-quiz"/>

                    <Route component={MessageStatisticsPage} path="/message"/>

                    <Route component={AdventureListPage} path="/adventure-list"/>

                    <Route component={AddAdventurePage} path="/adventure-add"/>

                    <Route component={UpdateAdventurePage} path="/adventure-update/:id"/>

                    <Route component={SpeedQuizStatisticsPage} path="/speed-quiz"/>

                    <Route component={SpeedQuizListPage} path="/speed-quiz-list"/>

                    <Route component={AddSpeedQuizPage} path="/add-speed-quiz"/>

                    <Route component={UpdateSpeedQuizPage} path="/update-speed-quiz/:id"/>

                    <Route component={EmployeeListPage} path="/employee-list"/>

                    <Route component={AddEmployeePage} path="/employee-add"/>

                    <Route component={UpdateEmployeePage} path="/employee-update/:id"/>

                    <Route component={MessageListPage} path="/message-list"/>

                    <Route component={AddMessagePage} path="/add-message"/>

                    <Route component={UpdateMessagePage} path="/update-message/:id"/>

                    <Route exact path="/"> <Redirect to="/statistics"/> </Route>

                    <Route exact path="/login"> <Redirect to="/statistics"/> </Route>

                    <Route component={AgencyListPage} path="/agency-list"/>

                    <Route component={AddAgencyPage} path="/add-agency"/>

                    <Route component={UpdateAgencyPage} path="/update-agency/:code"/>

                    <Route component={PageNotFoundError}/>
                </Switch>
            </LayoutContainer>
        </BrowserRouter>
    )
}

function loginNavigation(): JSX.Element {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={LoginContainer}/>

                <Route exact path="/"> <Redirect exact to="/login"/> </Route>

                <Route component={PageNotFoundError}/>
            </Switch>
        </BrowserRouter>
    )
}
