import {
    GET_SPEED_QUIZ_RESPONSE_STATS,
    GET_SPEED_QUIZ_RESPONSE_STATS_FAILED, GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED,
    GetSpeedQuizResponseStatsAction, GetSpeedQuizResponseStatsActionTypes
} from "./actionTypes";
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getSpeedQuizResponseStats = (speedQuizId: string): GetSpeedQuizResponseStatsAction => ({
    type   : GET_SPEED_QUIZ_RESPONSE_STATS,
    payload: speedQuizId
})

export const getSpeedQuizResponseStatsFailed = (error: string): GetSpeedQuizResponseStatsActionTypes => ({
    type   : GET_SPEED_QUIZ_RESPONSE_STATS_FAILED,
    payload: error
})

export const getSpeedQuizResponseStatsSucceeded = (stats: CartesianData[]): GetSpeedQuizResponseStatsActionTypes => ({
    type   : GET_SPEED_QUIZ_RESPONSE_STATS_SUCCEEDED,
    payload: stats
})
