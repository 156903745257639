import React, { PureComponent, ReactNode } from "react";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

interface Props {
    title: string;
    icon: JSX.Element;
    isActive: boolean;
    path: string;
    children?: ReactNode;
}

interface State {
    open: boolean;
}

export class NestedListItem extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: this.props.isActive
        }
    }

    render(): ReactNode {
        return (
            <Box>
                <ListItemButton className={'nested-menu-item'}
                                selected={this.props.isActive}
                                href={this.props.path} key={this.props.path}
                                onClick={() => {
                                    if (this.props.path === '/statistics')
                                        this.setState({ open: !this.state.open })
                                    else
                                        this.setState({ open: !this.state.open }, () => {
                                            if (this.state.open)
                                                location.href = this.props.path
                                        })
                                }}>
                    <ListItemIcon>
                        {this.props.icon}
                    </ListItemIcon>

                    <ListItemText primary={this.props.title}/>
                    {this.props.path === '/statistics' ?
                        this.state.open ? <ExpandMore/> :
                            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }}/> : null}
                </ListItemButton>

                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {this.props.children}
                    </List>
                </Collapse>
            </Box>
        )
    }
}
