export const AgencyI18n = {
    add_agency          : 'Ajouter une agence',
    agency_list         : 'Liste des agences',
    agency_title        : 'Agences',
    agency              : 'L\'agence',
    agency_code         : 'Code de l\'agence',
    agency_name         : 'Nom de l\'agence',
    region_name         : 'Nom de région',
    network             : 'Réseau',
    cged                : 'CGED',
    connect             : 'CONNECT',
    save                : 'Enregistrer',
    fill_in_all_inputs  : 'Veuillez remplir tous les champs',
    no_agencies         : 'Pas des agences',
    search              : 'Chercher une agence',
    actions             : 'Actions',
    update_agency       : (name: string) => `Modifier l'agence : ${name} `,
    agency_already_exist: 'Agence existe déjà'
}
