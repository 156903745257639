import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StatisticsGridTitle } from "./Title";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { Theme } from "../../../../Common/Config/Theme";

interface Props {
    title: string;
    stats: CartesianData[];
    size: number
    legend: string
    isCurrency?: boolean;
}

export class StatisticsBarChart extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid item sm={12} lg={this.props.size}>
                <Box className={'statistics-grid'}>
                    <StatisticsGridTitle title={this.props.title}
                                         info={this.props.title}/>

                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={500}
                                  height={300}
                                  style={{ fontFamily: 'sans-serif', fontSize: 12 }}
                                  data={this.props.stats}
                                  margin={{ top: 20, right: 30, bottom: 20, left: 30 }}>
                            <CartesianGrid strokeDasharray="3 3"/>

                            <XAxis dataKey="label"/>
                            <YAxis />

                            <Tooltip formatter={(value: number) => {
                                if (this.props.isCurrency) {
                                    const options1 = { style: 'currency', currency: 'EUR' };
                                    const numberFormat = new Intl.NumberFormat('de-DE', options1);
                                    const fvalue = numberFormat.format(value)
                                    return fvalue
                                }
                                else
                                    return value

                            }}/>
                            <Legend/>

                            <Bar dataKey="value" fill={Theme.grayishBlue} barSize={20} name={this.props.legend}/>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        )
    }
}
