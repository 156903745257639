import { Agency } from "../../Domain/Entities/Agency";

export const GET_AGENCY_LIST = 'GET_AGENCY_LIST'
export const GET_AGENCY_LIST_FAILED = 'GET_AGENCY_LIST_FAILED'
export const GET_AGENCY_LIST_SUCCEEDED = 'GET_AGENCY_LIST_SUCCEEDED'

export interface GetAgencyListAction {
    type: typeof GET_AGENCY_LIST
}

export interface GetAgencyListFailedAction {
    type: typeof GET_AGENCY_LIST_FAILED
    payload: string
}

export interface GetAgencyListSucceededAction {
    type: typeof GET_AGENCY_LIST_SUCCEEDED
    payload:  Agency[]
}

export type GetAgencyListActionTypes = GetAgencyListAction | GetAgencyListFailedAction | GetAgencyListSucceededAction