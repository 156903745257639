import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { SpeedQuizListContainer } from "./SpeedQuizList.container";
import { SpeedQuizHeader } from "../../../Domain/Entities/SpeedQuizHeader";
import { GetAllSpeedQuizAction } from "../../../Usecases/GetAllSpeedQuiz/actionTypes";
import { getAllSpeedQuiz } from "../../../Usecases/GetAllSpeedQuiz/actions";
import { allSpeedQuizDataSelector } from "../../../Usecases/GetAllSpeedQuiz/selector";
import { getSpeedQuizQuestionStatsLoadingSelector } from "../../../../Statistics/Usecases/GetSpeedQuizQuestionStats/selector";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";

interface StateToPropsType {
    loading: boolean
    speedQuizList: SpeedQuizHeader[] | null;
}

interface DispatchToPropsType {
    loadSpeedQuizList: () => void;
    setTopBarTitle: (title: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : getSpeedQuizQuestionStatsLoadingSelector(state),
    speedQuizList: allSpeedQuizDataSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadSpeedQuizList: (): GetAllSpeedQuizAction => dispatch(getAllSpeedQuiz()),
    setTopBarTitle   : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title))
})

export const SpeedQuizListPage = connect(mapStateToProps, mapDispatchToProps)(SpeedQuizListContainer)
