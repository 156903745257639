import { Theme } from "../../Common/Config/Theme";

export const PieChartColors = [
    Theme.grayishBlue,
    Theme.softBlue,
    Theme.desaturatedGreen,
    Theme.lightOrange,
    Theme.verySoftBlue,
    Theme.brightBlue,
    Theme.moderateViolet,
    Theme.brightPink
]
