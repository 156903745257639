import { Region } from "../../Domain/Entities/Region";

export const GET_REGION_LIST = 'GET_REGION_LIST'
export const GET_REGION_LIST_FAILED = 'GET_REGION_LIST_FAILED'
export const GET_REGION_LIST_SUCCEEDED = 'GET_REGION_LIST_SUCCEEDED'

export interface GetRegionListAction {
    type: typeof GET_REGION_LIST
}

export interface GetRegionListFailedAction {
    type: typeof GET_REGION_LIST_FAILED
    payload: string
}

export interface GetRegionListSucceededAction {
    type: typeof GET_REGION_LIST_SUCCEEDED
    payload: Region[]
}

export type GetRegionListActionTypes = GetRegionListAction | GetRegionListFailedAction | GetRegionListSucceededAction