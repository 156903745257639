import { LocaleFr } from "./Locale.fr";
const moment = require("moment-timezone")

export class ApplicationContext {

    private static instance: ApplicationContext
    private _momentJs: typeof moment

    static getInstance(): ApplicationContext {

        if (!ApplicationContext.instance) {
            ApplicationContext.instance = new ApplicationContext()

            ApplicationContext.instance._momentJs = moment.tz.setDefault('Europe/Paris')
            ApplicationContext.instance._momentJs.updateLocale('fr', LocaleFr)

            return ApplicationContext.instance
        }

        return ApplicationContext.instance
    }

    momentJs(): typeof moment {
        return this._momentJs;
    }
}
