import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs/index";
import { ADD_ADVENTURE, AddAdventureAction } from "./actionTypes";
import { HandleAdventureState } from "../../Config/State";
import { AdventureService } from "../../Domain/Gateway/Adventure.service";
import { addAdventureFailed, addAdventureSucceeded } from "./actions";
import { MCQBuilder } from "../../Domain/Builder/MCQ.builder";

export const addAdventureEpic: Epic = (action$: ActionsObservable<AddAdventureAction>,
                                       store: StateObservable<HandleAdventureState>,
                                       { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(ADD_ADVENTURE),
        switchMap((action) => adventureService.addAdventure(action.payload.adventure)
            .pipe(
                mergeMap((adventureId: string) =>
                    adventureService.addMCQ(new MCQBuilder().fromMCQ(action.payload.mcq).withAdventureId(adventureId).build())
                        .pipe(
                            map(() => addAdventureSucceeded()),
                            catchError(error => of(addAdventureFailed(error)))
                        )
                ),
                catchError(error => of(addAdventureFailed(error)))
            )
        ))
