import { LOGOUT_USER, LOGOUT_USER_FAILED, LOGOUT_USER_SUCCEEDED } from './actionTypes';
import { LogoutUserActionTypes } from './actionTypes';
import { LoginUserState } from "../../Config/State";

const initialState: LoginUserState = {
    loading: false,
    error  : undefined,
    success: null
}
export const logoutUserReducer = (state = initialState, action: LogoutUserActionTypes): LoginUserState => {
    switch (action.type) {
        case LOGOUT_USER:
            return {
                loading: true,
                error  : undefined,
                success: null
            }
        case LOGOUT_USER_FAILED:
            return {
                loading: false,
                error  : action.payload,
                success: null
            }
        case LOGOUT_USER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                success: true
            }
        default:
            return state
    }
}