import { AuthenticationService } from '../../../Domain/Gateway/Authentication.service';
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { ObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/ObservableAjax.httpClient";

export class APIAuthenticationService implements AuthenticationService {
    private headers: object = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept'      : 'application/json'
    }

    requestPassword(email: string): Observable<void> {
        const URL = process.env.REACT_APP_API_URL + '/v1/request-password'
        const body = { email }
        return new ObservableAjaxHttpClient().post(URL, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    signInUser(email: string, password: string): Observable<string> {
        const URL = process.env.REACT_APP_API_URL + '/v1/signin'
        const body = { email, password, adminAuthentication : true }
        return new ObservableAjaxHttpClient().post(URL, body, this.headers)
            .pipe(
                map(response => response.response.data.token),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
