import { combineReducers, Reducer } from 'redux';
import { MessageState } from "./State";
import { addMessageReducer } from "../Usecases/AddMessage/reducer";
import { loadMessageListReducer } from "../Usecases/List/reducer";
import { updateMessageReducer } from "../Usecases/Update/reducer";

export const messageRootReducer: Reducer = combineReducers<MessageState>({
    list         : loadMessageListReducer,
    addMessage   : addMessageReducer,
    updateMessage: updateMessageReducer
})
