import {
    LOAD_ADVENTURE_MCQ,
    LOAD_ADVENTURE_MCQ_FAILED,
    LOAD_ADVENTURE_MCQ_SUCCEEDED,
    LoadAdventureMCQActionTypes
} from "./actionTypes";
import { LoadMCQState } from "../../Config/State";

const initialState: LoadMCQState = {
    loading: false,
    error  : undefined,
    mcq    : null
}
export const loadAdventureMCQReducer = (state = initialState, action: LoadAdventureMCQActionTypes): LoadMCQState => {
    switch (action.type) {
        case LOAD_ADVENTURE_MCQ:
            return { loading: true, error: undefined, mcq: null }
        case LOAD_ADVENTURE_MCQ_FAILED:
            return { loading: false, error: action.payload, mcq: null }
        case LOAD_ADVENTURE_MCQ_SUCCEEDED:
            return { loading: false, error: undefined, mcq: action.payload }
        default:
            return state
    }
}