import { MCQResponse } from "../Entity/MCQResponse";
import { MCQQuestion } from "../Entity/MCQQuestion";

export class MCQQuestionBuilder {
    protected _id: number
    protected _text: string
    protected _description: string
    protected _picture: File | undefined
    protected _pictureName: string
    protected _responses: MCQResponse[]

    withId(value: number): MCQQuestionBuilder {
        this._id = value
        return this
    }

    withText(value: string): MCQQuestionBuilder {
        this._text = value
        return this
    }

    withDescription(value: string): MCQQuestionBuilder {
        this._description = value
        return this
    }

    withPicture(value: File | undefined): MCQQuestionBuilder {
        this._picture = value
        return this
    }

    withPictureName(value: string): MCQQuestionBuilder {
        this._pictureName = value
        return this
    }

    withResponses(value: MCQResponse[]): MCQQuestionBuilder {
        this._responses = value
        return this
    }

    fromMCQQuestion(question: MCQQuestion): MCQQuestionBuilder {
        this._id = question.id
        this._pictureName = question.pictureName
        this._picture = question.picture
        this._text = question.question
        this._responses = question.responses
        this._description = question.description
        return this
    }

    build(): MCQQuestion {
        return new MCQQuestion(
            this._id,
            this._text,
            this._description,
            this._picture,
            this._pictureName,
            this._responses
        )
    }
}
