import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { UPDATE_SPEED_QUIZ, UpdateSpeedQuizAction } from "./actionTypes";
import { updateSpeedQuizFailed, updateSpeedQuizSucceeded } from "./actions";
import { SpeedQuizService } from "../../Domain/Gateway/SpeedQuiz.service";
import { HandleSpeedQuizState } from "../../Config/State";

export const updateSpeedQuizEpic: Epic = (action$: ActionsObservable<UpdateSpeedQuizAction>,
                                       store: StateObservable<HandleSpeedQuizState>,
                                       { speedQuizService }: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(UPDATE_SPEED_QUIZ),
        switchMap((action) => speedQuizService.updateSpeedQuiz(action.payload)
            .pipe(
                map(() => updateSpeedQuizSucceeded()
                ),
                catchError(error => of(updateSpeedQuizFailed(error)))
            )
        )
    )