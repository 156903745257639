import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetAppStatsState } from "../../Config/State";
import {
   GET_SPEED_QUIZ_PLAYER_COUNT,
    GetSpeedQuizPlayerCountAction,
} from "./actionTypes";

import { getSpeedQuizPlayerCountFailed, getSpeedQuizPlayerCountSucceeded } from "./actions";

export const getSpeedQuizPlayerCountEpic: Epic = (action$: ActionsObservable<GetSpeedQuizPlayerCountAction>,
                                                     store: StateObservable<GetAppStatsState>,
                                                     { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_SPEED_QUIZ_PLAYER_COUNT),
        switchMap((action) => statisticsService.getSpeedQuizPlayerCount(action.payload)
            .pipe(
                map((playerCount: number) => getSpeedQuizPlayerCountSucceeded(playerCount)),
                catchError(error => of(getSpeedQuizPlayerCountFailed(error)))
            )
        )
    )
