import { combineReducers } from "redux";
import { AgencyState } from "./State";
import { getAgencyListReducer } from "../Usecases/GetAgencyList/reducer";
import { addAgencyReducer } from "../Usecases/Add/reducer";
import { updateAgencyReducer } from "../Usecases/Update/reducer";
import { getRegionListReducer } from "../Usecases/GetRegionList/reducer";

export const agencyRootReducers = combineReducers<AgencyState>({
    listing  : getAgencyListReducer,
    addAgency: addAgencyReducer,
    updateAgency: updateAgencyReducer,
    getRegionList: getRegionListReducer

})
