import { Agency } from "../../Domain/Entities/Agency";

export const UPDATE_AGENCY = 'UPDATE_AGENCY'
export const UPDATE_AGENCY_FAILED = 'UPDATE_AGENCY_FAILED'
export const UPDATE_AGENCY_SUCCEEDED = 'UPDATE_AGENCY_SUCCEEDED'

export interface UpdateAgencyAction {
    type: typeof UPDATE_AGENCY
    payload: Agency
}

export interface UpdateAgencyFailedAction {
    type: typeof UPDATE_AGENCY_FAILED
    payload: string
}

export interface UpdateAgencySucceededAction {
    type: typeof UPDATE_AGENCY_SUCCEEDED
}

export type UpdateAgencyActionTypes = UpdateAgencyAction | UpdateAgencyFailedAction | UpdateAgencySucceededAction