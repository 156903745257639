import { Message } from "../../Domain/Entity/Message";

export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_MESSAGE_FAILED = 'ADD_MESSAGE_FAILED'
export const ADD_MESSAGE_SUCCEEDED = 'ADD_MESSAGE_SUCCEEDED'

export interface AddMessageAction {
    type: typeof ADD_MESSAGE;
    payload: Message;
}

interface AddMessageFailedAction {
    type: typeof ADD_MESSAGE_FAILED;
    payload: string;
}

interface AddMessageSucceededAction {
    type: typeof ADD_MESSAGE_SUCCEEDED;
}

export type AddMessageActionTypes = AddMessageAction | AddMessageFailedAction | AddMessageSucceededAction
