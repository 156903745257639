import { AddMessageState } from "../../Config/State";
import {
    ADD_MESSAGE,
    ADD_MESSAGE_FAILED,
    ADD_MESSAGE_SUCCEEDED,
    AddMessageActionTypes
} from './actionTypes';

const initialState: AddMessageState = {
    loading: false,
    success: null,
    error  : undefined
}

export const addMessageReducer = (state = initialState, action: AddMessageActionTypes): AddMessageState => {
    switch (action.type) {
        case ADD_MESSAGE:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case ADD_MESSAGE_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case ADD_MESSAGE_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
};
