import { combineEpics, Epic } from "redux-observable";
import { getAllSpeedQuizEpic } from "../Usecases/GetAllSpeedQuiz/epic";
import { addSpeedQuizEpic } from "../Usecases/AddSpeedQuiz/epic";
import { updateSpeedQuizEpic } from "../Usecases/UpdateSpeedQuiz/epic";
import { getSpeedQuizEpic } from "../Usecases/GetSpeedQuiz/epic";

export const speedQuizRootEpics : Epic = combineEpics(
    getAllSpeedQuizEpic,
    addSpeedQuizEpic,
    updateSpeedQuizEpic,
    getSpeedQuizEpic
)