import { EmployeeData } from "../../Domain/Entity/EmployeeData";

export const GET_EMPLOYEE_STATS = 'GET_EMPLOYEE_STATS'
export const GET_EMPLOYEE_STATS_FAILED = 'GET_EMPLOYEE_STATS_FAILED'
export const GET_EMPLOYEE_STATS_SUCCEEDED = 'GET_EMPLOYEE_STATS_SUCCEEDED'

export interface GetEmployeeStatsAction {
    type: typeof GET_EMPLOYEE_STATS
}

export interface GetEmployeeStatsFailedAction {
    type: typeof GET_EMPLOYEE_STATS_FAILED
    payload: string
}

export interface GetEmployeeStatsSucceededAction {
    type: typeof GET_EMPLOYEE_STATS_SUCCEEDED
    payload: EmployeeData
}

export type GetEmployeeStatsActionTypes =
    GetEmployeeStatsAction
    | GetEmployeeStatsFailedAction
    | GetEmployeeStatsSucceededAction