import React, { PureComponent } from 'react'
import { Employee } from "../../../Domain/Entities/Employee";
import { Alert, Box, Button, CircularProgress, FormControlLabel, MenuItem, Switch, TextField } from "@mui/material";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { FormBlock } from "../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { Agency } from "../../../../Agence/Domain/Entities/Agency";
import { ChoiceType } from "../../../../Common/Domain/Entiities/ChoiceType";
import { EmployeeBuilder } from "../../../Domain/Builder/EmployeeBuilder";
import { AgencyBuilder } from "../../../../Agence/Domain/Builder/AgencyBuilder";
import { match } from "react-router";

interface Props {
    loading: boolean
    error: string | undefined
    updateEmployee: (employee: Employee) => void;
    agencies: Agency[] | null;
    getAgencyList: () => void;
    getEmployeeList: () => void;
    employees: Employee[] | null;
    match: match<{ id: string }>
    setTopBarTitle: (title: string) => void
}

interface State {
    email: string
    first_name: string
    last_name: string
    job: string
    type: 'ITINERANT' | 'AGENCE' | 'SUPPORT'
    agency: string,
    active: boolean
    status: string
    errorMessage: string,
    errorEmail: boolean
    errorFirstName: boolean
    errorLastName: boolean
    errorAgency: boolean
    errorJob: boolean
    success: boolean
}

export class UpdateEmployeeContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            email         : '',
            first_name    : '',
            last_name     : '',
            job           : '',
            type          : 'ITINERANT',
            agency        : '',
            status        : i18n.employee.active,
            errorMessage  : '',
            errorEmail    : false,
            active        : true,
            errorFirstName: false,
            errorLastName : false,
            errorAgency   : false,
            errorJob      : false,
            success       : false
        }
    }

    componentDidMount() {
        this.props.getAgencyList()
        if (!this.props.employees)
            this.props.getEmployeeList()
        this.props.setTopBarTitle(i18n.employee.employee_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.employees && this.state.email === '') {
            const employee: Employee | undefined = this.props.employees.find(employee => employee.email === this.props.match.params.id)
            if (employee)
                this.setState({
                    email     : employee.email,
                    first_name: employee.firstName,
                    last_name : employee.lastName,
                    job       : employee.job,
                    type      : employee.qualification,
                    agency    : employee.agency.agencyCode,
                    active    : employee.active,
                    status    : employee.active ? i18n.employee.active : i18n.employee.inactive
                })
        }
        if (prevProps.error !== this.props.error && this.props.error)
            this.setState({ errorMessage: i18n.employee.error_handle_employee })
        if (prevProps.loading === true && this.props.loading === false && this.props.error === undefined) {
            this.setState({ success: true })
            window.location.href = '/employee-list'
        }
    }

    render() {
        return (
            <Box>
                <PageTitle title={i18n.employee.update_employee(this.state.first_name + ' ' + this.state.last_name)}/>

                <Box component="form"
                     noValidate
                     autoComplete="off">

                    <FormBlock title={i18n.employee.employee}>
                        <TextField fullWidth sx={{ mb: 2 }} required disabled
                                   label={i18n.employee.email}
                                   value={this.state.email}
                                   error={this.state.errorEmail}
                                   onChange={event => this.setState({ email: event.target.value })}/>

                        <TextField fullWidth sx={{ mb: 2 }} required
                                   label={i18n.employee.first_name}
                                   value={this.state.first_name}
                                   error={this.state.errorFirstName}
                                   onChange={event => this.setState({ first_name: event.target.value })}/>

                        <TextField fullWidth sx={{ mb: 2 }} required
                                   label={i18n.employee.last_name}
                                   value={this.state.last_name}
                                   error={this.state.errorLastName}
                                   onChange={event => this.setState({ last_name: event.target.value })}/>

                        <TextField select
                                   fullWidth sx={{ mb: 2 }} required
                                   label={i18n.employee.qualification}
                                   value={this.state.type}
                                   onChange={event => this.setType(event.target.value)}>
                            <MenuItem key={'ITINERANT'} value={'ITINERANT'}>ITINERANT</MenuItem>
                            <MenuItem key={'AGENCE'} value={'AGENCE'}>AGENCE</MenuItem>
                            <MenuItem key={'SUPPORT'} value={'SUPPORT'}>SUPPORT</MenuItem>
                        </TextField>

                        <TextField fullWidth sx={{ mb: 2 }}
                                   label={i18n.employee.job} required
                                   value={this.state.job}
                                   error={this.state.errorJob}
                                   onChange={event => this.setState({ job: event.target.value })}/>

                        <TextField select
                                   fullWidth sx={{ mb: 2 }} required
                                   label={i18n.employee.agency}
                                   value={this.state.agency}
                                   error={this.state.errorAgency}
                                   onChange={event => this.setState({ agency: event.target.value })}>

                            {this.mapChoiceTypeFromAgencies(this.props.agencies).map(item => (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                            )}
                        </TextField>

                        <FormControlLabel control={<Switch checked={this.state.active}
                                                           color={'success'}
                                                           onChange={event =>
                                                               this.setState({
                                                                   active: event.target.checked,
                                                                   status: event.target.checked ? i18n.employee.active : i18n.employee.inactive
                                                               })}/>}
                                          label={this.state.status}/>

                        {this.state.errorMessage !== '' ?
                            <Alert severity="error">{this.state.errorMessage}</Alert> : null}

                        {this.state.success ?
                            <Alert severity="success">{i18n.employee.success_update_employee}</Alert> : null}

                        {this.props.loading ? <CircularProgress/> : null}
                    </FormBlock>

                    <Button variant="contained"
                            onClick={(): void => this.updateEmployee()}>
                        {i18n.employee.save}
                    </Button>

                    <Button variant="outlined" sx={{ marginLeft: 2 }}
                            href={'/employee-list'}>
                        {i18n.employee.cancel}
                    </Button>
                </Box>
            </Box>
        )
    }

    setType(type: string): void {
        if (type === 'ITINERANT' || type === 'AGENCE' || type === 'SUPPORT')
            this.setState({ type })
    }

    mapChoiceTypeFromAgencies(agencies: Agency[] | null): ChoiceType[] {
        return agencies ? agencies.map(item => ({
            label: item.agencyName,
            value: item.agencyCode
        })) : []
    }

    updateEmployee() {
        this.setState({ errorMessage: '', success: false })
        if (this.validateForm())
            this.props.updateEmployee(
                new EmployeeBuilder()
                    .withEmail(this.state.email)
                    .withFirstName(this.state.first_name)
                    .withLastName(this.state.last_name)
                    .withQualification(this.state.type)
                    .withJob(this.state.job)
                    .withAgency(new AgencyBuilder().withAgencyCode(this.state.agency).build())
                    .withActive(this.state.active)
                    .build()
            )
    }

    validateForm(): boolean {
        this.setState({
            errorFirstName: this.state.first_name.length < 2,
            errorLastName : this.state.last_name.length < 2,
            errorEmail    : !this.validateEmail(this.state.email),
            errorAgency   : this.state.agency === '',
            errorJob      : this.state.job.length < 1
        })
        return (this.state.first_name.length > 2 &&
            this.state.last_name.length > 2 &&
            this.validateEmail(this.state.email) &&
            this.state.agency !== '' && this.state.job.length > 1)
    }

    validateEmail(email: string): boolean {
        const regex = new RegExp(['^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
            '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
            '[a-zA-Z]{2,}))$'].join(''));
        return regex.test(email.toLowerCase())
    }
}
