export const REQUEST_PASSWORD = 'REQUEST_PASSWORD'
export const REQUEST_PASSWORD_FAILED = 'REQUEST_PASSWORD_FAILED'
export const REQUEST_PASSWORD_SUCCEEDED = 'REQUEST_PASSWORD_SUCCEEDED'

export  interface RequestPasswordAction {
    type: typeof REQUEST_PASSWORD;
    payload: string;
}

interface RequestPasswordFailedAction {
    type: typeof REQUEST_PASSWORD_FAILED;
    payload: string;
}

interface RequestPasswordSucceededAction {
    type: typeof REQUEST_PASSWORD_SUCCEEDED;
}

export type RequestPasswordActionTypes = RequestPasswordAction | RequestPasswordFailedAction | RequestPasswordSucceededAction
