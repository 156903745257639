import { SpeedQuizRound } from "./SpeedQuizRound";

export class SpeedQuiz {
    constructor(
        protected _id: string,
        protected _label: string,
        protected _endDate: Date,
        protected _rounds: SpeedQuizRound[]
    ){
    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get rounds(): SpeedQuizRound[] {
        return this._rounds;
    }
}