import { SpeedQuiz } from "../../Domain/Entities/SpeedQuiz";

export const GET_SPEED_QUIZ='GET_SPEED_QUIZ'
export const GET_SPEED_QUIZ_FAILED='GET_SPEED_QUIZ_FAILED'
export const GET_SPEED_QUIZ_SUCCEEDED='GET_SPEED_QUIZ_SUCCEEDED'


export interface GetSpeedQuizAction {
    type: typeof GET_SPEED_QUIZ
    payload: string
}

export interface GetSpeedQuizFailedAction {
    type: typeof GET_SPEED_QUIZ_FAILED
    payload: string
}

export interface GetSpeedQuizSucceededAction {
    type: typeof GET_SPEED_QUIZ_SUCCEEDED
    payload: SpeedQuiz
}

export type GetSpeedQuizActionTypes = GetSpeedQuizAction |GetSpeedQuizFailedAction |GetSpeedQuizSucceededAction