import React from 'react';
import { i18n } from "../../../../Configuration/I18n";
import { Typography } from '@mui/material';

export const EnabledTag = () => {
    return (
        <Typography component="span" style={{
            fontSize    : 13,
            color       : 'green',
            borderRadius: 15,
            padding     : '2px 8px',
            border      : '1px solid green'
        }}>
            {i18n.common.enabled}
        </Typography>
    )
};
