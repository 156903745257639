import { Adventure } from "../../Domain/Entity/Adventure";
import { MCQ } from "../../Domain/Entity/MCQ";

export const ADD_ADVENTURE = 'ADD_ADVENTURE'
export const ADD_ADVENTURE_FAILED = 'ADD_ADVENTURE_FAILED'
export const ADD_ADVENTURE_SUCCEEDED = 'ADD_ADVENTURE_SUCCEEDED'

export interface AddAdventureAction {
    type: typeof ADD_ADVENTURE
    payload: { adventure: Adventure, mcq: MCQ }
}

export interface AddAdventureFailedAction {
    type: typeof ADD_ADVENTURE_FAILED
    payload: string
}

export interface AddAdventureSucceededAction {
    type: typeof ADD_ADVENTURE_SUCCEEDED
}

export type AddAdventureActionTypes = AddAdventureAction | AddAdventureFailedAction | AddAdventureSucceededAction