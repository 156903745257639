import { RetrieveProfileState } from "../../Config/State";
import {
    RETRIEVE_PROFILE,
    RETRIEVE_PROFILE_FAILED,
    RETRIEVE_PROFILE_SUCCEEDED,
    RetrieveProfileActionsTypes
} from "./RetrieveProfile.types";

const initialState: RetrieveProfileState = {
    isLoading: false,
    error    : undefined,
    success  : undefined
}
export const retrieveProfileReducer = (state = initialState, action: RetrieveProfileActionsTypes): RetrieveProfileState => {
    switch (action.type) {
        case RETRIEVE_PROFILE:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                error    : action.payload,
            }
        case RETRIEVE_PROFILE_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                success  : true
            }
        default:
            return state;
    }
}