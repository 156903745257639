import { createTheme, Theme } from "@mui/material";

export const globalTheme: Theme = createTheme({
    palette   : {
        background: {
            default: "linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)",
        },
        primary   : {
            main : "#1468B6",
            dark : "#27348B",
            light: '#009EE3'
        },
        secondary : {
            main : "#efefef",
            dark : "#cfcfcf",
            light: '#ffffff'
        }
    },
    components: {
        MuiAppBar        : {
            styleOverrides: {
                root: {
                    boxShadow: 'rgba(100, 116, 139, 0.12) 0px 1px 4px'
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height         : 10,
                    borderRadius   : 5,
                    backgroundColor: '#eeeeee'
                },
                bar : {
                    borderRadius   : 5,
                    backgroundColor: '#308fe8'
                }
            }
        },
        MuiTableHead     : {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(243, 244, 246)'
                }
            }
        },
        MuiTableCell     : {
            styleOverrides: {
                head: {
                    fontWeight       : '700',
                    borderBottomWidth: 0
                }
            }
        }
    }
})
