import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../Configuration/I18n";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import { SpeedQuizAccordion } from "./Components/Accordion";
import { SpeedQuizDetailsForm } from "./Components/Details";
import {
    SpeedQuizAnswerType,
    SpeedQuizQuestionType,
    SpeedQuizRoundType,
    SpeedQuizType
} from "../../../Domain/SpeedQuiz.type";
import { SpeedQuizFormValidation } from "./FormValidation";
import { SpeedQuiz } from "../../../Domain/Entities/SpeedQuiz";
import { SpeedQuizBuilder } from "../../../Domain/Builder/SpeedQuiz.builder";
import { SpeedQuizRound } from "../../../Domain/Entities/SpeedQuizRound";
import { SpeedQuizRoundBuilder } from "../../../Domain/Builder/SpeedQuizRound.builder";
import { SpeedQuizQuestion } from "../../../Domain/Entities/SpeedQuizQuestion";
import { SpeedQuizQuestionBuilder } from "../../../Domain/Builder/SpeedQuizQuestion.builder";
import { SpeedQuizAnswer } from "../../../Domain/Entities/SpeedQuizAnswer";
import { SpeedQuizAnswerBuilder } from "../../../Domain/Builder/SpeedQuizAnswer.builder";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { RouteComponentProps } from "react-router";

const moment = ApplicationContext.getInstance().momentJs()

interface Props extends RouteComponentProps {
    loading: boolean;
    error: string | undefined
    addSpeedQuiz: (speedQuiz: SpeedQuiz) => void
    setTopBarTitle: (title: string) => void
}

interface State {
    title: string;
    startDate: string;
    endDate: string;
    errorMessage: string | undefined
    round_1: SpeedQuizRoundType
    round_2: SpeedQuizRoundType
    round_3: SpeedQuizRoundType
}

export class AddSpeedQuizContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            title       : '',
            startDate   : '',
            endDate     : '',
            errorMessage: undefined,
            round_1     : {
                expanded : true,
                category : '',
                startDate: '',
                questions: []
            },
            round_2     : {
                expanded : false,
                category : '',
                startDate: '',
                questions: []
            },
            round_3     : {
                expanded : false,
                category : '',
                startDate: '',
                questions: []
            },
        }
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.speedQuiz.speed_quiz_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error && prevProps.error === undefined)
            this.setState({ errorMessage: this.props.error })

        if (this.props.loading === false && prevProps.loading === true && this.props.error === undefined)
            this.props.history.push('/speed-quiz-list')
    }

    render(): ReactNode {
        return (
            <Box>
                <PageTitle title={i18n.speedQuiz.add_speed_quiz}/>

                <Box component="form" noValidate autoComplete="off">
                    <SpeedQuizDetailsForm title={this.state.title}
                                          startDate={this.state.startDate}
                                          error={this.state.errorMessage}
                                          onChangeDate={(value: string) => this.setDatesSpeedQuiz(value)}
                                          onChange={(title: string) => this.setState({ title })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_1(this.formatDate(this.state.round_1.startDate))}
                                        round={this.state.round_1}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_1: {
                                                ...this.state.round_1,
                                                [key]: value
                                            }
                                        })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_2(this.formatDate(this.state.round_2.startDate))}
                                        round={this.state.round_2}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_2: {
                                                ...this.state.round_2,
                                                [key]: value
                                            }
                                        })}/>

                    <SpeedQuizAccordion title={i18n.speedQuiz.round_3(this.formatDate(this.state.round_3.startDate))}
                                        round={this.state.round_3}
                                        error={this.state.errorMessage}
                                        onChange={(key, value) => this.setState({
                                            round_3: {
                                                ...this.state.round_3,
                                                [key]: value
                                            }
                                        })}/>

                    {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}

                    {this.props.loading &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress/></Box>}

                    <Button sx={{ mt: 2 }} variant="contained" onClick={(): void => this.addSpeedQuiz()}>
                        {i18n.speedQuiz.save}
                    </Button>
                </Box>
            </Box>
        )
    }

    formatDate(date: string): string {
        if (date !== '')
            return moment(date, 'YYYY-MM-DDTHH:mm').format(' - dddd DD/MM/YYYY à HH:mm')
        return ''
    }

    addSpeedQuiz() {
        if (SpeedQuizFormValidation.validate(this.speedQuizValues())) {
            const rounds: SpeedQuizRound[] = [
                new SpeedQuizRoundBuilder()
                    .withCategory(this.state.round_1.category)
                    .withStep(1)
                    .withStartDate(moment(this.state.round_1.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_1))
                    .build(),
                new SpeedQuizRoundBuilder()
                    .withCategory(this.state.round_2.category)
                    .withStep(2)
                    .withStartDate(moment(this.state.round_2.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_2))
                    .build(),
                new SpeedQuizRoundBuilder()
                    .withCategory(this.state.round_3.category)
                    .withStep(3)
                    .withStartDate(moment(this.state.round_3.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withQuestions(this.getQuestionsRound(this.state.round_3))
                    .build()
            ]
            this.props.addSpeedQuiz(new SpeedQuizBuilder()
                .withLabel(this.state.title)
                .withEndDate(moment(this.state.endDate, 'YYYY-MM-DDTHH:mm').format())
                .withRounds(rounds)
                .build()
            )
        }
        else
            this.setState({ errorMessage: i18n.speedQuiz.empty_field_error })
    }

    setDatesSpeedQuiz(value: string) {
        this.setState({
            startDate: value,
            endDate  : moment(value + 'T20:00', 'YYYY-MM-DDTHH:mm').add(4, 'day').format('YYYY-MM-DDTHH:mm'),
            round_1  : {
                ...this.state.round_1,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm')
            },
            round_2  : {
                ...this.state.round_2,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').add(2, 'day').format('YYYY-MM-DDTHH:mm')
            },
            round_3  : {
                ...this.state.round_3,
                startDate: moment(value + 'T10:00', 'YYYY-MM-DDTHH:mm').add(4, 'day').format('YYYY-MM-DDTHH:mm')
            }
        })
    }

    getQuestionsRound(round: SpeedQuizRoundType): SpeedQuizQuestion[] {
        return round.questions.map((question: SpeedQuizQuestionType) =>
            new SpeedQuizQuestionBuilder()
                .withText(question.text)
                .withDelay(question.delay)
                .withAnswers(this.getAnswersQuestion(question.responses))
                .build())
    }

    getAnswersQuestion(answers: SpeedQuizAnswerType[]): SpeedQuizAnswer[] {
        return answers.map((answer: SpeedQuizAnswerType) =>
            new SpeedQuizAnswerBuilder()
                .withText(answer.text)
                .withIsCorrect(answer.correct)
                .build()
        )
    }

    speedQuizValues(): SpeedQuizType {
        return {
            label  : this.state.title,
            endDate: this.state.endDate,
            rounds : [
                this.state.round_1,
                this.state.round_2,
                this.state.round_3
            ]
        }
    }
}
