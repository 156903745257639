import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    GET_ADVENTURE_SUBSCRIPTION_STATS,
    GetAdventureSubscriptionStatsAction
} from "./actionTypes";
import {  GetAppStatsState } from "../../Config/State";
import { StatisticsService } from "../../Domain/Gateway/Statistics.service";
import {
    getAdventureSubscriptionStatsFailed,
    getAdventureSubscriptionStatsSucceeded
} from "./actions";
import { SerieData } from "../../Domain/Entity/SerieData";

export const getAdventureSubscriptionStatsEpic: Epic = (action$: ActionsObservable<GetAdventureSubscriptionStatsAction>,
                                            store: StateObservable<GetAppStatsState>,
                                            { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_ADVENTURE_SUBSCRIPTION_STATS),
        switchMap(action => statisticsService.getAdventureSubscriptionStats(action.payload)
            .pipe(
                map((stats: SerieData[]) => getAdventureSubscriptionStatsSucceeded(stats)),
                catchError((error: string) => of(getAdventureSubscriptionStatsFailed(error)))
            )
        )
    )
