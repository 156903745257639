import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { Profile } from "../../../Domain/Entities/Profile";
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { ProfileMapper } from "./mapper/Profile.mapper";
import { ProfileService } from "../../../Domain/Gateway/Profile.service";

export class ApiProfileService implements ProfileService {

    retrieveRemoteProfile(): Observable<Profile> {
        const url = process.env.REACT_APP_API_URL + '/v1/profile'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url)
            .pipe(
                map(response => ProfileMapper.mapProfileDtoToProfile(response.response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
