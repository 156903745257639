import { Adventure } from "../../Domain/Entity/Adventure";
import {
    UPDATE_ADVENTURE,
    UPDATE_ADVENTURE_FAILED, UPDATE_ADVENTURE_SUCCEEDED,
    UpdateAdventureAction,
    UpdateAdventureFailedAction, UpdateAdventureSucceededAction
} from "./actionTypes";

export const updateAdventure = (adventure: Adventure): UpdateAdventureAction => ({
    type   : UPDATE_ADVENTURE,
    payload: adventure
})

export const updateAdventureFailed = (error: string): UpdateAdventureFailedAction => ({
    type   : UPDATE_ADVENTURE_FAILED,
    payload: error
})

export const updateAdventureSucceeded = (): UpdateAdventureSucceededAction => ({
    type: UPDATE_ADVENTURE_SUCCEEDED
})