import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { AddMessageState } from "../../Config/State";
import { MessageService } from "../../Domain/Gateway/Message.service";
import { UPDATE_MESSAGE, UpdateMessageAction } from "./actionTypes";
import { updateMessageFailed, updateMessageSucceeded } from "./actions";
import { loadMessageList } from "../List/actions";

export const updateMessageEpic: Epic = (action$: ActionsObservable<UpdateMessageAction>,
                                     store: StateObservable<AddMessageState>,
                                     { messageService }: { messageService: MessageService }) =>
    action$.pipe(
        ofType(UPDATE_MESSAGE),
        switchMap((action) => messageService.updateMessage(action.payload)
            .pipe(
                concatMap(() => [
                    updateMessageSucceeded(),
                    loadMessageList()
                ]),
                catchError(error => of(updateMessageFailed(error)))
            )
        )
    )
