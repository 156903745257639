import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { catchError, concatMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { GET_ALL_SPEED_QUIZ, GetAllSpeedQuizAction } from "./actionTypes";
import { GetAllSpeedQuizState } from "../../Config/State";
import { getAllSpeedQuizFailed, getAllSpeedQuizSucceeded } from "./actions";
import { SpeedQuizService } from "../../Domain/Gateway/SpeedQuiz.service";
import { getSpeedQuizQuestionStats } from "../../../Statistics/Usecases/GetSpeedQuizQuestionStats/actions";
import { SpeedQuizHeader } from "../../Domain/Entities/SpeedQuizHeader";
import { getSpeedQuizResponseStats } from "../../../Statistics/Usecases/GetSpeedQuizResponseStats/actions";

export const getAllSpeedQuizEpic: Epic = (action$: ActionsObservable<GetAllSpeedQuizAction>,
                                          store: StateObservable<GetAllSpeedQuizState>,
                                          { speedQuizService }: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(GET_ALL_SPEED_QUIZ),
        switchMap(() => speedQuizService.getAllSpeedQuiz()
            .pipe(
                concatMap((data: SpeedQuizHeader[]) => {
                    if (data.length > 0)
                        return [getAllSpeedQuizSucceeded(data), getSpeedQuizQuestionStats(data[0].id), getSpeedQuizResponseStats(data[0].id)]
                    else
                        return [getAllSpeedQuizSucceeded(data)]
                }),
                catchError(error => of(getAllSpeedQuizFailed(error)))
            )
        )
    )