import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { StatisticsService } from '../../Domain/Gateway/Statistics.service';
import { GetSerieStatsState } from "../../Config/State";
import { SerieData } from "../../Domain/Entity/SerieData";
import { GET_BADGES_STATS,  GetBadgesStatsAction } from "./actionTypes";
import { getBadgesStatsFailed, getBadgesStatsSucceeded } from "./actions";

export const getBadgesStatsEpic: Epic = (action$: ActionsObservable<GetBadgesStatsAction>,
                                      store: StateObservable<GetSerieStatsState>,
                                      { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_BADGES_STATS),
        switchMap(() =>
            statisticsService.getBadgesStats()
                .pipe(
                    map((stats: SerieData[]) => getBadgesStatsSucceeded(stats)),
                    catchError(error => of(getBadgesStatsFailed(error)))
                )
        )
    )
