import React, { PureComponent, ReactNode } from 'react'
import { SpeedQuizHeader } from "../../../../../SpeedQuiz/Domain/Entities/SpeedQuizHeader";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../../Configuration/I18n";

interface Props {
    value: string;
    speedQuiz: SpeedQuizHeader[];
    onChange: (id: string) => void;
}
export class SpeedQuizSelectInput extends PureComponent<Props>{
    render(): ReactNode{
        return(
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <FormControl fullWidth>
                        <InputLabel id="simple-select-label">{i18n.statistics.speed_quiz}</InputLabel>

                        <Select id="simple-select"
                                labelId="simple-select-label"
                                value={this.props.value}
                                label={i18n.statistics.speed_quiz}
                                onChange={(event: SelectChangeEvent) => this.props.onChange(event.target.value)}>

                            {this.props.speedQuiz.map(speedQuiz => <MenuItem key={speedQuiz.id}
                                                                              value={speedQuiz.id}>{speedQuiz.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}