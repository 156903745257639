import {
    GET_ADVENTURE_SUBSCRIPTION_STATS,
    GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED, GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED,
    GetAdventureSubscriptionStatsActionTypes
} from "./actionTypes";
import { GetSerieStatsState } from "../../Config/State";

const initialState: GetSerieStatsState = {
    loading: false,
    error  : undefined,
    stats  : null
}
export const getAdventureSubscriptionStatsReducer = (state = initialState, action: GetAdventureSubscriptionStatsActionTypes): GetSerieStatsState => {
    switch (action.type) {
        case GET_ADVENTURE_SUBSCRIPTION_STATS:
            return { loading: true, stats: null, error: undefined }
        case GET_ADVENTURE_SUBSCRIPTION_STATS_FAILED:
            return { loading: false, error: action.payload, stats: null }
        case GET_ADVENTURE_SUBSCRIPTION_STATS_SUCCEEDED:
            return { loading: false, error: undefined, stats: action.payload }
        default:
            return state
    }
}