import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable'
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { AuthenticationRepository } from '../../Domain/Gateway/Authentication.repository';
import { userIsAuthenticated, userIsNotAuthenticated } from './actions';
import { AUTHENTICATE_USER, AuthenticateUserAction } from './actionTypes';
import { AuthenticateUserState } from "../../Config/State";

export const authenticateUserEpic: Epic = (action$: ActionsObservable<AuthenticateUserAction>,
                                           store: StateObservable<AuthenticateUserState>,
                                           { authenticationRepository }: { authenticationRepository: AuthenticationRepository }) =>
    action$.pipe(
        ofType(AUTHENTICATE_USER),
        switchMap(() => authenticationRepository.getAuthenticationToken()
            .pipe(
                map((token: string) => userIsAuthenticated(token)),
                catchError(() => of(userIsNotAuthenticated()))
            )
        )
    )
