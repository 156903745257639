import {
    GET_PAGE_STATS,
    GET_PAGE_STATS_FAILED, GET_PAGE_STATS_SUCCEEDED,
    GetPageStatsAction,
    GetPageStatsFailedAction,
    GetPageStatsSucceededAction
} from "./actionTypes";
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getPageStats = (): GetPageStatsAction =>({
    type: GET_PAGE_STATS
})

export const getPageStatsFailed = (error: string): GetPageStatsFailedAction =>({
    type: GET_PAGE_STATS_FAILED,
    payload: error
})

export const getPageStatsSucceeded = (data: CartesianData[]): GetPageStatsSucceededAction =>({
    type: GET_PAGE_STATS_SUCCEEDED,
    payload: data
})